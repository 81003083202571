import React, { useState, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

import Header from '../Header';
import Card_puchases  from '../../components/card_puchases';
import Loading from '../../components/loading';

import usePeristedState from '../../utils/usePersistedState';

import { Puchases_loans, Spinners } from './styles';
import { Container, Row, Col, Table, Modal, Button, Spinner } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import theme_interface from '../../interfaces/theme_epub_interface';

import auth from '../../services/auth';
import publications from '../../services/publications';

export default function Puchases_loan(props: any) {
   
   const [type, setType] = useState(props.match.params.id);
   const [publication, setPublication]: any = useState('');
   const [themes, setThemes] = usePeristedState<theme_interface>('theme_epub');
   const [isLoading, setLoading] = useState(false);
	const [page_count, setPage_count] = useState(0);
	const [page_size, setPagesize] = useState(0);
   const [show, setShow] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [ref, inView] = useInView({
		threshold: 0,
	})

   let title = (props.match.params.id === 'BUY') ? 'Compras' : 'Empréstimos';
   let history = useHistory();
   let token = auth().isAuthenticated();

   useEffect(() => {
      setShow(true);
      async function getPublication() {
         publications().indexPuchases_loan({type: type, pageSize: 20 }).then((results: any) => {
               setPublication(results.results);
               setShow(false);
         });
      }

      console.log(themes);
            
      if(themes == null){
         
         setThemes((prev: any) => ({ ...prev, 
            background: "#eee5de",
            color: "#000",
            font_size: 150,
            font_transform: '',
            font_family: 'PT_Serif',
            line_height: 1,
            margin: 0}));

      }
      if(token){
         getPublication();
      }
   }, []);

   const showLoanding = () => {
      setShow(true);
   }

   const inShowLoanding = () => {
      setShow(false);
   }

   
	if (inView) {
		if (page_size < page_count) {

         publications().indexPuchases_loan({type: type, page: (page_size + 1), pageSize: 20 }).then((teste2: any) => {
            setPagesize(page_size + 1);
            setPublication([...publication, ...teste2.results ]);
         });
		}

	}

   if(token){
      
      return (
         <Puchases_loans>
            <Header title={title} autor={true} />
            <Container className="mt-6 pt-2 justify-content-md-center">
            
               {(publication) ?
                  publication.map((item: any, index: any) => (
                     (item) ?
                        <Card_puchases license={item} key={`pl-${index}`} funShow={showLoanding} funInShow={inShowLoanding} />
                        : null
                  )) :
                  <div className="text-center p-auto m-auto ">
                     <p>Nenhuma publicação cadastrada</p>
                  </div>
               }
               {(page_size < page_count) ?
                  <div ref={ref} className={(inView) ? 'visible' : 'invisible'}>
                     <Spinners className="text-center p-auto m-auto mt-4">
                        <Spinner animation="border" />
                     </Spinners>
                  </div>
                  : ''
               }
            <Loading show={show} />
            </Container>
         </Puchases_loans>
      );
   }else{
      
      return (
         <Puchases_loans>
            <Header title="Seus Livros" autor={true} />
            <Container className="mt-5 pt-2 justify-content-md-center">
               <h1>Você não esta logado</h1>
               <h4>Por favor efetue o login para ver esta pagina</h4>
               
               <Button variant="success" size="lg" type="button" onClick={event => history.push("/login")} ></Button>
            </Container>
         </Puchases_loans>
      );
   }
}