import React from 'react';
import { Modals } from './styles';

import { Modal, Spinner } from 'react-bootstrap';


export default function Loading(props: any) {

   return (
      <Modal show={props.show} 
         centered>
         <Modal.Body>
            <Modals>
               <div className="text-center">
                  <Spinner animation="border">
                  </Spinner>
                  <p>Carregando...</p>
               </div>
            </Modals>
         </Modal.Body>
      </Modal>
   );
}