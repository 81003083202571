import styled from 'styled-components';

export const Fabs = styled.div`
position: fixed;
bottom:0px;
left:0px;
right:0px;
height:50px;
margin-bottom:5vh;
margin-right:5vw;
text-align: -webkit-right;
.icon_fab{
   color: ${props => props.theme.colors.text_color_secondary};
}
`;

export const Spinners = styled.div`
.spinner-border {
   width: 2rem;
   height: 2rem;
   color: ${props => props.theme.colors.primary} !important;
}
`;
export const Dashboards = styled.div`

.div_new{
   border-bottom: 5px solid ${props => props.theme.colors.primary} !important;
}
`;