import axios from 'axios';
import auth from './auth';
import moment from 'moment';

export default function annotation() {

   
   const token_key: string = '';
   const base_url = process.env.REACT_APP_BASE_URL;

   const annotation = {
      
      getAnnotationByBook: function getAnnotationByBook(publication_id: number, header: any): any {
         
         return axios.get(`${base_url}v1/annotation/${publication_id}`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
            params: {
               page: header.page,
               pageSize: header.pageSize,
            }
         })
      },
      CreateAnnotationByBook: function CreateAnnotationByBook(data: any): any {

         return axios.post(`${base_url}v1/annotation/`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
         })
      },
      UpdateAnnotationByBook: function UpdateAnnotationByBook(publication_id: string, data: any): any {

         return axios.put(`${base_url}v1/annotation/${publication_id}`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
         })
      },
      DeleteAnnotationByBook: function DeleteAnnotationByBook(publication_id: string): any {

         return axios.delete(`${base_url}v1/annotation/${publication_id}`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
         })
      }
   }
   
   return annotation;

}