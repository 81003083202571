import React, { useState, useEffect, useContext } from 'react';
import { Navbar, Nav, Form, InputGroup, FormControl, Row, Col, Image } from 'react-bootstrap';
import { faAngleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbars, Buttons, Titles, InputSearch, Buttons_go_back } from './styles';
import { useHistory, Link } from 'react-router-dom';
import { Turn as Hamburger } from 'hamburger-react';
import { ThemeContext } from 'styled-components';

import usePeristedState from '../../utils/usePersistedState';

import Image_loading from '../../components/image_loading';
 
import user_interface from '../../interfaces/user_interface';

import auth from '../../services/auth';
import users from '../../services/users';


export default function Header(props: any) {

  let history = useHistory();
  const [term, setTerm] = useState((props.term ?? ''));
  const [user, setUser] = usePeristedState<user_interface>('user');
    
  const [isOpen, setOpen] = useState(false);
  const theme = useContext(ThemeContext);

  let links = [];

  useEffect(() => {
    async function loadUser() {
        users().get().then((results: any) => {
            if (results.response) {
                setUser(results.response);
            }
        });
    }

    if ((auth().isAuthenticated()) && (user == null)) {
        loadUser();
    }

  }, []);

  if (process.env.REACT_APP_SIGLA === 'PUB ') {
    links = [
      { name: 'HOME', link: '/' },
      { name: 'MEUS DADOS', link: '/users' },
      { name: 'MEUS LIVROS', link: '/dashboard' },
      { name: 'MEUS AUTORES', link: '/authors' },
      { name: 'COMENTARIOS', link: '' },
      { name: 'PERIODO DE EMPRESTIMO', link: '/date_loan' }
    ];
  } else if (process.env.REACT_APP_SIGLA === 'RDR ') {
    links = [
      { name: 'HOME', link: '/' },
      { name: 'MEUS DADOS', link: '/users' },
      { name: 'MEUS EMPRESTADOS', link: '/publications/list/loan' }
    ];
  } else {
    links = [
      { name: 'HOME', link: '/' },
      { name: 'MEUS DADOS', link: '/users' },
      { name: 'MEUS LIVROS', link: '/publications/list/buy' },
      { name: 'MEUS EMPRESTADOS', link: '/publications/list/loan' }
    ];

  }

  function go_back() {
    return history.goBack();
  }

  function search() {
    if (term != '') {
      history.push('/search/' + term);
    }

  }

  function logout() {
    auth().logout();
    return history.push("/");
  }

  if (props.title) {
    return (
      <div>
        {(props.autor) ?
          <Navbars collapseOnSelect expand={false} active={true} variant="light" fixed="top" className="pt-0 pb-0">
            <div className="div_menu pl-4 pr-4 pt-2 pb-2">
              <InputGroup className="m-2 text-center " >
                <Buttons variant="" onClick={(props.go_back) ? props.go_back : go_back}>
                  <FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" />
                </Buttons>
                <Titles className="ml-3">{props.title}</Titles>
                <Navbars.Toggle aria-controls="responsive-navbar-nav" className="btn-navbar m-3">
                  <Hamburger toggled={isOpen} toggle={setOpen} size={25} direction="left" />
                </Navbars.Toggle>
              </InputGroup>
            </div>
            <Navbar.Collapse in={isOpen} id="responsive-navbar-nav">
              <Nav className="menu">
                <div className="menu_top ml-4">
                    <Row>
                      {(user) ? 
                      <Col xs="4" lg="4" className="text-center mt-2" >
                        <Image_loading image={`${user.image}`} className="rounded-circle" width={70} height={70} alt={`avatar do ${user.name}`} />
                        <p>Olá {user.name.split(" ")[0]}!</p>
                      </Col>
                      : ''
                      }
                      <Col xs="8" lg="8">
                        {links.map(item => (
                            <Link className="nav-link" role="button" to={item.link}>{item.name}
                            </Link>
                        ))}
                        {(user) ? 
                          <Nav.Link onClick={() => logout()}>LOGOUT</Nav.Link> 
                          : ''
                        }
                      </Col>
                    </Row>
                </div>
                <div className="menu_footer ml-1">
                  <Row>
                    <Col xs="4" lg="4">
                      <div className="text-center m-2">
                        <Image src={theme.icon} fluid width="100%" />
                      </div>
                    </Col>
                    <Col xs="8" lg="8">
                      <Link className="nav-link" role="button" to="/terms">TERMOS DE USO</Link>
                      <Link className="nav-link" role="button" to="/terms">POLÍTICA DE USO</Link>
                    </Col>
                  </Row>

                </div>
              </Nav>
            </Navbar.Collapse>

          </Navbars>
          :
          <Navbars expand="sm" variant="light" fixed="top" className="pl-4 pr-4">
            <Buttons variant="" onClick={(props.go_back) ? props.go_back : go_back}>
              <FontAwesomeIcon icon={faAngleLeft} className="icon-navbar" />
            </Buttons>
            <Titles className="ml-3">{props.title}</Titles>
          </Navbars>
        }
      </div>
    );
  } else {

    return (
      <>
        <Navbars collapseOnSelect expand={false} active={true} variant="dark" fixed="top" className="pl-4 pr-4">
          {(props.detalhe) ? 
            <Form onSubmit={search}  >
              <InputSearch draggable={(props.detalhe) ? props.detalhe : false}  className="ml-3">
                <InputGroup className="m-2">
                  <Buttons_go_back variant="" onClick={(props.go_back) ? props.go_back : go_back}>
                    <FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" />
                  </Buttons_go_back>
                  <FormControl
                    type="search"
                    value={term}
                    onChange={event => setTerm(event.target.value)}
                    placeholder="Pesquisar publicações"
                  />
                  {(user) ? 
                  <Navbars.Toggle aria-controls="responsive-navbar-nav" className="btn-navbar">
                    <Hamburger toggled={isOpen} toggle={setOpen} size={25} direction="left" />
                  </Navbars.Toggle> : ''

                  }
                </InputGroup>
              </InputSearch>
            </Form>
          : 
          <Form onSubmit={search} >
            <InputSearch draggable={(props.detalhe) ? props.detalhe : false} >
              <InputGroup className="m-2">
                <FontAwesomeIcon icon={faSearch} className="icon-navbar" />
                <FormControl
                  type="search"
                  value={term}
                  onChange={event => setTerm(event.target.value)}
                  placeholder="Pesquisar publicações"
                />
                <Navbars.Toggle aria-controls="responsive-navbar-nav" className="btn-navbar">
                  <Hamburger toggled={isOpen} toggle={setOpen} size={25} direction="left" />
                </Navbars.Toggle>
              </InputGroup>
            </InputSearch>
          </Form>
          
          }
          <Navbar.Collapse in={isOpen} id="responsive-navbar-nav">
            <Nav className="menu">
              <div className="menu_top ml-4">
                  <Row>
                    {(user) ? 
                    <Col xs="4" lg="4" className="text-center mt-2" >
                      <Image_loading image={`/img/test/const.png`} className="rounded-circle" width={70} height={70} alt={`avatar do ${user.name}`} />
                      <p>Olá {user.name.split(" ")[0]}!</p>
                    </Col>
                    : ''
                    }
                    <Col xs="8" lg="8">
                      {links.map(item => (
                        <Link className="nav-link" role="button" to={item.link}>{item.name}
                        </Link>
                      ))}
                      {(user) ? 
                        <Nav.Link onClick={() => logout()}>LOGOUT</Nav.Link> 
                        : ''
                      }
                    </Col>
                  </Row>
              </div>
              <div className="menu_footer ml-1">
                <Row>
                  <Col xs="4" lg="4">
                    <div className="text-center m-2">
                      <Image src={theme.icon} fluid width="100%" />
                    </div>
                  </Col>
                  <Col xs="8" lg="8">
                    <Link className="nav-link" role="button" to="/terms">TERMOS DE USO</Link>
                    <Link className="nav-link" role="button" to="/terms">POLÍTICA DE USO</Link>
                  </Col>
                </Row>

              </div>
            </Nav>
          </Navbar.Collapse>

        </Navbars>
      </>
    );
  }

}
