import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import publications from '../../services/publications';

import Card_public from '../../components/card_public';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Navtabs, Card_teste } from './styles';


export default function Tab_banners(props: any) {

   const [keys, setKeys] = useState('home');
   let publication = props.publication;


   return (
      <Navtabs id="controlled-tab-example" activeKey={keys} onSelect={(k: any) => setKeys(k)} className="mt-5 pt-4 justify-content-center">
         <Tab eventKey="home" tabClassName="text-white" title="RECENTES">
            <Card_teste>
               <Carousel
                  plugins={[
                     'clickToChange',
                     'centered',
                     {
                        resolve: arrowsPlugin,
                        options: {
                           arrowLeft: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /></button>,
                           arrowLeftDisabled: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /></button>,
                           arrowRight: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleRight} className="icon-navbar fa-lg" /></button>,
                           arrowRightDisabled: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleRight} className="icon-navbar fa-lg" /></button>,
                           addArrowClickHandler: true,
                        }
                     },
                     {
                        resolve: slidesToShowPlugin,
                        options: {
                           numberOfSlides: 2
                        }
                     },
                  ]}
                  breakpoints={{
                     640: {
                        plugins: [
                           'clickToChange',
                           'centered',
                           {
                              resolve: arrowsPlugin,
                              options: {
                                 arrowLeft: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /></button>,
                                 arrowLeftDisabled: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /></button>,
                                 arrowRight: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleRight} className="icon-navbar fa-lg" /></button>,
                                 arrowRightDisabled: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleRight} className="icon-navbar fa-lg" /></button>,
                                 addArrowClickHandler: true,
                              }
                           },
                           {
                              resolve: slidesToShowPlugin,
                              options: {
                                 numberOfSlides: 2
                              }
                           },
                        ]
                     },
                     900: {
                        plugins: [
                           'clickToChange',
                           'centered',
                           {
                              resolve: arrowsPlugin,
                              options: {
                                 arrowLeft: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /></button>,
                                 arrowLeftDisabled: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /></button>,
                                 arrowRight: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleRight} className="icon-navbar fa-lg" /></button>,
                                 arrowRightDisabled: <button className="btn btn-light" type="button"><FontAwesomeIcon icon={faAngleRight} className="icon-navbar fa-lg" /></button>,
                                 addArrowClickHandler: true,
                              }
                           },
                           {
                              resolve: slidesToShowPlugin,
                              options: {
                                 numberOfSlides: 2
                              }
                           },
                        ]
                     }
                  }}
               >

                  {(publication) ?
                     publication.map((item: any, index: any) => (
                        <div className="m-4" key={`tab-${index}`}>
                           <Card_public publication={item} key={`tab-${index}`} width={140} height={170} />
                        </div>
                     )) :
                     <div className="text-center">
                        <p>Nenhuma publicação cadastrada</p>
                     </div>
                  }
               </Carousel>
            </Card_teste>

         </Tab>
         <Tab eventKey="profile" tabClassName="text-white" title="TODOS">
            <p className="bg-secondary">TODOS</p>
         </Tab>
      </Navtabs>
   );
}