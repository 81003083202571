import styled from 'styled-components';
import { Navbar, Button, FormControl, NavbarBrand } from 'react-bootstrap';

export const Navbars = styled(Navbar)`
   background-color: ${props => props.active ? props.theme.colors.secondary : props.theme.colors.primary};
   border-color: ${props => props.theme.colors.primary};
   .nav-link{
      color: ${props => props.theme.colors.text_color_secondary} !important;

   }
   p{
      color: ${props => props.theme.colors.text_color_secondary} !important;
   }
   form{
      width: 95% !important;
   }
   .menu{
      height: 100vh !important;
   }
   .menu_top{
      font-weight: bolder; 

   }
   .menu_footer {
      font-weight: 400; 
      position: absolute;
      bottom: 15vh;
      font-size: 10px;
   }

   .footer_autor{
      bottom: 20vh !important;
   }

   .div_menu{
      background-color: ${props => props.theme.colors.primary};
      width: 100%;
   }
   .btn-navbar{
      background-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary};
      border-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary};
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary} !important;
      border-top-right-radius: 0.55rem !important;
      border-bottom-right-radius: 0.55rem !important;

   }
   .hamburger-react{
      margin-top: -23px;
      left: -20px;
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary} !important;
      width:  20px !important;
      height: 20px !important;
   }

   .form_teste {
      width: 80%;
   }
`;
export const Buttons = styled(Button)`
   background-color: ${props => props.theme.colors.primary} !important;
   color: ${props => props.theme.colors.text_color_secondary} !important;
   margin-left: 5% !important;
`;

export const Buttons_go_back = styled(Button)`
   color: ${props => props.theme.colors.primary};
   padding: 0 !important;
`;

export const FormControls = styled(FormControl)`
   background-color: ${props => props.theme.colors.primary} !important;
   border-color: ${props => props.theme.colors.primary} !important;
   color: ${props => props.theme.colors.text_color_secondary} !important;
`;
export const Titles = styled(NavbarBrand)`
   margin: auto !important;
   margin-left: 50px !important;
   text-align: center !important;
   vertical-align: middle !important;
   color: ${props => props.theme.colors.text_color_secondary} !important;
`;

export const NavbarsToggles = styled(Navbar.Toggle)`
background-color: ${props => props.theme.colors.primary} !important;
border-color: ${props => props.theme.colors.text_color_secondary} !important;
`;

export const InputSearch = styled.div`
   border-top-left-radius: 0.55rem !important;
   border-bottom-left-radius: 0.55rem !important;
   margin-left: 5vw;
   margin-right: 5vw;
   background-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary};
   border-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary};;
   color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary}  !important;
   border-top-right-radius: 0.55rem !important;
   border-bottom-right-radius: 0.55rem !important;

   .div_search{
      svg{
         margin-left: 5vw;
      }
      width: 85vw;
      border-top-right-radius: 0.55rem !important;
      border-bottom-right-radius: 0.55rem !important;
      
      .form-control{
         border-top-right-radius: 0.55rem !important;
         border-bottom-right-radius: 0.55rem !important;
      }
   }

   .form-control{
      background-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary} !important;
      border-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary} !important;
      margin-left: 19px  !important;
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary}  !important;
   }
   .form-control::placeholder {
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary}  !important;
   }

   .icon-navbar{
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary}  !important;
   }
   svg{
      margin: auto;
      width: 50%;     
   }
   .btn-navbar{
      background-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary};
      border-color: ${props => props.draggable ? '#7C7C7C' : props.theme.colors.primary};
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary} !important;
      border-top-right-radius: 0.55rem !important;
      border-bottom-right-radius: 0.55rem !important;

   }
   .hamburger-react{
      margin-top: -23px;
      left: -20px;
      color: ${props => props.draggable ? props.theme.colors.primary : props.theme.colors.text_color_secondary} !important;
      width:  20px !important;
      height: 20px !important;
   }
`;