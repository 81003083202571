import axios from 'axios';
import moment from 'moment';

export default function auth() {

   const token_key: string = '';
   const base_url = process.env.REACT_APP_BASE_URL;

   const auth = {
      isAuthenticated: function isAuthenticated(): any {
         const itemStr = localStorage.getItem(token_key);
         if (!itemStr) {
            return false;
         }
         const item = JSON.parse(itemStr);
         const now = new Date();
         if (now.getTime() > item.expiry) {
            localStorage.removeItem(token_key)
            return false;
         }
         return true;
      },
      getToken: function getToken(): any {
         const itemStr = localStorage.getItem(token_key);
         if (!itemStr) {
            return null
         }
         const item = JSON.parse(itemStr);
         const now = new Date();
         if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(token_key)
            return null
         }
         return item.value
      },

      register: function register(data: any): any {

         return axios.post(`${base_url}register`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Content-Type': 'application/json'
            }
         });
      },

      login: function login(data: any): any {
         
         return axios.post(
            `${base_url}users/login`,
            JSON.stringify(data),
            {
               headers: {
                  'Content-Type': 'application/json'
               }
            })
            .then(function (response) {
               if (response.data) {
                  let ttl = response.data['ttl'].split(" ");
                  
                  // `item` is an object which contains the original value
                  // as well as the time when it's supposed to expire
                  const item = {
                     value: response.data['token'],
                     expiry: moment().add(ttl[0], ttl[1]).valueOf() ,
                  }
                  localStorage.setItem(token_key, JSON.stringify(item));
                  return 'success';
               }
            })
            .catch(function (error) {
               
               return error.response.data;
            });
      },
      validateEmail: function validateEmail(data: any): any {

         return axios.post(`${base_url}users/accesskey`, JSON.stringify(data),
         {
            headers: {
               'Content-Type': 'application/json'
            }
         })
         .then(function (response) {
            return 'success';
         })
         .catch(function (error) {
            console.log(error);
            return error;
         });
      },
      logout: function logout(): any {
         localStorage.removeItem('user');
         return localStorage.removeItem(token_key);
      }
   }

   return auth;
}
