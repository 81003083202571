import React, { useState, useContext } from 'react';

import Header from '../Header';
import auth from '../../services/auth';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Loading from '../../components/loading';
import Image_loading from '../../components/image_loading';

import InputMask from 'react-input-mask';
import usePeristedState from '../../utils/usePersistedState';

import { Signis } from './styles';

import { Card, Container, Row, Form, Button, Image, Col } from 'react-bootstrap';


export default function Signin() {

  let history = useHistory();
  const theme = useContext(ThemeContext);
  const [user, setUser] = usePeristedState<any>('user');
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [password3, setPassword3] = useState('');
  const [password4, setPassword4] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault()
    setLoading(true);
    setShow(true);
    setUser(null);
    if (!email || !password1 || !password2 || !password3 || !password4) {
      setError("Preencha senha para continuar!");
      setLoading(false);
      setShow(false);
    } else {

      setError("");
      const response = await auth().login({
        username: email,
        password: `${password1}${password2}${password3}${password4}`
      });

      if (response === 'success') {
        history.push("/dashboard");
      } else {
        if (response) {
          setError(response);
          setLoading(false);
          setShow(false);

        } else {
          setError("Ouve uma instabilidade nos serviços tente novamente mais tarde");
          setLoading(false);
          setShow(false);
        }
      }
    }

  }

  async function validateEmail(event: any) {
    event.preventDefault();
    setShow(true);
    setLoading(true);
    if (!email) {
      setError("Preencha e-mail para continuar!");
      setLoading(false);
      setShow(false);
    } else {

      setError("");
      const response = await auth().validateEmail({ email: email });

      if (response === 'success') {
        setOpen(!open);
        setLoading(false);
        setShow(false);
      } else {
        if (response != 'Error: Network Error') {
          setError(response);
          setLoading(false);
          setShow(false);

        } else {
          setError("Ouve uma instabilidade nos serviços tente novamente mais tarde");
          setLoading(false);
          setShow(false);
        }
      }
    }

  }

  let token = auth().isAuthenticated();

  if (token) {
    history.push("/dashboard");
  }

  function PressEnter(nextinputId: string, value: any) {
    if (value > 0) {
      const el1 = document.getElementById(nextinputId);
      if (el1) {
        el1.focus();
        return false;

      }

    }
  }

  return (
    <div>
      {(!open) ?
        <Header title="Login" />
        :
        <Header title="Senha" />
      }
      <Container>
        <Row className="justify-content-md-center">
          <div className="mt-5 pt-4">
            <div className="text-center m-2">
              <Image_loading image={theme.icon_dark} width={200} height={250} alt="logo" />
            </div>
            {(!open) ?

              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="mt-2">Email</Form.Label>
                  <Form.Control type="email" placeholder="seuemail@dominio.com"
                    value={email}
                    onChange={event => setEmail(event.target.value)} required />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </Form.Group>
                {error &&
                  <div className="text-center mt-4">
                    <Button variant="outline-danger" disabled >{error}</Button>
                  </div>
                }
                <div className="text-center">
                  <Button className="mt-5 mb-5" variant="secondary" size="lg" type="button"
                    onClick={(event) => validateEmail(event)}
                    disabled={isLoading}>
                    {isLoading ? 'Carregando...' : 'LOGAR'}
                  </Button>
                </div>
              </Form>

              :

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="mt-2">SENHA</Form.Label>
                  <Row>
                    <Col>
                      <InputMask className="form-control input_pass"
                        value={password1} mask="9"
                        onChange={event => { PressEnter('input2', event.target.value); setPassword1(event.target.value); }} required />
                    </Col>
                    <Col>
                      <InputMask className="form-control input_pass"
                        value={password2} mask="9" id="input2"
                        onChange={event => { PressEnter('input3', event.target.value); setPassword2(event.target.value); }} required />
                    </Col>
                    <Col>
                      <InputMask className="form-control input_pass"
                        value={password3} mask="9" id="input3"
                        onChange={event => { PressEnter('input4', event.target.value); setPassword3(event.target.value); }} required />
                    </Col>
                    <Col>
                      <InputMask className="form-control input_pass"
                        value={password4} mask="9" id="input4"
                        onChange={event => setPassword4(event.target.value)} required />
                    </Col>
                  </Row>
                </Form.Group>
                <p className="info_pass mt-3">Valide sua senha no seu email</p>
                {error &&
                  <div className="text-center mt-4">
                    <Button variant="outline-danger" disabled >{error}</Button>
                  </div>
                }
                <div className="text-center">
                  <Button className="mt-5 mb-5" variant="secondary" size="lg" type="button"
                    onClick={event => handleSubmit(event)}
                    disabled={isLoading}>
                    {isLoading ? 'Carregando...' : 'LOGAR'}
                  </Button>
                </div>
              </Form>
            }
          </div>

        </Row>
        <Loading show={show} />

      </Container>
    </div>
  )
}
