import React from 'react';
import './App.css';
import Routes from './routes'
import { ThemeProvider } from 'styled-components';
import Helmets from './components/helmets';
import publisher from './styles/themes/publisher';
import leitura_tech from './styles/themes/leitura_tech';
import store from './styles/themes/store';
import reader from './styles/themes/reader';

import GlobalStyle from './styles/global';

export default function App() {

  const infoTheme = () => {
    let t = publisher;
    if(process.env.REACT_APP_SIGLA === 'PUB '){
      t =  publisher;
    } else if (process.env.REACT_APP_SIGLA === 'RDR ') {
      if(window.location.host.indexOf(".leitura.tech") != -1) {
        t = leitura_tech;
      }else{
        t =  reader;
      }
    } else if (process.env.REACT_APP_SIGLA === 'STR ') {
      t =  store;
    }

    
    return t;
  }

  return (
    <ThemeProvider theme={infoTheme}>
      <div className="App">
        <Helmets />
        <GlobalStyle />
        <Routes />
      </div>
    </ThemeProvider>
  );
}
