import React, { useState, useEffect, useContext } from 'react';
import { Card, Container, Row, Col, Button, Modal, InputGroup, FormControl, Form  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Authors, Fabs } from './styles';
import Header from '../Header';
import Loading from '../../components/loading';

import auth from '../../services/auth';
import author from '../../services/author';

import { FloatingMenu, MainButton, Directions } from 'react-floating-button-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faPen, faTrash, faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from 'styled-components';

export default function Author() {

   const [show, setShow] = useState(false);
   const [author_id, setAutor_id] = useState(0);
   const [autor, setAutor] = useState([]);
   const [authors, setAuthors] = useState([]);
   const [isOpen, setIsOpen] = useState(false);
   const [termAuthor, setTermAuthor] = useState('');
   const [showModal, setShowModal] = useState(false);
 
   const theme = useContext(ThemeContext);

   let history = useHistory();
   let token = auth().isAuthenticated();

   if (!token) {
     history.push("/");
   }
   function updateAuthor(author: any) {

      history.push({
         pathname: '/author/update/'+ author.id,
         state: author
      });
   }

   const handleClose = () => {
      setAutor_id(0);
      setShowModal(false)
   };

   async function search(event: any) {
      event.preventDefault();
      let retor = authors.filter(
         function(i: any) { 
            return i['name'].toLowerCase().includes(termAuthor.toLowerCase()); 
         }
      )
      setAutor(retor)
      console.log(retor);
      
   }

   const deleteAuthor = () => {
      setShow(true);
      author().destroy(author_id)
      .then(function (result: any) {
         setAutor(
            autor.filter(
               function(i: any) { 
                  return i.id !== author_id; 
               }
            )
         );
         setShow(false);
         setShowModal(false);
      })
      .catch(function (error: any) {
      })
   };

   function handleShow (author: number) {
      if(author){
         setAutor_id(author);
         setShowModal(true);
      }
   }

   useEffect(() => {
      setShow(true);
      async function loadAuthor() {
  
         author().get().then((results: any) => {
            if(results){
               setAutor(results.response);
               setAuthors(results.response);
               setShow(false);

            }else{
               setShow(false);

            }
  
          });
      }
  
      loadAuthor();
    }, []);

   return (
      <Authors>
         <Header title="Todos os Autores" autor={true} />
         <Container className="mt-6 pt-3 pb-5" >
            <Form onSubmit={event => search(event)}>
              <InputGroup className="pesq-3">
                <FontAwesomeIcon icon={faSearch} className="icon-navbar" />
                <FormControl
                  type="search"
                  value={termAuthor}
                  onChange={event => setTermAuthor(event.target.value)}
                  placeholder="Pesquisar os autores"
                />
                <Button>
                <FontAwesomeIcon icon={faFilter} className="icon-navbar" />
                </Button>
              </InputGroup>

            </Form>
            <Row className="justify-content-md-center pt-4">
               {(autor.length > 0) ? 
                  autor.map((item: any) => (
                     <Card className="card-body mt-2 mb-2" key={item.id}>
                        <Row>
                           <Col xs="8" lg="8">
                              <p>{item.name}</p>
                           </Col>
                           <Col xs="4" lg="4">
                              <Button variant="info" size="sm" onClick={() => updateAuthor(item)} type="button"><FontAwesomeIcon icon={faPen} className="icon_fab" /></Button>
                              <Button variant="danger" className="mr-3" size="sm" onClick={() => handleShow(item.id)} type="button"><FontAwesomeIcon icon={faTrash} className="icon_fab" /></Button>
                           </Col>
                        </Row>
                     </Card>
                     )) 
                  : 
                  <div className="text-center">
                     <p>Nenhum Autor cadastrado</p>
                  </div>
               }
            </Row>
         </Container>

          <Fabs>
            <FloatingMenu
              slideSpeed={500}
              isOpen={isOpen}
              spacing={8}
              direction={Directions.Up}
            >
              <MainButton
                isOpen={isOpen}
                iconResting={<FontAwesomeIcon icon={faPlus} className="icon_fab" />}
                iconActive={<FontAwesomeIcon icon={faTimes} className="icon_fab" />}
                background={theme.colors.primary}
                onClick={() => {
                  setIsOpen((prev) => !prev);
                  history.push("/author/create");
                  
                }}
                size={56}
              />
            </FloatingMenu>
          </Fabs>
          
         <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
               <Modal.Title>Deletar Autor</Modal.Title>
            </Modal.Header>
            <Modal.Body>Você tem certeza que quer deletar este autor?</Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={deleteAuthor}>
                  Sim
               </Button>
               <Button variant="danger" onClick={handleClose}>
                  Não
               </Button>
            </Modal.Footer>
         </Modal>
         <Loading show={show} />
      </Authors>
   );
}