import styled from 'styled-components';

export const Authors = styled.div`

.btn-danger{
   margin-left: 10px !important;
}
.pesq-3{
   width: 100%;
   border-bottom: 1px solid ${props => props.theme.colors.secondary} !important;
   input{
      border-bottom: none !important;
   }
   svg{
      margin: auto;
      margin-left: 1rem;
      margin-right: 0.5rem;
   }
   button{
      background-color: #EAEAEA !important;
      color: ${props => props.theme.colors.secondary} !important;
      border: none !important;
   }
   button:focus {   
      border-color: ${props => props.theme.colors.primary};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px ${props => props.theme.colors.primary};
      outline: 0 none;
   }
}
`;

export const Fabs = styled.div`
position: fixed;
bottom:0px;
left:0px;
right:0px;
height:50px;
margin-bottom:5vh;
margin-right:5vw;
text-align: -webkit-right;
.icon_fab{
   color: ${props => props.theme.colors.text_color_secondary};
}
`;