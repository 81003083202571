import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Header from '../Header';

import { useInView } from 'react-intersection-observer';
import { Publication_lists, Spinners } from './styles';
import publications from '../../services/publications';
import Loading from '../../components/loading';
import Card_public  from '../../components/card_public';

export default function Publication_list(props: any) {

	const [publication, setPublication] = useState<any>([]);
	const [page_count, setPage_count] = useState(0);
	const [page_size, setPagesize] = useState(0);
	const [show, setShow] = useState(false);
	const [ref, inView] = useInView({
		threshold: 0,
	})

   async function getBuy ()  {
      
      publications().indexBuy({ page: (page_size + 1), pageSize: 20 }).then((teste2: any) => {
         setPagesize(page_size + 1);
         setPublication([...publication, ...teste2.results ]);
      });
   }

   async function getloan () {
      
      publications().indexLoans({ page: (page_size + 1), pageSize: 20 }).then((teste2: any) => {
         setPagesize(page_size + 1);
         setPublication([...publication, ...teste2.results ]);
      });
   }

   async function  getPublic () {
      
      publications().all({ page: (page_size + 1), pageSize: 20 }).then((teste2: any) => {
         setPagesize(page_size + 1);
         setPublication([...publication, ...teste2.results ]);
      });
   }

	if (inView) {
		if (page_size < page_count) {
         if(props.match.path == '/publications/buy'){
            getBuy();
         }else if(props.match.path == '/publications/loan'){
            getloan();
         }else if(props.match.path == '/publications/all'){
            getPublic();
         }
      }
   }

   
	useEffect(() => {
		setShow(true);
		async function loadPublicationsBuy() {

         publications().indexBuy({ pageSize: 20}).then((teste: any) => {
            setPublication(teste.results);
            setPage_count(teste.page_count - 1);
            setPagesize(0)
            setShow(false);
         });

		}
		async function loadPublicationsLoan() {

         publications().indexLoans({ pageSize: 20 }).then((teste: any) => {
            setPublication(teste.results);
            setPage_count(teste.page_count - 1);
            setPagesize(0)
            setShow(false);
         });

		}
		async function loadPublicationsPublic() {

         publications().all({ pageSize: 20 }).then((teste: any) => {
            setPublication(teste.results);
            setPage_count(teste.page_count - 1);
            setPagesize(0)
            setShow(false);
         });

		}

      if(props.match.path == '/publications/buy'){
         loadPublicationsBuy();
      }else if(props.match.path == '/publications/loan'){
         loadPublicationsLoan();
      }else if(props.match.path == '/publications/all'){
         loadPublicationsPublic();
      }


	}, []);

   return (
      <Publication_lists>
         <Header detalhe={true} />
         <Container className="mt-6 pt-2 pb-5">
            <Row>
               {(publication) ?
                  publication.map((item: any, index: any) => (
                     (item) ?
                     <Col className="mt-2" xs="4" lg="4" key={`all-${index}`}>
                        <Card_public publication={item} key={`all-${index}`} width={90} height={120} />
                     </Col>
                        : null
                  )) :
                  <div className="text-center p-auto m-auto ">
                     <p>Nenhuma publicação cadastrada</p>
                  </div>
               }
               {(page_size < page_count) ?
                  <div ref={ref} className={(inView) ? 'visible' : 'invisible'}>
                     <Spinners className="text-center p-auto m-auto mt-4">
                        <Spinner animation="border" />
                     </Spinners>
                  </div>
                  : ''
               }
            </Row>
            <Loading show={show} />
         </Container>
      </Publication_lists>
   );
}