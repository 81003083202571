import styled from 'styled-components';
export const Buttons_summarys = styled.div`
.btn_summary{
   
   color: ${props => props.theme.colors.text_color_primary } !important;
   width: 90% !important;
   display: block;
   font-size: 1rem !important;
   white-space: nowrap !important;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
   
   a{
      padding-left: 4vw !important;
      padding-right: 4vw !important;
   }
}
`