import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Image_loading(props: any) {

   let image = (props.image) ? props.image : 'https://storage.googleapis.com/statictest099/publication/cover/08b754d916d9f407eb82a0b93b5c6179.png';   

   return (
      <div>
         <LazyLoadImage
            alt={props.alt}
            height={props.height}
            key={image}
            className={props.className}
            effect="blur"
            src={image} // use normal <img> attributes as props
            width={props.width} />
      </div>
   );
}