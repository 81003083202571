const leitura_tech = {
   title: 'leitura tech',

   favicon: '/img/icons/leitura_icone.ico',
   icon: '/img/icons/leitura_icone-dark.svg',
   icon_dark: '/img/icons/leitura_icone.svg',
   colors: {
      primary: '#42A6DD',
      secondary: '#706F6F',
      text_color_primary: '#333333',
      text_color_secondary: '#ffffff',
   }
}
export default leitura_tech;