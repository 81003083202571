import React from 'react';
import { Card_comments } from './styles';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar  } from "@fortawesome/free-solid-svg-icons";
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

import Image_loading from '../image_loading';
 
export default function Card_comment(props: any) {

   const comment = props.comment;
   const regex1 = /(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)\/?/g;

   return(
      <Card_comments key={`${props.key}-${comment.id}`}>
         <Row>
            <Col xs="2" lg="1">
               <Image_loading image={(comment.user.image != '') ? comment.user.image :  '/img/test/const.png'} className="rounded-circle" width={50} height={50}  alt={`${comment.user.name} `} />
            </Col>
            <Col xs="10" lg="11">
               <h4 className="comment_name">{comment.user.name}<a className="comment_date ml-2">{moment(comment.created_at).format('DD/MM/YYYY HH:mm:ss')}  </a></h4>
               
               <p>{sanitizeHtml(comment.content, 
                  {
                     allowedTags: [],
                     allowedAttributes: {},
                     allowedSchemes: [],
                     nonTextTags: [ 'style', 'script', 'textarea', 'option', 'noscript', 'a' ]
                  }).replace(regex1, "").trim()}
               </p>
               <Rating
                  className="mb-2"
                  initialRating={Number(comment.rating)}
                  readonly
                  emptySymbol={<FontAwesomeIcon icon={farFaStar} className="icon_rating m-1" />}
                  fullSymbol={<FontAwesomeIcon icon={faStar} className="icon_rating m-1" />} /><br /> 
            </Col>
         </Row>
      </Card_comments>
   );

}