import styled from 'styled-components';

export const Card_annotations = styled.div`
.blockquote{
   border-left-style: solid;
   border-left-width: 5px;
   p{
      margin-left: 10px;
      font-style: italic;
      font-size: 0.9rem;
      text-transform: none;
   }
}
.mr-4{
   margin-right: 10px;
}
`;