import React, { useState } from 'react';
import { Card, Container, Row, Alert, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Authors_create } from './styles';
import Header from '../Header';
import Loading from '../../components/loading';

import auth from '../../services/auth';
import author from '../../services/author';

export default function Author_create(props: any) {

   const [show, setShow] = useState(false);
   const [autor, setAutor] = useState((props.history) ? props.history.location.state : null);
   const [id, setId] = useState((props.match) ? props.match.params.id : null);
   const [name, setName] = useState('');
   const [description, setDescription] = useState('');
   const [error, setError] = useState('');
   const [isLoading, setLoading] = useState(false);


   let history = useHistory();
   let token = auth().isAuthenticated();

   if (!token) {
      history.push("/");
   }

   const goBack = () => {
      history.push("/authors");

   }
   
   const updateAuthor = () => {
      setLoading(true); 
      setShow(true); 
      setError("");
      if(!autor.name || !autor.description ) {
         setError("Não é possivel remover o Nome e/ou Descrição do Autor!");
         setLoading(false); 
         setShow(false); 
      }else {
         if(autor.name.length >=3 || autor.description.length >=3 ) {
            author().update({name: autor.name, description: autor.description}, id)
            .then(function (response: any) {
               setLoading(false); 
               setShow(false); 
               history.push("/authors");
            })
            .catch(function (error: any) {
               
               setError(error.response.data);
               setLoading(false); 
               setShow(false); 
            })
         }else {
            setError("Nome e/ou Descrição precisam ter mais de 2 letras");
            setLoading(false); 
            setShow(false); 
         }
      }
   }
   const createAuthor = () => {
      setLoading(true); 
      setShow(true); 
      setError("");
      if(!name || !description ) {
         setError("Preencha Nome e a Descrição para continuar!");
         setLoading(false); 
         setShow(false); 
      }else {
         if(name.length >=3 || description.length >=3 ) {
            author().register({name: name, description: description})
            .then(function (response: any) {
               setLoading(false); 
               setShow(false); 
               history.push("/authors");
            })
            .catch(function (error: any) {
               
               setError(error.response.data);
               setLoading(false); 
               setShow(false); 
            })
         }else {
            setError("Nome e/ou Descrição precisam ter mais de 2 letras");
            setLoading(false); 
            setShow(false); 
         }
      }
   }

   if (autor) {
      return (
         <Authors_create>
            <Header title="Editar Autor" go_back={goBack} />
            <Container className="mt-6" >
               <Row className="justify-content-md-center">
                  <Card className="card-body">
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Nome do Autor</Form.Label>
                        <Form.Control type="text" placeholder="João Silva"
                           value={autor.name}
                           onChange={event => setAutor((prev: any) => ({ ...prev, name: event.target.value }))} required />
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Descrição do Autor</Form.Label>
                        <Form.Control type="text" placeholder="João Silva"
                           value={autor.description}
                           onChange={event => setAutor((prev: any) => ({ ...prev, description: event.target.value }))} required />
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                           </Form.Control.Feedback>
                     </Form.Group>
                     {error &&
                        <div className="text-center mt-4">
                           <Alert variant="danger" dismissible>{error}</Alert>
                        </div>
                     }
                     <div className="text-center mt-4">
                        <Button variant="secondary" size="lg" type="button"
                        onClick={!isLoading ? updateAuthor : undefined}
                        disabled={isLoading}>
                           {isLoading ? 'Carregando...' : 'EDITAR'}</Button>

                     </div>

                  </Card>
               </Row>
            </Container>

            <Loading show={show} />
         </Authors_create>
      );

   } else {
      return (
         <Authors_create>
            <Header title="Novo Autor" go_back={goBack}  />
            <Container className="mt-6" >
               <Row className="justify-content-md-center">
                  <Card className="card-body">
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Nome do Autor</Form.Label>
                        <Form.Control type="text" placeholder="João Silva"
                           value={name}
                           onChange={event => setName(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                           </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Descrição do Autor</Form.Label>
                        <Form.Control type="text" placeholder="João Silva"
                           value={description}
                           onChange={event => setDescription(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                           </Form.Control.Feedback>
                     </Form.Group>
                     {error &&
                        <div className="text-center mt-4">
                           <Alert variant="danger" dismissible>{error}</Alert>
                        </div>
                     }
                     <div className="text-center mt-4">
                        <Button variant="secondary" size="lg" type="button"
                        onClick={!isLoading ? createAuthor : undefined}
                        disabled={isLoading}>
                        {isLoading ? 'Carregando...' : 'REGISTRAR'}</Button>
                     </div>

                  </Card>
               </Row>
            </Container>

            <Loading show={show} />
         </Authors_create>
      );

   }
}