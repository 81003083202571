import axios from 'axios';
import auth from './auth';

export default function loan() {
   let base_url = process.env.REACT_APP_BASE_URL;

   let loan = {
      register: function register(data: any): any {
         
         return axios.post(`${base_url}v1/publications/loan`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      registerDate_loan: function registerDate_loan(data: any): any {
         
         return axios.post(`${base_url}v1/publications/loans/create`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      getDate_loan: function getDate_loan(): any {
         
         return axios.get(`${base_url}v1/publications/loans`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      updateDate_loan: function updateDate_loan(data: any, id: string): any {
         
         return axios.put(`${base_url}v1/publications/loans/update/${id}`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      }
   }
   
   return loan;
}