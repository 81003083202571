import styled from 'styled-components';

export const Modal_comments = styled.div`

.icon_rating{
   color: ${props => props.theme.colors.primary} !important;
}
.contAreatext {
   font-size: 12px;
   color: #706F6F;
}
.btn_buy{
   background-color: ${props => props.theme.colors.primary} !important;
   color: #ffffff !important;
   border-color: ${props => props.theme.colors.primary} !important;
}
`;