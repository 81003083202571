import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Alert, Button } from 'react-bootstrap';

import Header from '../Header';
import Loading from '../../components/loading';

import auth from '../../services/auth';
import loan from '../../services/loan';
import { Date_loans } from './styles';

import { useHistory } from 'react-router-dom';

export default function Date_loan(props: any) {

   const [date, setDate] = useState('');
   const [maxDate, setMaxDate] = useState('');
   const [id, setId] = useState('');
   const [show, setShow] = useState(false);
   const [error, setError] = useState('');
   const [isLoading, setLoading] = useState(false);
   let history = useHistory();
   let token = auth().isAuthenticated();

   if (!token) {
      history.push("/");
   }

   if (process.env.REACT_APP_SIGLA != 'PUB ') {
      history.push("/");
   }

   useEffect(() => {
		async function loadDate() {
         setShow(true);
         loan().getDate_loan()
         .then(function (response: any) {
            setLoading(false); 
            setShow(false);
            console.log(response.data);
            
            setId(response.data.id);
            setDate((response.data.end_date ?? 30));
            setMaxDate((response.data.max_end_date ?? 30));
         })
      }
      
      if (process.env.REACT_APP_SIGLA === 'PUB ') {
         loadDate();
      }
   }, []);

   const updateLoan = () => {
      setShow(true);
      setLoading(true);
      setError("");
      console.log({end_date: date,max_end_date: maxDate});
      
      loan().updateDate_loan({end_date: date,max_end_date: maxDate}, id)
      .then(function (response: any) {
         setLoading(false); 
         setShow(false); 
         // history.push("/authors");
      })
      .catch(function (error: any) {

         console.log(error.response);
         setLoading(false);
         setShow(false);
      })
   }

   return (
      <Date_loans>
         <Header title="Data de EMPRÉSTIMO" autor={true} />

         <Container className="mt-6 pt-2" >
            <div>
               <Form.Label className="mt-2">Incio do Periodo</Form.Label>
               <Form.Control as="select" className="form-select"
                  onChange={event => setDate(event.target.value)} required>
                  <option value="30" selected={(date == '30') ? true : false} >30 dias</option>
                  <option value="60" selected={(date == '60') ? true : false} >60 dias</option>
                  <option value="90" selected={(date == '90') ? true : false} >90 dias</option>
               </Form.Control>
               <Form.Label className="mt-2">Final do Periodo</Form.Label>
               <Form.Control as="select" className="form-select"
                  onChange={event => setMaxDate(event.target.value)} required>
                  <option value="30" selected={(maxDate == '30') ? true : false} >30 dias</option>
                  <option value="60" selected={(maxDate == '60') ? true : false} >60 dias</option>
                  <option value="90" selected={(maxDate == '90') ? true : false} >90 dias</option>
               </Form.Control>
               {error &&
                  <div className="text-center mt-4">
                     <Alert variant="danger" dismissible>{error}</Alert>
                  </div>
               }
               <div className="text-center mt-4">
                  <Button variant="secondary" size="lg" type="button"
                  onClick={!isLoading ? updateLoan : undefined}
                  disabled={isLoading}>
                     {isLoading ? 'Carregando...' : 'EDITAR'}
                  </Button>

               </div>
            </div>
            <Loading show={show} />
         </Container>
      </Date_loans>
   );
}