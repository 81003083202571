import React, { useState } from 'react';
import Header from '../Header';

import { Divs } from './styles';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import Image_loading from '../../components/image_loading';
import Loading from '../../components/loading';

import { useHistory } from 'react-router-dom';
import licenses from '../../services/licenses';


export default function Conclusion(props: any) {

   const [obj, setObj] = useState((props.history) ? props.history.location.state : null);
   const [isLoading, setLoading] = useState(false);
   const [show, setShow] = useState(false);
   let history = useHistory();
   
   if(!obj){

      history.push("/");
   }

   const openReader = (publication: any) => {
      
      history.push({
         pathname: '/publications/reader',
         state: obj
      })
   }

   const getlicense = () => {
      setLoading(true);
      setShow(true);
      licenses().register(obj.uuid)
      .then((results: any) => {
         fileDownload(JSON.stringify(results.data), `${obj.publication.title}.lcpl`);
         setLoading(false);
         setShow(false);
      })
      .catch((error: any) => {
        
      })
   }

   if(obj.type === 'create'){

      return (
         <>
            <Header title="Cadastro - Concluido" />
            <Container >
               <Row className="justify-content-md-center">
                  <Card className="card-body mt-5 mb-5" >
                     <Divs className="text-center">
                        
                        <Image_loading image={`/img/avatar/avatar_${Math.floor(Math.random() * 8) + 1}.svg`} width={150} height={200} alt="avatar" className="mb-2"/>
                        <h3>PARABÉNS</h3>
                        <h5>seu cadastro foi realizado!!!</h5>
                        <p>Verifique seu email: {obj.email} , e valide sua conta </p>
                        <div className="text-center mt-5 mb-5">
                           <Button variant="secondary" size="lg" type="button" onClick={() => history.push(`/`)}>CONTINUAR</Button>
                        </div>
   
                     </Divs>
                  </Card>
   
               </Row>
   
            </Container>
         </>
      );
   }else if(obj.type === 'loan') {
      
      return (
         <>
            <Header title="Empréstimo - Concluido" />
            <Container >
               <Row className="justify-content-md-center mt-5 pt-2">
                  <Divs className="text-center">
                     
                     <Image_loading image={`/img/avatar/avatar_${Math.floor(Math.random() * 8) + 1}.svg`} width={150} height={200} alt="avatar" className="mb-2"/>
                     <h3>PARABÉNS</h3>
                     <h5>seu Empréstimo foi realizado!!!</h5>
                     <p>Sua licença do livro {obj.publication.title} do {obj.publication.author.name} esta pronta</p>
                     <p>Faça o download da sua licença agora ou pegue posteriormente no seus Empréstimos</p>
                     <Row className="justify-content-md-center">
                        <Col xs="6" lg="6">
                           <div className="text-center mt-5 mb-5">
                              <Button variant="secondary" size="lg" type="button"  onClick={() => history.push(`/`)}>CONTINUAR</Button>
                           </div>
                        </Col>
                        <Col xs="6" lg="6">
                           
                           <div className="text-center mt-5 mb-5">
                              <Button variant="success" size="lg" type="button" 
                                 onClick={!isLoading ? getlicense : undefined}
                                 
                                 >
                                    DOWNLOAD
                              </Button>
                           </div>
                        </Col>
                        <Col xs="3" lg="3">
                        </Col>
                        <Col xs="6" lg="6">
                           <div className="text-center">
                              <Button className="text-white" variant="info" size="lg" type="button" onClick={openReader}>LER AGORA</Button>
                           </div>
                        </Col>
                        <Col xs="3" lg="3">
                        </Col>
                     </Row>
                  </Divs>
               </Row>

            <Loading show={show} />
            </Container>
         </>
      );
   }else{

      return (
         <>
            <Header title="Compra - Concluido" />
            <Container >
               <Row className="justify-content-md-center">
                  <Card className="card-body mt-5 mb-5" >
                     <Divs className="text-center">
                        
                        <Image_loading image={`/img/avatar/avatar_${Math.floor(Math.random() * 8) + 1}.svg`} width={150} height={200} alt="avatar" className="mb-2"/>
                        <h3>PARABÉNS</h3>
                        <h5>seu Compra foi realizado!!!</h5>
                        <p>Sua licença do livro {obj.name} do {obj.author.name} esta pronta</p>
                        <p>Faça o download da sua licença agora ou pegue posteriormente no seus Livros</p>
                        <div className="text-center mt-5 mb-5">
                           <Button variant="secondary" size="lg" type="button"  onClick={() => history.push(`/`)}>CONTINUAR</Button>
                        </div>

                     </Divs>
                  </Card>

               </Row>

               <Loading show={show} />
            </Container>
         </>
      );
   }
}