import axios from 'axios';
import auth from './auth';

export default function authors() {
   let base_url = process.env.REACT_APP_BASE_URL;

   let authors = {
      get: function get(): any {
         return axios(
            {
               method: 'get',
               url: `${base_url}v1/organization/authors`,
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            })
            .then(function (response) {
               return { response: response.data };
            })
            .catch(function (error) {
               console.log(error);
            });
      },
      getOrg: function getOrg(org_id: number): any {
         return axios(
            {
               method: 'get',
               url: `${base_url}authors/${org_id}`,
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            })
            .then(function (response) {
               return { response: response.data };
            })
            .catch(function (error) {
               console.log(error);
            });

      },

      register: function register(data: any): any {
         return axios.post(
            `${base_url}v1/organization/authors`,
            JSON.stringify(data),
            {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            });
      },

      update: function update(data: any, author_id: number): any {
         return axios.put(
            `${base_url}v1/organization/authors/${author_id}`,
            JSON.stringify(data),
            {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            });
      },

      destroy: function destroy(author_id: number): any {
         return axios.delete(
            `${base_url}v1/organization/authors/${author_id}`,
            {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               
               return error.response.data;
            });
      },

   };

   return authors;
}
