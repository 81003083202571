import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Table, Modal, Button, Alert, Form, Spinner } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Rating from 'react-rating';

import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Image_loading from '../../components/image_loading';
import Card_comment from '../../components/card_comment';

import ReactTooltip from 'react-tooltip';
import auth from '../../services/auth';
import { useInView } from 'react-intersection-observer';
import { FloatingMenu, MainButton, ChildButton, Directions } from 'react-floating-button-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons'
import { faPen, faTimes, faEllipsisV, faTrash, faChartLine, faLocationArrow, faSkull, faRedoAlt, faStar } from "@fortawesome/free-solid-svg-icons";

import Header from '../Header';

import { Div_publications, Fabs, Fabs2, Spinners } from './styles';

import Loading from '../../components/loading';
import Modal_comment from '../../components/modal_comment';

import publications from '../../services/publications';
import comments_service from '../../services/comments';
import loan from '../../services/loan';

export default function Publication(props: any) {

   let id = props.match.params.id;

   const [publication, setPublication]: any = useState('');
   const [author, setAuthor]: any = useState('');
   const [comment, setComment]: any = useState('');
   const [comments, setComments]: any = useState([]);
   const [rating, setRating]: any = useState('');
   const [show, setShow] = useState(false);
	const [page_count, setPage_count] = useState(0);
	const [page_size, setPagesize] = useState(0);
	const [showComments, setShowComments] = useState(false);
	const [ref, inView] = useInView({
		threshold: 0,
	})
   const [isLoading, setLoading] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [showModal2, setShowModal2] = useState(false);
   const [isOpen, setIsOpen] = useState(false);
   const [isOpenModalComment, setIsOpenModalComment] = useState(false);
   const [error, setError] = useState('');
   const [tooltipEdit, setTooltipEdit] = useState<any>();
   const [tooltipShare, setTooltipShare] = useState<any>();
   const [tooltipStatistic, setTooltipStatistic] = useState<any>();
   const [tooltipDisable, setTooltipDisable] = useState<any>();
   const [tooltipEnable, setTooltipEnable] = useState<any>();
   const [tooltipDelete, setTooltipDelete] = useState<any>();
   const theme = useContext(ThemeContext);

   let type = '';
   let history = useHistory();
   let token = auth().isAuthenticated();

   const handleClose = () => {

      setShowModal(false)
   };

   const handleClose2 = () => {

      setShowModal2(false)
   };

   const deletePublication = () => {
      if (process.env.REACT_APP_SIGLA === 'PUB ') {
         setShow(true);
         publications().destroy(id)
            .then((results2: any) => {
               setShow(false);
               setShowModal(false);
               setIsOpen(false);
               setError('');
               setPublication((prev: any) => ({ ...prev, enabled: false }));
            })
            .catch(function (error: any) {
               setError(error);
               setShow(false);
            });
      }
   }

   const enabledPublication = () => {
      if (process.env.REACT_APP_SIGLA === 'PUB ') {
         setShow(true);
         publications().enabled(id)
            .then((results2: any) => {
               setShow(false);
               setShowModal2(false);
               setIsOpen(false);
               setPublication((prev: any) => ({ ...prev, enabled: true }));
            })
            .catch(function (error: any) {
               console.log(error);
               setError(error);
               setShow(false);
            });
      }
   }

   const showLoanding = () => {
      setShow(true);
   }

   const inShowLoanding = () => {
      setShow(false);
   }

   const createLoan = () => {

      setShow(true);
      if (!token) {
         history.push({
            pathname: '/login',
            state: `/publication/${publication.id}`
         });
      } else {
         loan().register({ id: publication.id, org_id: publication.org_id })
            .then((results: any) => {
               let info: any = {
                  type: publication.type,
                  publication: publication,
                  uuid: results.data.uuid
               };
               history.push({
                  pathname: '/conclusion',
                  state: info
               });
               setShow(false);
            })
            .catch((error: any) => {

            })
      }
   }

   const createPurchase = () => {

      if (!token) {
      } else {

      }
   }

   const openModalComment = () => {
      if (token){
         setIsOpenModalComment(true);
      }else{
         history.push({
            pathname: '/login',
            state: `/publication/${publication.id}`
         });

      }
   }
   const closeModalComment = () => {
      setIsOpenModalComment(false);

   }

   async function getCommets () {
      
      comments_service().all(id, { page: (page_size + 1), pageSize: 10 }).then((teste2: any) => {
         setPagesize(page_size + 1);
         setComments([...comments, ...teste2.results ]);
      });
   }

   useEffect(() => {
      setShow(true);
      async function getPublication() {
         publications().get([], id).then((results: any) => {
            if (results.id) {
               setPublication(results);
               setAuthor(results.author);

               setShow(false);
            } else {
               history.push("/");
            }
         });
      }

      async function getPublicationOrg() {
         publications().getOrg([], id).then((results: any) => {
            if (results.id) {
               setPublication(results);
               setAuthor(results.author);

               setShow(false);
            } else {
               history.push("/");
            }
         });
      }

      async function getCommet() {
         comments_service().all(id, []).then((results: any) => {
            setComments(results.results);
            setPage_count(results.page_count - 1);
            setPagesize(0)
         })
			.catch(function (error: any) {
            console.log(error);
            
            setComments([]);
            setPage_count(0);
            setPagesize(0)
			});
      }

      if (process.env.REACT_APP_SIGLA === 'PUB ') {
         getPublicationOrg();
      } else {
         getPublication();

      }
      getCommet();
   }, []);

   if (publication.type === 'purchase') {
      type = 'COMPRA';
   } else if (publication.type === 'loan') {
      type = 'EMPRÉSTIMO';
   }

   if (isOpen) {
      if (publication.enabled) {
         ReactTooltip.show(tooltipEdit);
         ReactTooltip.show(tooltipShare);
         ReactTooltip.show(tooltipStatistic);
         ReactTooltip.show(tooltipDisable);

      } else {
         ReactTooltip.show(tooltipEnable);
         ReactTooltip.show(tooltipDelete);

      }
   }

   return (
      <Div_publications>
         <Header title="Detalhes do Livro" autor={true} />
         <div className="background pt-3" >
            <Container className="mt-5" key={`${publication.uuid}`}>
               <Row>
                  <Col xs="6" lg="6" className="mb-4 ">
                     <Image_loading image={publication.cover} width={150} height={200} alt={`${publication.title}`} />
                  </Col>
                  <Col xs="6" lg="6" className="mt-4 mb-4">
                     <h2>{publication.title}</h2>
                  </Col>
                  <Col xs="6" lg="6">
                     <p className="author"><a onClick={() => history.push(`/search/${author.name}`)}>{author.name}</a></p>
                     <p className="editora">{publication.entidade}</p>
                  </Col>
                  <Col xs="6" lg="6">
                     <Rating
                        className="mb-2"
                        initialRating={publication.rating}
                        emptySymbol={<FontAwesomeIcon icon={farFaStar} className="icon_rating m-1" />}
                        fullSymbol={<FontAwesomeIcon icon={faStar} className="icon_rating m-1" />}
                        onChange={event => {setRating(event); openModalComment(); }} /><br />

                     {(publication.type === 'purchase') ?
                        <>
                           {(parseFloat(publication.discount) == 0) ?
                              <p className="price">{`${publication.currency}${Number(parseFloat(publication.price) / 100).toFixed(2)}`}</p>
                              :
                              <>
                                 <p className="price"><s>{`${publication.currency}${Number(parseFloat(publication.price) / 100).toFixed(2)}`}</s></p>
                                 <p className="discount">{`${publication.currency}${Number(publication.price / 100).toFixed(2)}`}</p>
                              </>
                           }
                        </>
                        :
                        ''
                     }
                     {(process.env.REACT_APP_SIGLA != 'PUB ') ?
                        <Button className="btn_buy" variant="light"
                           onClick={!isLoading ? ((publication.type === 'purchase') ? createPurchase : createLoan) : undefined}
                           disabled={isLoading}>
                           {type}
                        </Button>
                        : ''
                     }
                  </Col>
                  <Col xs="12" lg="12">
                     <div className="mt-2 mb-2">
                        <Badge className="bg-light text-dark mr-2" key={`${publication.type}-${publication.id}`}>{`para ${type}`}</Badge>
                        <Badge className={`bg-${(publication.enabled) ? 'success' : 'danger'}`} >{`${(publication.enabled) ? 'Ativo' : 'Desativado'}`}</Badge>
                     </div>
                     <p className="description mt-4 mb-4">{publication.description}</p>

                  </Col>
               </Row>
            </Container>


         </div>
         <Container className="pb-5">
            <h6 className="div_new mt-2 mb-2">INFORMAÇÕES SOBRE O LIVRO</h6>
            <Table striped bordered hover>
               <tbody>
                  <tr>
                     <th>ISBN</th>
                     <td>{publication.isbn}</td>
                  </tr>
                  <tr>
                     <th>Data Produção</th>
                     <td>{moment(publication['data_producao']).format('DD/MM/YYYY')}</td>
                  </tr>
                  <tr>
                     <th>Data Publicação</th>
                     <td>{moment(publication['data_publicacao']).format('DD/MM/YYYY')}</td>
                  </tr>
                  <tr>
                     <th>Gênero</th>
                     <td>{(publication.genre) ?
                        publication.genre.toString() : ''
                     }</td>
                  </tr>
                  <tr>
                     <th>Idioma</th>
                     <td>{publication.idioma}</td>
                  </tr>
                  <tr>
                     <th>Pais</th>
                     <td >{publication.pais}</td>
                  </tr>
                  {(publication.type === 'loan') ?
                     <>
                        <tr>
                           <th>Data de Empréstimo</th>
                           <td>{publication['end_date']} dias</td>
                        </tr>
                        <tr>
                           <th>Data limite de Empréstimo</th>
                           <td>{publication['max_end_date']} dias</td>
                        </tr>
                     </>
                     : ''

                  }
               </tbody>
            </Table>

            {((process.env.REACT_APP_SIGLA != 'PUB ') && (token)) ?
            
               <Row>
                  <Col xs="12" lg="12">
                     <Form.Label className="mt-2">Escreva um Comentário</Form.Label>
                     <Form.Control as="textarea" aria-required="true" maxLength={500} rows={3} placeholder="Seu Comentário..."
                        value={comment}
                        onChange={event => setComment(event.target.value)} required />
                     <p className="text-right contAreatext">{500 - Number(comment.length)}/500</p>
                  </Col>
                  <Col xs="7" lg="7">
                  </Col>
                  <Col xs="5" lg="5">
                     <Button className="btn_buy" variant="light"
                        onClick={!isOpenModalComment ? openModalComment : undefined}
                        disabled={isOpenModalComment}>
                           {isOpenModalComment ? 'Carregando...' : 'COMENTAR'}
                     </Button>
                  </Col>
               </Row>
               : ''
            }
            <div className="mt-4">
               {(comments) ?
                  comments.map((item: any, index: any) => (
                     <Card_comment comment={item} key={`comments-${index}`} />
                  )) :
                  <div className="text-center p-auto m-auto ">
                     <p>Nenhum comentario cadastrado</p>
                  </div>
               }
               {(page_size < page_count) ?
                  <div ref={ref} className={(inView) ? 'visible' : 'invisible'}>
                     <Spinners className="text-center p-auto m-auto mt-4">
                        <Spinner animation="border" />
                     </Spinners>
                  </div>
                  : ''
               }
            </div>
         </Container>
         {(process.env.REACT_APP_SIGLA === 'PUB ') ?
            <>
               {(publication.enabled) ?
                  <Fabs>
                     <FloatingMenu
                        slideSpeed={500}
                        isOpen={isOpen}
                        spacing={8}
                        direction={Directions.Up}
                     >
                        <MainButton
                           isOpen={isOpen}
                           iconResting={<FontAwesomeIcon icon={faEllipsisV} className="icon_fab" />}
                           iconActive={<FontAwesomeIcon icon={faTimes} className="icon_fab" />}
                           background={theme.colors.primary}
                           onClick={() => setIsOpen(!isOpen)}
                           size={56}
                        />
                        <ChildButton
                           icon={
                              <div ref={ref => setTooltipEdit(ref)} data-tip data-for='tooltipEdit' >
                                 <FontAwesomeIcon icon={faPen} className="icon_fab" />
                                 <ReactTooltip id='tooltipEdit' place="left" type="light" effect="solid" >
                                    <b>Editar</b>
                                 </ReactTooltip>
                              </div>
                           }
                           background={theme.colors.primary}
                           size={40}
                           onClick={() => history.push(`/publications/edit/${publication.id}`)}
                        />
                        <ChildButton
                           icon={
                              <div ref={ref => setTooltipShare(ref)} data-tip data-for='tooltipShare' >
                                 <FontAwesomeIcon icon={faLocationArrow} className="icon_fab" />
                                 <ReactTooltip id='tooltipShare' place="left" type="light" effect="solid" >
                                    <b>Compartilhar</b>
                                 </ReactTooltip>
                              </div>
                           }
                           background={theme.colors.primary}
                           size={40}
                           onClick={() => console.log('First button clicked')}
                        />
                        <ChildButton
                           icon={
                              <div ref={ref => setTooltipStatistic(ref)} data-tip data-for='tooltipStatistic' >
                                 <FontAwesomeIcon icon={faChartLine} className="icon_fab" />
                                 <ReactTooltip id='tooltipStatistic' place="left" type="light" effect="solid" >
                                    <b>Estatística</b>
                                 </ReactTooltip>
                              </div>
                           }
                           background={theme.colors.primary}
                           size={40}
                           onClick={() => console.log('First button clicked')}
                        />
                        <ChildButton
                           icon={
                              <div ref={ref => setTooltipDisable(ref)} data-tip data-for='tooltipDisable' >
                                 <FontAwesomeIcon icon={faTrash} className="icon_fab" />
                                 <ReactTooltip id='tooltipDisable' place="left" type="light" effect="solid" >
                                    <b>Desabilitar</b>
                                 </ReactTooltip>
                              </div>
                           }
                           background={theme.colors.primary}
                           size={40}
                           onClick={() => setShowModal(true)}
                        />
                     </FloatingMenu>
                  </Fabs>
                  :
                  <Fabs2>
                     <FloatingMenu
                        slideSpeed={500}
                        isOpen={isOpen}
                        spacing={8}
                        direction={Directions.Up}
                     >
                        <MainButton
                           isOpen={isOpen}
                           iconResting={<FontAwesomeIcon icon={faEllipsisV} className="icon_fab" />}
                           iconActive={<FontAwesomeIcon icon={faTimes} className="icon_fab" />}
                           background={theme.colors.primary}
                           onClick={() => setIsOpen(!isOpen)}
                           size={56}
                        />
                        <ChildButton
                           icon={
                              <div ref={ref => setTooltipEnable(ref)} data-tip data-for='tooltipEnable' >
                                 <FontAwesomeIcon icon={faRedoAlt} className="icon_fab" />
                                 <ReactTooltip id='tooltipEnable' place="left" type="light" effect="solid" >
                                    <b>Reativar</b>
                                 </ReactTooltip>
                              </div>
                           }
                           background={theme.colors.primary}
                           size={40}
                           onClick={() => setShowModal2(true)}
                        />
                        <ChildButton
                           icon={
                              <div ref={ref => setTooltipDelete(ref)} data-tip data-for='tooltipDelete' >
                                 <FontAwesomeIcon icon={faSkull} className="icon_fab" />
                                 <ReactTooltip id='tooltipDelete' place="left" type="light" effect="solid" >
                                    <b>Deletar</b>
                                 </ReactTooltip>
                              </div>
                           }
                           background={theme.colors.primary}
                           size={40}
                           onClick={() => setShowModal2(true)}
                        />
                     </FloatingMenu>
                  </Fabs2>
               }
            </>

            : ''}
         <Loading show={show} />
         {(isOpenModalComment) ?
            <Modal_comment show={isOpenModalComment} funClose={closeModalComment} comment={comment} publication_id={publication.id} rating={rating} funShow={showLoanding} funInShow={inShowLoanding} /> 
         : '' }

         {(process.env.REACT_APP_SIGLA === 'PUB ') ?
            <>
               <Modal show={showModal} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                     DELETAR
                  </Modal.Header>
                  <Modal.Body>
                     <p>Realmente deseja deletar esta publicação?</p>
                     {(error) ?
                        <div className="text-center mt-4">
                           <Alert variant="danger" dismissible>{error}</Alert>
                        </div>
                        :
                        ''
                     }
                  </Modal.Body>

                  <Modal.Footer>
                     <Button variant="danger" onClick={handleClose}>NÃO</Button>
                     <Button variant="primary" onClick={deletePublication}>SIM</Button>
                  </Modal.Footer>
               </Modal>
               <Modal show={showModal2} onHide={handleClose2} centered>
                  <Modal.Header closeButton>
                     REATIVAR
                  </Modal.Header>
                  <Modal.Body>
                     <p>Realmente deseja reativar esta publicação?</p>
                     {(error) ?
                        <div className="text-center mt-4">
                           <Alert variant="danger" dismissible>{error}</Alert>
                        </div>
                        :
                        ''
                     }
                  </Modal.Body>

                  <Modal.Footer>
                     <Button variant="danger" onClick={handleClose2}>NÃO</Button>
                     <Button variant="primary" onClick={enabledPublication}>SIM</Button>
                  </Modal.Footer>
               </Modal>
            </> : ''}
      </Div_publications>
   )

}
