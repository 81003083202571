import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
.mt-6{
   margin-top: 4.5rem !important;
}

.modal-content {
   background-color: #EAEAEA !important;
}
body {
   .modal-backdrop.show {
      opacity: 0;
   }
   font-family: 'Roboto';
   color: ${props => props.theme.colors.text_color_primary};
   background-color: #EAEAEA !important;
   text-transform: uppercase;

   .card{
      background-color: #EAEAEA !important;
   }
   input {
      background-color: #EAEAEA !important;
      border: none !important;
      border-bottom: 1px solid ${props => props.theme.colors.secondary} !important;
      color: ${props => props.theme.colors.secondary} !important;
      border-radius: 0rem !important;
   }

   .btn-primary{
      background-color: ${props => props.theme.colors.primary} !important;
      border-color: ${props => props.theme.colors.primary} !important;
      :focus{
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px ${props => props.theme.colors.primary};
      outline: 0 none;

      }

   }
   select{
      background-color: #EAEAEA !important;
      border: none !important;
      border-bottom: 1px solid ${props => props.theme.colors.secondary} !important;
      color: ${props => props.theme.colors.secondary} !important;
      border-radius: 0rem !important;
   }
   textarea{
      background-color: #EAEAEA !important;
      border: none !important;
      border-bottom: 1px solid ${props => props.theme.colors.secondary} !important;
      color: ${props => props.theme.colors.secondary} !important;
      border-radius: 0rem !important;
   }
   textarea:focus,
   input[type="text"]:focus,
   input[type="password"]:focus,
   input[type="datetime"]:focus,
   input[type="datetime-local"]:focus,
   input[type="date"]:focus,
   input[type="month"]:focus,
   input[type="time"]:focus,
   input[type="week"]:focus,
   input[type="number"]:focus,
   input[type="email"]:focus,
   input[type="url"]:focus,
   input[type="search"]:focus,
   input[type="tel"]:focus,
   input[type="color"]:focus,
   select:focus,
   .uneditable-input:focus {   
      border-color: ${props => props.theme.colors.primary};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px ${props => props.theme.colors.primary};
      outline: 0 none;
   }
   input::placeholder {
      color: ${props => props.theme.colors.secondary} !important;
   }
}
`;