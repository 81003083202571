import axios from 'axios';
import auth from './auth';

export default function licenses() {
   let base_url = process.env.REACT_APP_BASE_URL;

   let licenses = {
      register: function register(uuid: string): any {
         
         return axios.get(`${base_url}v1/purchases/license/${uuid}`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      active: function active(uuid: string): any {
         
         return axios.post(`${base_url}v1/licenses/active/${uuid}`, {},
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      cancel: function cancel(uuid: string): any {
         
         return axios.post(`${base_url}v1/licenses/cancel/${uuid}`, {},
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      revoke: function revoke(uuid: string): any {
         
         return axios.post(`${base_url}v1/licenses/revoke/${uuid}`, {},
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      update: function update(data: any, uuid: string): any {
         
         return axios.put(`${base_url}v1/licenses/${uuid}`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      }

   }
   
   return licenses;
}