import styled from 'styled-components';

export const Publication_creates = styled.div`
.fileContainer {
   background-color: #EAEAEA !important;
   p{
      text-align: center;
      width: 60%;
   }
   
   .card{
      background-color: #EAEAEA !important;
   }
}

.btn-light{
   color: #212529 !important;
   background-color: #EAEAEA !important;
   border-radius: 0rem !important;
   border: none !important;
   border-bottom: 1px solid ${props => props.theme.colors.secondary} !important;
}

.select__control {
   
   background-color: #EAEAEA !important;
   border: none !important;
   border-bottom: 1px solid ${props => props.theme.colors.secondary} !important;
   color: ${props => props.theme.colors.secondary} !important;
   border-radius: 0rem !important;
   .css-tlfecz-indicatorContainer{
   color: ${props => props.theme.colors.text_color_primary} !important;
   }
}
.chooseFileButton{
   display: inline-block;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   border-radius: 0.25rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
}
.chooseFileButton:hover {
    background: #6c757d;
}
.filepond--credits{
   visibility: hidden;
}
.filepond--image-preview-overlay-success{
   visibility: hidden;
}

.contAreatext {
   font-size: 12px;
   color: #706F6F;
}
`;