import styled from 'styled-components';

export const Searchs = styled.div`
.rc-collapse {
   background-color: #EAEAEA !important;
}
.rc-collapse-content {
   background-color: #EAEAEA !important;
}
.div_new{
   border-bottom: 5px solid ${props => props.theme.colors.primary} !important;
   width: 100%;
}
`;