import styled from 'styled-components';

export const Divterms = styled.div`
   position: fixed;
   bottom:0px;
   left:0px;
   right:0px;
   height:50px;
   margin-bottom:0px;
   text-align: center;  
`;
export const Divreview = styled.div`

.filepond--wrapper{
   margin-top: -5% !important;
}
.contAreatext {
   font-size: 12px;
   color: #706F6F;
}
`;