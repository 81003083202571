import React, { useState } from 'react';
import { Modal_comments } from './styles';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Rating from 'react-rating';
import sanitizeHtml from 'sanitize-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons';

import comments from '../../services/comments';

export default function Modal_comment(props: any) {

   const [comment, setComment]: any = useState(props.comment);
   const [rating, setRating]: any = useState(props.rating);
   const [error, setError] = useState('');
   const [isLoading, setLoading] = useState(false);
   const regex1 = /(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)\/?/g;

   const createComment = () => {
      setLoading(true)
      props.funShow();
      let comment_sanitize = sanitizeHtml(comment,
         {
            allowedTags: [],
            allowedAttributes: {},
            allowedSchemes: [],
            nonTextTags: ['style', 'script', 'textarea', 'option', 'noscript', 'a']
         }).replace(regex1, "").trim();
      console.log(comment_sanitize);

      if (comment != comment_sanitize) {
         setError('Comentario invalido');
         console.log('Comentario invalido');

      } else {
         props.funInShow();
         setError('');
         comments().register(props.publication_id , {rating: rating, content: comment})
         .then((results: any) => {
            console.log(results);
            setLoading(false);
            props.funInShow();
         })
         .catch((error: any) => {
            setLoading(false);
            if(error.error = "user already commented"){

               setError('Usuario ja realizou um comentario');

            }
         })
      }

   }

   return (
      <Modal show={props.show} size="sm" onHide={() => props.funClose()} centered>
         <Modal_comments>
            <Modal.Header closeButton>
               <Modal.Title>Seu Comentário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form.Label className="mt-2">Avalie</Form.Label><br />
               <Rating
                  className="mb-2"
                  initialRating={rating}
                  emptySymbol={<FontAwesomeIcon icon={farFaStar} className="icon_rating m-1" />}
                  fullSymbol={<FontAwesomeIcon icon={faStar} className="icon_rating m-1" />}
                  onChange={event => setRating(event)} /><br />
               <Form.Label className="mt-2">Escreva um Comentário</Form.Label>
               <Form.Control as="textarea" aria-required="true" maxLength={500} rows={3} placeholder="Seu Comentário..."
                  value={comment}
                  onChange={event => setComment(event.target.value)} required />
               <p className="text-right contAreatext">{500 - Number(comment.length)}/500</p>

               {(error) ?
                  <div className="text-center mt-4">
                     <Alert variant="danger" dismissible>{error}</Alert>
                  </div>
                  :
                  ''
               }
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => props.funClose()}>Fechar</Button>
               <Button className="btn_buy" variant="light" onClick={!isLoading ? createComment : undefined} disabled={isLoading}>   
                  {isLoading ? 'Carregando...' : 'COMENTAR'}
               </Button>
            </Modal.Footer>

         </Modal_comments>
      </Modal>
   );

}