import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Modal_annotations = styled.div`

`;
export const Modals = styled(Modal)`
.fs-7{
   font-size: 80%; 
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.blockquote{
   border-left-style: solid;
   border-left-width: 5px;
   p{
      margin-left: 10px;
      font-style: italic;
      font-size: 0.9rem;
      text-transform: none;
   }
}
.btn-lg{
   border-radius: 50% !important;
   width: 60px !important;
   height: 60px !important;
   margin-left: 15px !important;
   border: solid #000 3px !important;
}
`;
