
import styled from 'styled-components';

export const Div_publications = styled.div`
.background {
   width: 100% !important;
   background-color: #707070 !important;
   color: ${props => props.theme.colors.text_color_secondary} !important;
}
.mt-5 {
   margin-top: 4rem !important;
}
.card-img-1 {
   margin-left: -10px;
   width: 80%;
}
.author{
   font-weight: bold; 
   font-size: 20px;
   margin-bottom: 0rem;
   a{
      color: #ffffff !important;
   }
}
.btn_buy{
   background-color: ${props => props.theme.colors.primary} !important;
   color: #ffffff !important;
   border-color: ${props => props.theme.colors.primary} !important;
}

.editora{
   font-size: 20px;
   margin-bottom: 0rem;
}
.price{
   font-size: 22px;
   margin-bottom: 0rem;
}
.discount{
   font-size: 20px;
   margin-bottom: 0rem;
}
.description{
   font-size: 14px;
   margin-bottom: 0rem;
   text-transform: initial !important;
}
.div_new{
   border-bottom: 5px solid ${props => props.theme.colors.primary} !important;
   line-height: 2.5;
}
.icon_rating{
   color: ${props => props.theme.colors.primary} !important;
}
.contAreatext {
   font-size: 12px;
   color: #706F6F;
}

`;
export const Fabs = styled.div`
position: fixed;
bottom:0px;
left:0px;
right:0px;
height:50px;
margin-bottom:35vh;
margin-right:5vw;
text-align: -webkit-right;
.icon_fab{
   color: ${props => props.theme.colors.text_color_secondary};
}
`;
export const Fabs2 = styled.div`
position: fixed;
bottom:0px;
left:0px;
right:0px;
height:50px;
margin-bottom:20vh;
margin-right:5vw;
text-align: -webkit-right;
.icon_fab{
   color: ${props => props.theme.colors.text_color_secondary};
}
`;

export const Spinners = styled.div`
.spinner-border {
   width: 2rem;
   height: 2rem;
   color: ${props => props.theme.colors.primary} !important;
}
`;