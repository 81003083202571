import styled from 'styled-components';

export const Publication_lists = styled.div`

`;

export const Spinners = styled.div`
.spinner-border {
   width: 2rem;
   height: 2rem;
   color: ${props => props.theme.colors.primary} !important;
}
`;