import styled from 'styled-components';

export const Config_epubs = styled.div`

background-color: #EAEAEA !important;
padding-bottom: 14vh;
.Roboto{
   font-family: "Roboto" !important;
}
.OpenDyslexic{
   font-family: "OpenDyslexic" !important;
}
.Georama{
   font-family: "Georama" !important;
}
.PT_Serif{
   font-family: "PT Serif" !important;
}
.btn-info{
   background-color: #eee5de !important;
}

select:focus,
.uneditable-input:focus {   
   border-color: ${props => props.theme.colors.primary};
   box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px ${props => props.theme.colors.primary};
   outline: 0 none;
}

.btn-lg{
   border-radius: 50% !important;
   width: 60px !important;
   height: 60px !important;
   margin-left: 15px !important;
   border: solid #000 3px !important;
}
`;