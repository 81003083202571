import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from '../Header';
import Collapse, { Panel } from 'rc-collapse';

import { Searchs } from './styles';
import "rc-collapse/assets/index.css";
import Loading from '../../components/loading';
import Card_list from '../../components/card_list';
import Card_public from '../../components/card_public';

import publications from '../../services/publications';

export default function Search(props: any) {

   console.log(props);
   
   let term = props.match.params.id;
   const [show, setShow] = useState(false);
   const [author, setAuthor] = useState([]);
   const [genre, setGenre] = useState([]);
   const [org, setOrg] = useState([]);
   const [title, setTitle] = useState([]);

   useEffect(() => {
      setShow(true);
      async function load() {
         let publis = [];
         if (process.env.REACT_APP_SIGLA === 'PUB ') {
            publis = await publications().searchOrg([], term);

         } else {
            publis = await publications().search([], term);

         }
         setAuthor(publis.author);
         setGenre(publis.genre);
         setOrg(publis.org);
         setTitle(publis.title);
         setShow(false);
      }
  
      load();
    }, []);   

   return (
      <Searchs>
         <Header detalhe={true} term={term}/>
            <Collapse defaultActiveKey={[1,2,3,4]} className="mt-6 pt-2 pb-5">
               <Panel header={<h3 className="div_new mt-4">Autor</h3>} key="1">
                  <Row>
                  {(author != null) ?
                     author.map((item: any, index: any) => (
                        (process.env.REACT_APP_SIGLA === 'PUB ') ? 
                           <Card_list publication={item} key={`author-${index}`}/>
                        :
                           <Col className="mt-2" xs="4" lg="4" key={`author-${index}`}> 
                              <Card_public publication={item} key={`author-${index}`} width={90} height={120}  />
                           </Col>
                     )) :
                     <div className="text-center p-auto m-auto ">
                        <p>Nenhuma publicação localizada</p>
                     </div>
                  }
                  </Row>

               </Panel>
               <Panel header={<h3 className="div_new mt-4">Gênero</h3>} key="2">
                  <Row>
                  {(genre != null) ?
                     genre.map((item: any, index: any) => (
                        (process.env.REACT_APP_SIGLA === 'PUB ') ? 
                           <Card_list publication={item} key={`genre-${index}`}/>
                        :
                           <Col className="mt-2" xs="4" lg="4" key={`genre-${index}`}> 
                              <Card_public publication={item} key={`genre-${index}`} width={90} height={120}  />
                           </Col>
                     )) :
                     <div className="text-center p-auto m-auto ">
                        <p>Nenhuma publicação localizada</p>
                     </div>
                  }
                  </Row>
               </Panel>
               <Panel header={<h3 className="div_new mt-4">Editora</h3>} key="3">
                  <Row>
                  {(org != null) ?
                     org.map((item: any, index: any) => (
                        (process.env.REACT_APP_SIGLA === 'PUB ') ? 
                           <Card_list publication={item} key={`org-${index}`}/>
                        :
                           <Col className="mt-2" xs="4" lg="4" key={`org-${index}`}> 
                              <Card_public publication={item} key={`org-${index}`} width={90} height={120}  />
                           </Col>
                     )) :
                     <div className="text-center p-auto m-auto ">
                        <p>Nenhuma publicação localizada</p>
                     </div>
                  }
                  </Row>
               </Panel>
               <Panel header={<h3 className="div_new mt-4">Título</h3>} key="4">
                  <Row>
                  {(title != null) ?
                     title.map((item: any, index: any) => (
                        (process.env.REACT_APP_SIGLA === 'PUB ') ? 
                           <Card_list publication={item} key={`title-${index}`} />
                        :
                           <Col className="mt-2" xs="4" lg="4" key={`title-${index}`}> 
                              <Card_public publication={item} key={`title-${index}`} width={90} height={120}  />
                           </Col>
                     )) :
                     <div className="text-center p-auto m-auto ">
                        <p>Nenhuma publicação localizada</p>
                     </div>
                  }
                  </Row>
               </Panel>
            </Collapse>
            <Loading show={show} />
         
      </Searchs>
   );
}