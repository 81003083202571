import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { faMicrophoneAlt, faItalic, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import annotation from '../../services/annotation';

import { Modal_annotations, Modals } from './styles';
import annotation_interface from '../../interfaces/annotation_interface';

export default function Modal_annotation(props: any) {

   const [type, setType] = useState('');
   const [annotations, setAnnotations] = useState<any>();
   const [highlightColor, setHighlightColor] = useState('#fbff00ae');
   const [reader, setReader] = useState(false);
   const [conclusion, setConclusion] = useState(false);
   
   const radios = [
      { name: 'Amarelo', value: '#fbff00ae', variant:"light" },
      { name: 'Verde', value: '#7efc00ae', variant:"info" },
      { name: 'Azul', value: '#665bffae', variant:"info" },
      { name: 'Vermelho', value: '#ff0015ae', variant:"dark" },
   ];

	useEffect(() => {
      if(props.annotation){
         setAnnotations(props.annotation);
         setHighlightColor(props.annotation.color);
         if(props.annotation.id){
            setReader(true)
            setConclusion(true)

         }
      }
	}, [props.annotation]);
   
   const createAnnotation = () => {
      let create = annotations;
      create['date'] = moment(`${annotations.date} ${annotations.hours}`, "DD/MM/YYYY hh:mm:ss").unix();
      create['color'] =`${highlightColor}`;
      annotation().CreateAnnotationByBook(create)
      .then((result: any) => {
         if(result.data.success){
            props.handleClose();
         }
      })
      .catch((error: any) => {

      });
   }

   const updateAnnotation = () => {
      if(annotations.id){
         
         let update: any = {
            color : `${highlightColor}`,
            annotation : annotations.annotation,
            public : annotations.public,
            type : annotations.type,

         };
         annotation().UpdateAnnotationByBook(annotations.id, update)
         .then((result: any) => {
            if(result.data.success){
               annotations['color'] = `${highlightColor}`;
               props.updateAnnotations(annotations)
               props.handleClose();
            }
         })
         .catch((error: any) => {
            
         });

      }
   }


   return(
      <Modal_annotations>
         <Modals show={props.show} onHide={props.handleClose} size="lg" centered>
            <Modal.Header closeButton>
            {(reader) ? 
               <Button variant="" className="button_goback" onClick={() => {setReader(false); setConclusion(false)}}>
                  <FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" />
               </Button>
            : ''}
               Pagina {((annotations) ?  annotations.page : '')}
            </Modal.Header>
            <Modal.Body>
               <div >
                  <blockquote className="blockquote mb-0" style={{...props.style, borderLeftColor: highlightColor, fontFamily: props.font_family}}>
                     <p >{`"${((annotations) ? annotations.range : '')}"`}</p>
                  </blockquote>
               </div>
               {(reader) ? 
                  <div>
                     <div className="text-center mt-3">
                        <ButtonGroup className="text-center">
                        {radios.map((radio, idx) => (
                           <ToggleButton
                              style={{...props.style, backgroundColor: `${radio.value}` }}
                              size="lg"
                              key={idx}
                              id={`radio-${idx}`}
                              type="radio"
                              variant={radio.variant}
                              name="radio"
                              value={radio.value}
                              checked={ highlightColor === radio.value}
                              onChange={(e: any) => {                                 
                                 setHighlightColor(`${e.currentTarget.value}`);
                              }}
                           >
                           </ToggleButton>
                        ))}
                        </ButtonGroup>
                     </div>
                     <Form.Group controlId="formBasicDescription">
                        <Form.Label className="mt-2">Anotação</Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder="sua anotação..."
                           value={annotations.annotation}
                           onChange={event => setAnnotations((prev: any) => ({ ...prev, annotation: event.target.value }))} required />
                     </Form.Group> 
                  </div>
               : 
               <div className="mt-4 text-center">
                  <Button className="m-2">
                     <FontAwesomeIcon icon={faMicrophoneAlt} className="icon-navbar fa-lg" />
                     <p className="mb-0">Audio</p>
                     
                  </Button>
                  <Button className="m-2" onClick={() => {setReader(true); setConclusion(true)}}>
                     <FontAwesomeIcon icon={faItalic} className="icon-navbar fa-lg" />
                     <p className="mb-0">Escrever</p>
                     
                  </Button>
               </div>}
            </Modal.Body>
            {(conclusion) ? 
               <Modal.Footer>
                  <Button variant="secondary" onClick={props.handleClose}>
                     Cancelar
                  </Button>
                  <Button variant="primary" onClick={((annotations.id) ? updateAnnotation : createAnnotation)}>
                      {((annotations.id) ? "Alterar" : "Salvar")}
                  </Button>
               </Modal.Footer>
            : '' }

            
         </Modals>
         
      </Modal_annotations>
   )
}