import React, { useState, useEffect } from 'react';
import {  Modal, Button } from 'react-bootstrap';

import Loading from '../../components/loading';
import Reader_epub from '../../components/reader_epub';

import auth from '../../services/auth';
import publications from '../../services/publications';
import { useHistory } from 'react-router-dom';

import { Readers } from './styles';

export default function Reader(props: any) {

   
   let publication = props.history.location.state;
   const [link, setLink] = useState("");
   const [start, setStart] = useState(true);
   const [showError, setShowError] = useState(false);
   const [show, setShow] = useState(false);

   let history = useHistory();
   let token = auth().isAuthenticated();

   
   useEffect(() => {

      async function loadPublication() {

         setShow(true);
         publications().urlSignedBook({uuid: publication.uuid})
         .then((results: any) => {
            setLink(results.data.url);
            setStart(false)
         })
         .catch(function (error: any) {
            setShow(false);
            history.goBack();
         });
      }

      if(window.screen.width < 1024 || window.screen.height < 768){
         loadPublication();

      }else {
         setShow(false);
         setShowError(true);
      }

   }, []);
   
   const handleClose2 = () => {
      setShowError(false);
   };
   const handleClose = () => {
      setShow(false);
   };
   const handleShow = () => {
      setShow(true);
   };
   const modalMobile = () => {
      setShowError(false);
      history.goBack();
   };

   return (
      <Readers>
         <div>
            <Reader_epub epub={{link: link, uuid: publication.uuid, publication_id: publication.publication.pub_id}} start={start} history={history} handleClose={handleClose} handleShow={handleShow}  />
         </div>
         <Modal show={showError} onHide={handleClose2} centered>
            <Modal.Body>
               <p>O modo leitura é focado para mobile por favor acesse por um dispositivo móvel</p>
               <div className="d-grid gap-2">
                  <Button variant="primary" size="lg" type="button" onClick={modalMobile}>SIM</Button>
               </div>
            </Modal.Body>
         </Modal>
         <Loading show={show} />
      </Readers>
   );

}