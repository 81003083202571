import React from 'react';
import { Buttons_summarys } from './styles';


export default function Buttons_summary(props: any) {

   let chapter = props.chapter;
   
   return (
      <Buttons_summarys>

         <p key={props.id} className="btn_summary" >
            <a onClick={() => props.selectPage(chapter.ref)}>{chapter.label}</a>
            <br />
            {(chapter.subchapter.length > 0) ? 
            <>
               <br />
               <ul>
                  {chapter.subchapter.map((subchapter: any, ids: any) => {
                     return <li><p key={ids} className="btn_summary" >
                     <a onClick={() => props.selectPage(subchapter.href)}>{subchapter.label}</a>
                     </p>
                     {(subchapter.subitems.length > 0) ? 
                     <>
                        <br />
                        <ul>
                           {subchapter.subitems.map((subchapters: any, ids1: any) => {
                              return <li><p key={ids1} className="btn_summary" >
                              <a onClick={() => props.selectPage(subchapters.href)}>{subchapters.label}</a>
                              </p></li>
                           })}
                        </ul>
                     </>
                     : ''}</li>
                  })}
               </ul>
            </>
             : ''}
         </p>
      </Buttons_summarys>
   )
}