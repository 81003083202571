import React from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { faEdit, faTrashAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card_annotations } from './styles';


export default function Card_annotation(props: any) {

   let annotation = props.annotations;

   const visibleAnnotation = (cfiRange: any) => {
      props.selectPage(cfiRange);
   }

   const deleteAnnotation = (annotation: any) => {
      props.removeAnnotations(annotation);

   }
   
   return (
      <Card_annotations>
         <Card className="m-3" style={{...props.style, fontFamily: props.font_family}} >
            <Card.Header>
               <Row>
                  <Col xs="8" sm="8">Pagina {annotation.page}</Col>
                  <Col xs="4" sm="4">
                     {(annotation.public) ? <FontAwesomeIcon icon={faUsers} className=" icon-navbar fa-lg" /> : "" }
                  </Col>
               </Row>
            </Card.Header>
            <Card.Body>
                  <a  onClick={() =>{ visibleAnnotation(annotation.cfiRange);}}>
                     <blockquote className="blockquote mb-0" style={{...props.style, borderLeftColor: annotation.color, fontFamily: props.font_family}}>
                        <p>"{annotation.range}"</p>
                     </blockquote>
                  </a>
               <p className="mt-3 mb-0">{annotation.annotation}</p>
            </Card.Body>
            <Card.Footer>
               <Row>
                  <Col xs="4" sm="4">
                     <small className="text-muted">{annotation.date}</small>
                     <br />
                     <small className="text-muted">{annotation.hours}</small>
                  </Col>
                  {(!annotation.public) ?
                  <Col xs="8" sm="8" className="text-center">
                     <Button className="mr-4" onClick={() =>{  props.handleShowModal(annotation);}} >
                        <FontAwesomeIcon icon={faEdit} className="icon-navbar" />
                     </Button>
                     <Button  onClick={() =>{  deleteAnnotation(annotation);}} >
                        <FontAwesomeIcon icon={faTrashAlt} className="icon-navbar" />
                     </Button>
                  </Col>
                   : "" }
               </Row>
            </Card.Footer>
         </Card>
      </Card_annotations>
   );
}