const publisher = {
   title: 'publisher',

   favicon: '/img/icons/publisher-favicon.ico',
   icon: '/img/icons/publisher.svg',
   icon_dark: '/img/icons/publisher-dark.svg',
   colors: {
      primary: '#A3CE37',
      secondary: '#706F6F',
      text_color_primary: '#333333',
      text_color_secondary: '#ffffff',
   }
}
export default publisher;