import axios from 'axios';
import auth from './auth';

export default function comments() {
   let base_url = process.env.REACT_APP_BASE_URL;

   let comments = {
      all: function all(id: number, header: any): any {
         
         return axios.get(`${base_url}publications/${id}/comment`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               },
               params: {
                  page: (header.page) ? header.page : null,
                  pageSize: (header.pageSize) ? header.pageSize : null,
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },
      register: function register(id: number, data: any): any {
         
         return axios.post(`${base_url}v1/publications/${id}/comment`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
      delete: function destroy(uuid: any): any {
         
         return axios.delete(`${base_url}v1/comment/${uuid}`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      }

   }
   
   return comments;
}