import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Alert, Form, Button, Modal, InputGroup, Image } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { Publication_creates } from './styles';
import Header from '../Header';
import Loading from '../../components/loading';

import auth from '../../services/auth';
import author from '../../services/author';
import publications from '../../services/publications';

import publication_interface from '../../interfaces/publication_interface';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginImageEdit, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize)

export default function Publication_create(props: any) {

   const [show, setShow] = useState(false);
   const [error, setError] = useState('');
   const [step, setStep] = useState(1);
   const [isLoading, setLoading] = useState(false);
   const [showmodalAuthor, setShowmodalAuthor] = useState(false);
   const [title, setTitle] = useState("");
   const [author_pub, setAuthor_pub] = useState(0);
   const [authors, setAuthors] = useState([]);
   const [authorsSelect, setAuthorsSelect] = useState([{ value: '', label: '' }]);
   const [authorsUpdate, setAuthorsUpdate] = useState({ value: '', label: '' });
   const [authors_name, setAuthors_name] = useState("");
   const [authors_description, setAuthors_description] = useState("");
   const [authors_error, setAuthors_Error] = useState('');
   const [description, setDescription] = useState("");
   const [type, setType] = useState("");
   const [epub, setEpub] = useState("");
   const [files, setFiles] = useState<any>([]);
   const [price, setPrice] = useState("0,00");
   const [discount, setDiscount] = useState("0,00");
   const [cover, setCover] = useState("");
   const [currency, setCurrency] = useState("R$");
   const [genre, setGenre] = useState([]);
   const [idioma, setIdioma] = useState("");
   const [isbn, setIsbn] = useState("");
   const [data_producao, setData_producao] = useState("");
   const [data_publicacao, setData_publicacao] = useState("");
   const [pais, setPais] = useState("");
   const [entidade, setEntidade] = useState("");
   const [publi, setPubli] = useState<any>([]);
   const [id, setId] = useState((props.match) ? props.match.params.id : null);
   const genreOptions = [
      { value: 'terror', label: 'terror' },
      { value: 'romance', label: 'romance' },
      { value: 'contos', label: 'contos' }
   ]

   let history = useHistory();
   let token = auth().isAuthenticated();

   if (!token) {
      history.push("/");
   }
   if (process.env.REACT_APP_SIGLA != 'PUB ') {
      setId(null)
   }

   const updatePublication = () => {
      setLoading(true);
      setShow(true);

      if (error == '') {
         if (publi.type == 'loan') {
            publi.price = 0;
            publi.discount = 0;

         } else {
            publi.price = parseFloat(publi.price.replace(',', '.')) * 100;
            publi.discount = parseFloat(publi.discount.replace(',', '.')) * 100;

         }

         publications().update(publi, id)
            .then((results2: any) => {
               setLoading(false);
               setShow(false);
               if (results2.status == 204) {
                  history.push(`/publication/${id}`);
               }
            })
            .catch(function (error: any) {
               setLoading(false);
               setShow(false);
               setError(error.message);
            });

      }
   }

   const ImagetoDataURL = (url: string) => fetch(url, {
      headers: {
        'Access-Control-Allow-Origin':'*',
        'access-control-expose-headers':'*',
      }
    })
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.onloadend = () => resolve(reader.result)
         reader.onerror = reject
         reader.readAsDataURL(blob)
      }))

   const createPublication = () => {
      setLoading(true);
      setShow(true);
      setError("");

      let publi: publication_interface = {
         author_id: author_pub,
         genre: genre,
         title: title.trim(),
         description: description.trim(),
         type: type,
         epub: epub,
         price: parseFloat(price.replace(',', '.')) * 100,
         discount: parseFloat(discount.replace(',', '.')) * 100,
         currency: currency,
         cover: cover,
         idioma: idioma,
         isbn: isbn,
         data_producao: data_producao,
         data_publicacao: data_publicacao,
         pais: pais,
         entidade: entidade,

      }
      
      publications().upload({ format: publi.epub })
         .then((results: any) => {
            let url = results.data.url;

            publications().storage(url, files)
               .then((results1: any) => {
                  if (results1.status == 200) {
                     publi.epub = results.data.filename;
                     
                     publications().register(publi)
                        .then((results2: any) => {
                           setLoading(false);
                           setShow(false);
                           if (results2.data.id) {
                              history.push(`/publication/${results2.data.id}`);
                           }
                        })
                        .catch(function (error: any) {
                           setLoading(false);
                           setShow(false);
                           setError(error.message);
                        });
                  }

               })
               .catch(function (error: any) {
                  setLoading(false);
                  setShow(false);
                  console.log(error);
                  
                  setError(error.message);
               });
         })
         .catch(function (error: any) {
            setLoading(false);
            setShow(false);
            setError(error.message);
         });
   }

   useEffect(() => {
      setShow(true);
      setLoading(false);
      async function loadAuthor() {

         author().get().then((results: any) => {
            if (results) {
               setAuthors(results.response);
               let author = [{ value: 'add-author', label: 'Criar Autor' }];

               let array_authors = results.response.map((item: any, key: any) => (
                  { value: item.id, label: item.name }
               ));
               let array_authors1 = array_authors.sort(function (a: any, b: any) {
                  return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
               });
               setAuthorsSelect([...author, ...array_authors1]);
               setShow(false);

            } else {
               setShow(false);

            }

         });
      }
      
      async function loadPublication(id: number) {
         publications().getOrg({}, id)
            .then(async (results2: any) => {
               results2.discount = Number(parseFloat(results2.discount) / 100).toFixed(2);
               results2.price = Number(parseFloat(results2.price) / 100).toFixed(2);
               let cover_base64 = await ImagetoDataURL(results2.cover)
               .then(dataUrl => {
                  if(dataUrl != ''){
                     return dataUrl;

                  }else {
                     return null;
                  }
               }).catch((error => {
                  return null;
               }))
               results2.cover = cover_base64;
               setPubli(results2)
               setAuthorsUpdate((eval(results2.author)) ? { value: results2.author_id, label: results2.author.name } : { value: '', label: '' });
            })
            .catch(function (error: any) {
               console.log(error);
            });
      }
      if (props.match.params.id) {
         loadPublication(props.match.params.id);
      }

      loadAuthor();
   }, []);

   const handleCloseAutor = () => {

      let author = [{ value: 'add-author', label: 'Criar Autor' }];

      let array_authors = authors.map((item: any, key: any) => (
         { value: item.id, label: item.name }
      ));
      setAuthorsSelect([...author, ...array_authors]);
      setShowmodalAuthor(false)
   };

   function onChangeAutor(value: any, { action }: any) {
      if (value.value) {
         if(value.value == 'add-author'){
            setShowmodalAuthor(true);
         }else{
            setAuthor_pub(value.value)
         }
      }
   }

   function UpdateonChangeAutor(value: any, { action }: any) {
      if (eval(value.value)) {
         setPubli((prev: any) => ({ ...prev, author_id: value.value }));
         setAuthorsUpdate({ value: value.value, label: value.label });
      }
   }

   const createAuthor = () => {
      setLoading(true);
      setShow(true);
      setAuthors_Error("");
      if (!authors_name || !authors_description) {
         setAuthors_Error("Preencha Nome e a Descrição para continuar!");
         setLoading(false);
         setShow(false);
      } else {
         if (authors_name.length >= 3 || authors_description.length >= 3) {
            author().register({ name: authors_name, description: authors_description })
               .then(function (response: any) {
                  setLoading(false);
                  setShow(false);
                  setShowmodalAuthor(false);
                  setAuthors_name('');
                  setAuthors_description('');
                  authorsSelect.splice( 0, 0, { value: response.data.id, label: authors_name })
               })
               .catch(function (error: any) {

                  setAuthors_Error(error.response.data);
                  setLoading(false);
                  setShow(false);
               })
         } else {
            setAuthors_Error("Nome e/ou Descrição precisam ter mais de 2 letras");
            setLoading(false);
            setShow(false);
            setAuthors_name('');
            setAuthors_description('');
         }
      }
   }

   function handleCreate(inputValue: any) {
      setShowmodalAuthor(true);
      setAuthors_name(inputValue)

   }

   function onChange(value: any, { action }: any) {
      let t: any = value.map((item: any) => {
         return item.value
      })
      if (t) {
         if (id) {
            setPubli((prev: any) => ({ ...prev, genre: t }))

         } else {
            setGenre(t);

         }
      }

   }

   const skipStep1 = () => setStep(1);
   const skipStep2 = () => setStep(2);
   const skipStep3 = () => setStep(3);
   const skipStep = () => history.push(`/publication/${props.match.params.id}`);

   const skipStep2Validate = () => {
      if (!title || !author_pub || !description) {
         setError('Preencha todos os campos')

      } else {
         setStep(2)
         setError('')
      }
   };

   const skipStep3Validate = () => {

      if (!epub) {
         setError('Faça o upload do arquivo')
      } else {
         setStep(3)
         setError('')
      }
   };

   const skipStep4Validate = () => {
      if (!type || !currency || !price || !discount || !genre || !idioma || !isbn || !data_producao || !data_publicacao || !pais || !entidade || !cover) {
         setError('Preencha todos os campos')

      } else {
         setStep(4)
         setError('')
      }
   };

   if (id) {

      if (step == 1) {
         return (
            <Publication_creates>
               <Header title="EDIÇÃO PUBLICAÇÃO - 1" go_back={skipStep} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasictitle">
                           <Form.Label className="mt-2">Título</Form.Label>
                           <Form.Control type="text" placeholder="Teste"
                              value={publi.title}
                              onChange={event => setPubli((prev: any) => ({ ...prev, title: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Título.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicAuthor">
                           <Form.Label className="mt-2">Autor</Form.Label>

                           <CreatableSelect
                              value={authorsUpdate}
                              isSearchable={true}
                              classNamePrefix="select"
                              placeholder="Autor..."
                              onChange={UpdateonChangeAutor}
                              onCreateOption={handleCreate}
                              options={authorsSelect}
                           />
                           <Form.Control.Feedback type="invalid">
                              Please choose a author.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                           <Form.Label className="mt-2">Descrição</Form.Label>
                           <Form.Control as="textarea"  maxLength={5000} isInvalid={(Number(publi.description.length) < 5000) ? false : true} rows={5} placeholder="este livro..."
                              value={publi.description}
                              onChange={event => {
                                 
                                 if(event.target.value.length <= 5000) { 
                                    setPubli((prev: any) => ({ ...prev, description: event.target.value }))
                                 }else{
                                    setError('Você preencheu todos os caracteres permitidos da Descrição')

                                 }
                              }} required />
                           <p className="text-right contAreatext">{5000-Number(publi.description.length)}/5000</p>
                           <Form.Control.Feedback type="invalid">
                              Please choose a description.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? skipStep2 : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'PROXIMA ETAPA'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
               <Modal show={showmodalAuthor} onHide={handleCloseAutor} centered>
                  <Modal.Header closeButton>
                  </Modal.Header>

                  <Card className="card-body">
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Nome do Autor</Form.Label>
                        <Form.Control type="text" placeholder="João Silva"
                           value={authors_name}
                           onChange={event => setAuthors_name(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Descrição do Autor</Form.Label>
                        <Form.Control placeholder="Descrição do Autor" as="textarea" maxLength={2000} rows={5} 
                           value={authors_description}
                           onChange={event => setAuthors_description(event.target.value)} required />
                        <p className="text-right contAreatext">{2000-Number(authors_description.length)}/2000</p>
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                        </Form.Control.Feedback>
                     </Form.Group>
                     {(authors_error) ?
                        <div className="text-center mt-4">
                           <Alert variant="danger" dismissible>{authors_error}</Alert>
                        </div>
                        : ''
                     }
                     <div className="text-center mt-4">
                        <Button variant="secondary" size="lg" type="button"
                           onClick={!isLoading ? createAuthor : undefined}
                           disabled={isLoading}>
                           {isLoading ? 'Carregando...' : 'ALTERAR'}</Button>
                     </div>

                  </Card>
               </Modal>
            </Publication_creates>
         );

      } else if (step == 2) {
         return (
            <Publication_creates>
               <Header title="Nova Publicação - 2" go_back={skipStep1} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasicType">
                           <Form.Label className="mt-2">Tipo</Form.Label>
                           <Form.Control as="select" className="form-select"
                              onChange={event => setPubli((prev: any) => ({ ...prev, type: event.target.value }))} required>
                              <option value="null" disabled >Selecione</option>
                              <option value="loan" selected={(publi.type == 'loan') ? true : false} >Emprestimo</option>
                              <option value="purchase" selected={(publi.type == 'purchase') ? true : false} >Venda</option>
                           </Form.Control>
                           <Form.Control.Feedback type="invalid">
                              Please choose a type.
                           </Form.Control.Feedback>
                        </Form.Group>
                        {(publi.type == 'purchase') ?
                           <>
                              <Form.Group controlId="formBasicCurrency">
                                 <Form.Label className="mt-2">Preço</Form.Label>
                                 <Row>
                                    <Col sm={4} xs={4} md={4}>

                                       <Form.Control as="select" className="form-select"
                                          onChange={event => setPubli((prev: any) => ({ ...prev, currency: event.target.value }))} required>
                                          <option value="null" disabled >Selecione</option>
                                          <option value="R$" selected={(publi.type == 'R$') ? true : false} >R$</option>
                                          <option value="$" selected={(publi.type == '$') ? true : false} >$</option>
                                       </Form.Control>
                                    </Col>
                                    <Col sm={8} xs={8} md={8}>
                                       <CurrencyInput
                                          className="form-control"
                                          step={1}
                                          decimalScale={2}
                                          id="validation-example-3-field1"
                                          placeholder="0,00"
                                          value={publi.price}
                                          onChange={event => setPubli((prev: any) => ({ ...prev, price: (event.target.value != '') ? Number(parseFloat(event.target.value.replace(',', '.'))).toFixed(2) : 0 }))}
                                          required />
                                    </Col>
                                 </Row>
                              </Form.Group>
                              <Form.Group controlId="formBasicDiscount">
                                 <Form.Label className="mt-2">Valor com Desconto</Form.Label>
                                 <CurrencyInput
                                    className="form-control"
                                    step={1}
                                    decimalScale={2}
                                    id="validation-example-3-field1"
                                    placeholder="0,00"
                                    value={publi.discount}
                                    onChange={event => setPubli((prev: any) => ({
                                       ...prev, discount: (event.target.value != '') ? Number(parseFloat(event.target.value.replace(',', '.'))).toFixed(2) : 0
                                    }))}
                                    required />
                                 <Form.Control.Feedback type="invalid">
                                    Please choose a Razão Social.
                              </Form.Control.Feedback>
                              </Form.Group>
                           </>
                           : ''

                        }
                        <Form.Group controlId="formBasicGenre">
                           <Form.Label className="mt-2">Gênero</Form.Label>
                           <Select
                              isMulti
                              name="genre"
                              value={
                                 publi.genre.map((item: any) => (
                                    { value: item, label: item }
                                 ))
                              }
                              isSearchable={true}
                              options={genreOptions}
                              onChange={onChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="terror..."
                           />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Razão Social.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicIdioma">
                           <Form.Label className="mt-2">Idioma</Form.Label>
                           <Form.Control type="text" placeholder="pt-br"
                              value={publi.idioma}
                              onChange={event => setPubli((prev: any) => ({ ...prev, idioma: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a idioma.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicIsbn">
                           <Form.Label className="mt-2">ISBN</Form.Label>
                           <InputMask className="form-control" placeholder="999-99-999-9999-9"
                              mask="999-99-999-9999-9"
                              value={publi.isbn}
                              onChange={event => setPubli((prev: any) => ({ ...prev, isbn: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a ISBN.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_producao">
                           <Form.Label className="mt-2">data de Produção</Form.Label>
                           <Form.Control type="date"
                              value={publi.data_producao}
                              onChange={event => setPubli((prev: any) => ({ ...prev, data_producao: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a data de Produção.
                                 </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_publicacao">
                           <Form.Label className="mt-2">Data de Publicação</Form.Label>
                           <Form.Control type="date"
                              value={publi.data_publicacao}
                              onChange={event => setPubli((prev: any) => ({ ...prev, data_publicacao: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Data de Publicação.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicPais">
                           <Form.Label className="mt-2">Pais</Form.Label>
                           <Form.Control type="text" placeholder="Brasil"
                              value={publi.pais}
                              onChange={event => setPubli((prev: any) => ({ ...prev, pais: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Pais.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicEntidade">
                           <Form.Label className="mt-2">Entidade</Form.Label>
                           <Form.Control type="text" placeholder="João Silva"
                              value={publi.entidade}
                              onChange={event => setPubli((prev: any) => ({ ...prev, entidade: event.target.value }))} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Entidade.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicCover">
                           <Form.Label className="mt-3">sua capa</Form.Label>
                           <FilePond
                              onaddfile={(error: any, file: any) => {
                                 // Set currently active file objects to this.state
                                 if (error) {
                                    setError(error.main)
                                 } else {
                                    setPubli((prev: any) => ({ ...prev, cover: `data:${file.fileType};base64,${file.getFileEncodeBase64String()}` }))
                                    setError('')
                                 }
                              }}
                              onremovefile={(error: any, file: any) => {
                                 if(typeof(file.source) == 'string'){
                                    setError('FAÇA O ULPOAD DA CAPA')
                                    setPubli((prev: any) => ({ ...prev, cover: null}))
                                 }
                              }}
                              files={(publi.cover != null) ? [publi.cover] : []}
                              allowReorder={true}
                              allowImagePreview={true}
                              allowImageExifOrientation={true}
                              allowFileEncode={true}
                              allowMultiple={false}
                              maxFiles={1}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                              fileValidateTypeLabelExpectedTypesMap={[
                                 { 'image/png': '.png' },
                                 { 'image/jpeg': '.png' },
                                 { 'image/jpg': '.png' }
                              ]}
                              className="mt-3"
                              fileValidateTypeLabelExpectedTypes={''}
                              allowFileSizeValidation={true}
                              maxFileSize='500000'
                              required
                              labelMaxFileSizeExceeded="capa muito grande"
                              labelMaxFileSize="maximo aceitavel {filesize}"
                              labelFileTypeNotAllowed='FORMATO NÃO SUPORTADO'
                              labelFileProcessing='CARREGANDO...'
                              labelFileProcessingComplete='COMPLETADO'
                              labelFileProcessingAborted='ERRO NO UPLOAD'
                              labelTapToUndo='TOQUE PARA DESFAZER'
                              labelTapToCancel='TOQUE PARA CANCELAR'
                              labelButtonRemoveItem='REMOVER'
                              labelButtonAbortItemProcessing='CANCELAR'
                              labelIdle='TAMANHO MAXIMO: 500KB;<br> <span class="filepond--label-action">SELECIONAR</span>'
                           />
                        </Form.Group>

                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? skipStep3 : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'PROXIMA ETAPA'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
            </Publication_creates>
         );
      } else {
         return (
            <Publication_creates>
               <Header title="Revisão" go_back={skipStep2} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasictitle">
                           <Form.Label className="mt-2">Título</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Teste"
                                 value={publi.title} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep1}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicAuthor">
                           <Form.Label className="mt-2">Autor</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Teste"
                                 value={authors.filter(
                                    function (i: any) {
                                       return i.id == publi.author_id;
                                    }
                                 ).map((i: any) => (i.name))} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep1}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>

                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                           <Form.Label className="mt-2">Descrição</Form.Label>
                           <InputGroup>
                              <Form.Control as="textarea" maxLength={5000} rows={5} placeholder="este livro..."
                                 value={publi.description}
                                 disabled={true} />
                              <p className="text-right contAreatext">{5000-Number(publi.description.length)}/5000</p>
                              <Button type="button" variant="light"
                                 onClick={skipStep1}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicType">
                           <Form.Label className="mt-2">Tipo</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Teste"
                                 value={(publi.type == 'purchase') ? 'Venda' : 'Emprestimo'} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        {
                           (publi.type == 'purchase') ?
                              <>
                                 <Form.Group controlId="formBasicCurrency">
                                    <Form.Label className="mt-2">Preço</Form.Label>
                                    <InputGroup>
                                       <Form.Control type="text" placeholder="0,00"
                                          value={`${publi.currency} ${publi.price}`} disabled={true} />
                                       <Button type="button" variant="light"
                                          onClick={skipStep2}>
                                          <FontAwesomeIcon icon={faPen} className="" />
                                       </Button>
                                    </InputGroup>
                                 </Form.Group>
                                 <Form.Group controlId="formBasicDiscount">
                                    <Form.Label className="mt-2">Valor com Desconto</Form.Label>
                                    <InputGroup>
                                       <Form.Control type="text" placeholder="0,00"
                                          value={`${publi.currency} ${publi.discount}`} disabled={true} />
                                       <Button type="button" variant="light"
                                          onClick={skipStep2}>
                                          <FontAwesomeIcon icon={faPen} className="" />
                                       </Button>
                                    </InputGroup>
                                 </Form.Group>
                              </>
                              : ''
                        }
                        <Form.Group controlId="formBasicGenre">
                           <Form.Label className="mt-2">Gênero</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="teste"
                                 value={publi.genre.toString()} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicIdioma">
                           <Form.Label className="mt-2">Idioma</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="pt-br"
                                 value={publi.idioma} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicIsbn">
                           <Form.Label className="mt-2">ISBN</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="999-99-999-9999-9"
                                 value={publi.isbn} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_producao">
                           <Form.Label className="mt-2">data de Produção</Form.Label>
                           <InputGroup>
                              <Form.Control type="text"
                                 value={moment(publi.data_producao).format('DD/MM/YYYY')} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_publicacao">
                           <Form.Label className="mt-2">Data de Publicação</Form.Label>
                           <InputGroup>
                              <Form.Control type="text"
                                 value={moment(publi.data_publicacao).format('DD/MM/YYYY')} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicPais">
                           <Form.Label className="mt-2">Pais</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Brasil"
                                 value={publi.pais} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicEntidade">
                           <Form.Label className="mt-2">Entidade</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Brasil"
                                 value={publi.entidade} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicCover">
                           <InputGroup>
                              <Form.Label className="mt-3">sua capa</Form.Label>
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                           <Image src={publi.cover} fluid width="100%" />
                        </Form.Group>
                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? updatePublication : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'ALTERAR'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
            </Publication_creates>
         );
      }

   } else {
      if (step == 1) {
         return (
            <Publication_creates>
               <Header title="Nova Publicação - 1" />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasictitle">
                           <Form.Label className="mt-2">Título</Form.Label>
                           <Form.Control type="text" placeholder="Teste"
                              value={title}
                              pattern="^[a-zA-Z0-9]+$"
                              onChange={event => setTitle(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Título.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicAuthor">
                           <Form.Label className="mt-2">Autor</Form.Label>

                           <CreatableSelect
                              isSearchable={true}
                              classNamePrefix="select"
                              placeholder="Autor..."
                              onChange={onChangeAutor}
                              onCreateOption={handleCreate}
                              options={authorsSelect}
                           />
                           <Form.Control.Feedback type="invalid">
                              Please choose a author.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                           <Form.Label className="mt-2">Descrição</Form.Label>
                           <Form.Control as="textarea" maxLength={5000} rows={5} placeholder="este livro..."
                              value={description}
                              onChange={event => {
                                 if(event.target.value.length <= 5000) { 
                                    setDescription(event.target.value)
                                 }
                              }} required />
                           <p className="text-right contAreatext">{5000-Number(description.length)}/5000</p>
                           <Form.Control.Feedback type="invalid">
                              Please choose a description.
                                 </Form.Control.Feedback>
                        </Form.Group>
                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? skipStep2Validate : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'PROXIMA ETAPA'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
               <Modal show={showmodalAuthor} onHide={handleCloseAutor} centered>
                  <Modal.Header closeButton>
                  </Modal.Header>

                  <Card className="card-body">
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Nome do Autor</Form.Label>
                        <Form.Control type="text" placeholder="João Silva"
                           value={authors_name}
                           onChange={event => setAuthors_name(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="formBasicAutor">
                        <Form.Label className="mt-2">Descrição do Autor</Form.Label>
                        <Form.Control placeholder="Descrição do Autor" as="textarea" maxLength={2000} rows={5} 
                           value={authors_description}
                           onChange={event => setAuthors_description(event.target.value)} required />
                        <p className="text-right contAreatext">{2000-Number(authors_description.length)}/2000</p>
                        <Form.Control.Feedback type="invalid">
                           Please choose a Autor.
                                 </Form.Control.Feedback>
                     </Form.Group>
                     {(authors_error) ?
                        <div className="text-center mt-4">
                           <Alert variant="danger" dismissible>{authors_error}</Alert>
                        </div>
                        : ''
                     }
                     <div className="text-center mt-4">
                        <Button variant="secondary" size="lg" type="button"
                           onClick={!isLoading ? createAuthor : undefined}
                           disabled={isLoading}>
                           {isLoading ? 'Carregando...' : 'REGISTRAR'}</Button>
                     </div>

                  </Card>
               </Modal>
            </Publication_creates>
         );

      } else if (step == 2) {
         return (
            <Publication_creates>
               <Header title="Nova Publicação - 2" go_back={skipStep1} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasicArquivo">
                           <Form.Label className="mt-2">Arquivo</Form.Label>
                           <FilePond
                              allowReorder={true}
                              allowMultiple={false}
                              onaddfile={(error: any, file: any) => {
                                 // Set currently active file objects to this.state
                                 if (error) {
                                    setError(error.main);
                                 } else {
                                    setEpub(file.fileExtension);
                                    setFiles(file.file)
                                 }
                              }}
                              onremovefile={(error: any, file: any) => {
                                 setError('')
                                 setEpub('')
                                 setFiles([])
                              }}
                              allowImagePreview={true}
                              allowImageExifOrientation={true}
                              allowFileEncode={true}
                              maxFiles={1}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={["application/pdf", "application/epub+zip", "application/lpf+zip"]}
                              fileValidateTypeLabelExpectedTypesMap={[
                                 { "application/pdf": ".png" },
                                 { "application/epub+zip": ".epub" },
                                 { "application/lpf+zip": ".lpf" }
                              ]}
                              required
                              fileValidateTypeLabelExpectedTypes={''}
                              allowFileSizeValidation={true}
                              maxFileSize='200000000'
                              labelMaxFileSizeExceeded="Arquivo muito grande"
                              labelMaxFileSize="maximo aceitavel {filesize}"
                              labelFileTypeNotAllowed="FORMATO NÃO SUPORTADO"
                              labelFileProcessing="CARREGANDO..."
                              labelFileProcessingComplete="COMPLETADO"
                              labelFileProcessingAborted="ERRO NO UPLOAD"
                              labelTapToUndo="TOQUE PARA DESFAZER"
                              labelTapToCancel="TOQUE PARA CANCELAR"
                              labelButtonRemoveItem="REMOVER"
                              labelButtonAbortItemProcessing="CANCELAR"
                              labelIdle='<br> <span class="filepond--label-action">SELECIONAR EBOOK</span>'
                           />
                        </Form.Group>
                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? skipStep3Validate : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'PROXIMA ETAPA'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
            </Publication_creates>
         );
      } else if (step == 3) {
         return (
            <Publication_creates>
               <Header title="Nova Publicação - 3" go_back={skipStep2} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasicType">
                           <Form.Label className="mt-2">Tipo</Form.Label>
                           <Form.Control as="select" className="form-select"
                              onChange={event => setType(event.target.value)} required>
                              <option value="null" disabled selected>Selecione</option>
                              <option value="loan" >Emprestimo</option>
                              <option value="purchase">Venda</option>
                           </Form.Control>
                           <Form.Control.Feedback type="invalid">
                              Please choose a type.
                           </Form.Control.Feedback>
                        </Form.Group>
                        {(type == 'purchase') ?
                           <>
                              <Form.Group controlId="formBasicCurrency">
                                 <Form.Label className="mt-2">Preço</Form.Label>
                                 <Row>
                                    <Col sm={4} xs={4} md={4}>

                                       <Form.Control as="select" className="form-select"
                                          onChange={event => setCurrency(event.target.value)} required>
                                          <option value="R$" selected>R$</option>
                                          <option value="$">$</option>
                                       </Form.Control>
                                    </Col>
                                    <Col sm={8} xs={8} md={8}>
                                       <CurrencyInput
                                          className="form-control"
                                          step={1}
                                          decimalScale={2}
                                          id="validation-example-3-field1"
                                          placeholder="0,00"
                                          value={price}
                                          onValueChange={(value) => setPrice((value) ? value : '0')} required />
                                    </Col>
                                 </Row>
                              </Form.Group>
                              <Form.Group controlId="formBasicDiscount">
                                 <Form.Label className="mt-2">Valor com Desconto</Form.Label>
                                 <CurrencyInput
                                    className="form-control"
                                    step={1}
                                    decimalScale={2}
                                    id="validation-example-3-field1"
                                    placeholder="0,00"
                                    value={discount}
                                    onValueChange={(value) => setDiscount((value) ? value : '0')} required />
                                 <Form.Control.Feedback type="invalid">
                                    Please choose a Razão Social.
                                    </Form.Control.Feedback>
                              </Form.Group>
                           </>
                           : ''

                        }
                        <Form.Group controlId="formBasicGenre">
                           <Form.Label className="mt-2">Gênero</Form.Label>
                           <Select
                              isMulti
                              name="genre"
                              isSearchable={true}
                              options={genreOptions}
                              onChange={onChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="terror..."
                           />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Razão Social.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicIdioma">
                           <Form.Label className="mt-2">Idioma</Form.Label>
                           <Form.Control type="text" placeholder="pt-br"
                              value={idioma}
                              onChange={event => setIdioma(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a idioma.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicIsbn">
                           <Form.Label className="mt-2">ISBN</Form.Label>
                           <InputMask className="form-control" placeholder="999-99-999-9999-9"
                              value={isbn} mask="999-99-999-9999-9"
                              onChange={event => setIsbn(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a ISBN.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_producao">
                           <Form.Label className="mt-2">data de Produção</Form.Label>
                           <Form.Control type="date"
                              value={data_producao}
                              onChange={event => setData_producao(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a data de Produção.
                                 </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_publicacao">
                           <Form.Label className="mt-2">Data de Publicação</Form.Label>
                           <Form.Control type="date"
                              value={data_publicacao}
                              onChange={event => setData_publicacao(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Data de Publicação.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicPais">
                           <Form.Label className="mt-2">Pais</Form.Label>
                           <Form.Control type="text" placeholder="Brasil"
                              value={pais}
                              onChange={event => setPais(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Pais.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicEntidade">
                           <Form.Label className="mt-2">Entidade</Form.Label>
                           <Form.Control type="text" placeholder="João Silva"
                              value={entidade}
                              onChange={event => setEntidade(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Entidade.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicCover">
                           <Form.Label className="mt-3">sua capa</Form.Label>
                           <FilePond
                              onaddfile={(error: any, file: any) => {
                                 // Set currently active file objects to this.state
                                 if (error) {
                                    setError(error.main)
                                 } else {
                                    setCover(`data:${file.fileType};base64,${file.getFileEncodeBase64String()}`)
                                 }
                              }}
                              onremovefile={(error: any, file: any) => {
                                 setError('')
                                 setCover('')
                              }}
                              allowReorder={true}
                              allowImagePreview={true}
                              allowImageExifOrientation={true}
                              allowFileEncode={true}
                              allowMultiple={false}
                              maxFiles={1}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                              fileValidateTypeLabelExpectedTypesMap={[
                                 { 'image/png': '.png' },
                                 { 'image/jpeg': '.png' },
                                 { 'image/jpg': '.png' }
                              ]}
                              className="mt-3"
                              fileValidateTypeLabelExpectedTypes={''}
                              allowFileSizeValidation={true}
                              maxFileSize='500000'
                              required
                              labelMaxFileSizeExceeded="capa muito grande"
                              labelMaxFileSize="maximo aceitavel {filesize}"
                              labelFileTypeNotAllowed='FORMATO NÃO SUPORTADO'
                              labelFileProcessing='CARREGANDO...'
                              labelFileProcessingComplete='COMPLETADO'
                              labelFileProcessingAborted='ERRO NO UPLOAD'
                              labelTapToUndo='TOQUE PARA DESFAZER'
                              labelTapToCancel='TOQUE PARA CANCELAR'
                              labelButtonRemoveItem='REMOVER'
                              labelButtonAbortItemProcessing='CANCELAR'

                              labelIdle='TAMANHO MAXIMO: 500KB;<br> <span class="filepond--label-action">SELECIONAR</span>'
                           />
                        </Form.Group>

                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? skipStep4Validate : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'PROXIMA ETAPA'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
            </Publication_creates>
         );
      } else {
         return (
            <Publication_creates>
               <Header title="Revisão" go_back={skipStep3} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form.Group controlId="formBasictitle">
                           <Form.Label className="mt-2">Título</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Teste"
                                 value={title} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep1}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicAuthor">
                           <Form.Label className="mt-2">Autor</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Teste"
                                 value={authors.filter(
                                    function (i: any) {
                                       return i.id == author_pub;
                                    }
                                 ).map((i: any) => (i.name))} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep1}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>

                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                           <Form.Label className="mt-2">Descrição</Form.Label>
                           <InputGroup>
                              <Form.Control as="textarea" maxLength={5000} rows={5} placeholder="este livro..."
                                 value={description}
                                 disabled={true} />
                              <p className="text-right contAreatext">{5000-Number(description.length)}/5000</p>
                              <Button type="button" variant="light"
                                 onClick={skipStep1}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicType">
                           <Form.Label className="mt-2">Tipo</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Teste"
                                 value={(type == 'purchase') ? 'Venda' : 'Emprestimo'} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicCurrency">
                           <Form.Label className="mt-2">Preço</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="0,00"
                                 value={`${currency} ${price}`} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicDiscount">
                           <Form.Label className="mt-2">Valor com Desconto</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="0,00"
                                 value={`${currency} ${discount}`} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicGenre">
                           <Form.Label className="mt-2">Gênero</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="0,00"
                                 value={genre.toString()} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicIdioma">
                           <Form.Label className="mt-2">Idioma</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="pt-br"
                                 value={idioma} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicIsbn">
                           <Form.Label className="mt-2">ISBN</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="999-99-999-9999-9"
                                 value={isbn} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_producao">
                           <Form.Label className="mt-2">data de Produção</Form.Label>
                           <InputGroup>
                              <Form.Control type="text"
                                 value={moment(data_producao).format('DD/MM/YYYY')} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicData_publicacao">
                           <Form.Label className="mt-2">Data de Publicação</Form.Label>
                           <InputGroup>
                              <Form.Control type="text"
                                 value={moment(data_publicacao).format('DD/MM/YYYY')} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicPais">
                           <Form.Label className="mt-2">Pais</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Brasil"
                                 value={pais} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicEntidade">
                           <Form.Label className="mt-2">Entidade</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Brasil"
                                 value={entidade} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicCover">
                           <InputGroup>
                              <Form.Label className="mt-3">sua capa</Form.Label>
                              <Button type="button" variant="light"
                                 onClick={skipStep3}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                           <Image src={cover} fluid width="100%" />
                        </Form.Group>
                        <Form.Group>
                           <Form.Label className="mt-3">Arquivo</Form.Label>
                           <InputGroup>
                              <Form.Control type="text" placeholder="Brasil"
                                 value={epub} disabled={true} />
                              <Button type="button" variant="light"
                                 onClick={skipStep2}>
                                 <FontAwesomeIcon icon={faPen} className="" />
                              </Button>
                           </InputGroup>
                        </Form.Group>
                        <div className="text-center mt-5">
                           {(error) ?
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                              :
                              ''
                           }
                           <Button variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? createPublication : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'CADASTRAR'}
                           </Button>
                        </div>
                     </Card>
                  </Row>

               </Container>
               <Loading show={show} />
            </Publication_creates>
         );
      }

   }
}