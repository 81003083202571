import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Card, Spinner  } from 'react-bootstrap';
import { Divs, Spinners } from './styles';
import Header from '../Header';
import { useInView } from 'react-intersection-observer'

import publications from '../../services/publications';
import Card_public  from '../../components/card_public';
import Loading from '../../components/loading';

import { ThemeContext } from 'styled-components';
import auth from '../../services/auth';
import { useHistory  } from 'react-router-dom';


import Image_loading from '../../components/image_loading';

export default function Home() {

  let history = useHistory();
  const [show, setShow] = useState(false);
  const [publication, setPublication] = useState<any>([]);
  const [page_count, setPage_count] = useState(0);
  const [page_size, setPagesize] = useState(0);
  const theme = useContext(ThemeContext);
  let token = auth().isAuthenticated();
  const [ref, inView] = useInView({
    threshold: 0,
  })

  if(token){
    history.push("/dashboard");
  }  
  
  useEffect(() => {
    setShow(true);
    async function loadProducts() {
        publications().indexLoans([]).then((teste: any) => {
          setPublication(teste.results);
          setPage_count(teste.page_count - 1);
          setPagesize(0)
          setShow(false);
        });
    }


    if (process.env.REACT_APP_SIGLA === 'RDR ') {
      loadProducts();
    }
  }, []);

  if(inView){
    if(page_size < page_count) {
      if (process.env.REACT_APP_SIGLA === 'PUB ') {
        let pag = page_size + 1;
        
        publications().indexOrg({ page: pag }).then((teste2: any) => {
            setPagesize(pag);
            setPublication([...publication, ...teste2.results ]);
        });


      }else{
        publications().indexLoans({ page: (page_size + 1) }).then((teste2: any) => {
            setPagesize(page_size + 1);
            setPublication([...publication, ...teste2.results ]);
        });

      }

    }
    
  }


  if (process.env.REACT_APP_SIGLA === 'PUB ') {

    return (
      <Divs>
        <Row className="justify-content-md-center header">
          <div className="text-center div_signin">
            <Container>
              <Row>
                <Col className="m-auto">
                  <h6>NOVO POR AQUI?</h6>
                  <Button onClick={() => history.push(`/register`)} variant="light" type="button">CADASTRE-SE</Button>
                </Col>
                <Col>
                  <Image_loading image={`/img/avatar/avatar_${Math.floor(Math.random() * 8) + 1}.svg`} width={150} height={200} alt="avatar"/>
                
                </Col>
              </Row>
              <div className="mt-4 mb-4">
                <Row>
                  <Col className="m-auto">
                    <p className="m-auto">OU SE FOR CADASTRADO?</p>
                  </Col>
                  <Col  className="m-auto">
                    <Button variant="light" size="sm" onClick={() => history.push(`/login`)} type="button">CLIQUE AQUI</Button>
                  </Col>
                </Row>
  
              </div>
  
            </Container>
          </div>
          
        </Row>
        <Container>
          <div className="text-center">
            <Image_loading image={theme.icon_dark} width={200} height={250} alt="logo"  />
          </div>
        </Container>
      </Divs>
    );
  }else{
    
    return (
      <Divs>
        <Header />
          <div className="text-center div_signin mt-5 pt-3 pb-1">
            <Container>
              <Row className="div_new">
                <Col className="m-auto">
                  <h6>NOVO POR AQUI?</h6>
                  <Button onClick={ () => history.push("/register")}  variant="light" type="button">CADASTRE-SE</Button>
                </Col>
                <Col>
                  <Image_loading image={`/img/avatar/avatar_${Math.floor(Math.random() * 8) + 1}.svg`} width={150} height={200} alt="avatar"/>
                
                </Col>
              </Row>
              <div className="mt-4 mb-4">
                <Row>
                  <Col className="m-auto">
                    <p className="m-auto">OU SE FOR CADASTRADO?</p>
                  </Col>
                  <Col  className="m-auto">
                    <Button variant="light" size="sm" onClick={ () => history.push("/login")} type="button">CLIQUE AQUI</Button>
                  </Col>
                </Row>
  
              </div>
  
            </Container>
          </div>
          
        <Container>
          <Row>
            {(publication) ?
              publication.map((item: any, index: any) => (
                <Col xs="4" lg="2" key={item.id}>
                  <Card_public publication={item} />
                </Col>
              )) :
              <div className="text-center">
                <p>Nenhuma publicação cadastrada</p>
              </div>
            }
            {(page_size < page_count) ?
              <div ref={ref} className={(inView) ? 'visible': 'invisible'}>
                <Spinners className="text-center p-auto m-auto mt-4">
                  <Spinner animation="border" />
                </Spinners>
              </div>
              :  ''
            }
          </Row>
        </Container>
      </Divs>
    );
  }
}
