const reader = {
   title: 'reader',

   favicon: '/img/icons/reader-favicon.ico',
   icon: '/img/icons/reader.svg',
   icon_dark: '/img/icons/reader-dark.svg',
   colors: {
      primary: '#EFA835',
      secondary: '#706F6F',
      text_color_primary: '#333333',
      text_color_secondary: '#ffffff',
   }
}
export default reader;