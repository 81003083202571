import React, { useState, useEffect, useRef } from 'react';

import Header from '../Header';
import user_interface from '../../interfaces/user_interface';

import { Card, Container, Row, Form, Button, Alert } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import auth from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { Registers } from './styles';


import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginImageEdit, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize)



export default function Register() {

   let history = useHistory();
   let token = auth().isAuthenticated();

   if (token) {
      history.push("/dashboard");
   }
   const [error, setError] = useState('');
   const [open, setOpen] = useState(false);
   const [email, setEmail] = useState('');
   const [name, setName] = useState('');
   const [celular, setCelular] = useState('');
   const [cpf_cnpj, setCpf_cnpj] = useState('');
   const [razao_social, setRazao_social] = useState('');
   const [nome_fantasia, setNome_fantasia] = useState('');
   const [description, setDescription] = useState('');
   const [image, setImage] = useState('');
   const [isLoading, setLoading] = useState(false);
   let pond: any = useRef(null);
   let pond_pub: any = useRef(null);
   const mark_celular = '(99) 99999-9999';
   const mark_cpf_cnpj = (process.env.REACT_APP_SIGLA == 'PUB ') ? '99.999.999/9999-99' : '999.999.999-99';
   const placeholder_cpf_cnpj = (process.env.REACT_APP_SIGLA == 'PUB ') ? 'XX.XXX.XXX/0001-XX' : 'XXX.XXX.XXX-XX';
   

   function validateForm() {

      setError("");
      if (!email || !name || !celular || !cpf_cnpj || !image) {
         setError("Preencha todos os campos para continuar!");
         setLoading(false);
      } else {
         let cpf_cnpj_user = '';
         if (process.env.REACT_APP_SIGLA == 'PUB ') {
            cpf_cnpj_user = (validarCNPJ(cpf_cnpj)) ? cpf_cnpj.replace(/[^\d]+/g, '') : '';
         } else {
            cpf_cnpj_user = (validarCPF(cpf_cnpj.replace(/[^\d]+/g, ''))) ? cpf_cnpj.replace(/[^\d]+/g, '') : '';
         }
         if (error == '') {

            let data: user_interface = {
               email: email,
               password: '1111',
               name: name,
               is_editora: (process.env.REACT_APP_SIGLA == 'PUB ') ? true : false,
               hint: name,
               celular: (validatePhone(celular)) ? celular : '',
               cpf_cnpj: cpf_cnpj_user,
               razao_social: (razao_social != '') ? razao_social : name,
               nome_fantasia: (nome_fantasia != '') ? razao_social : name,
               description: (description != '') ? description : name,
               image: image
            };
            if ((error == '') && (data.celular != '') && (data.cpf_cnpj != '')) {
               return data;

            } else {

               console.log(error);
            }

         } else {

            console.log(error);
         }
      }

   }

   function validarCNPJ(cnpj: any) {

      let cnpj2: any = cnpj.replace(/[^\d]+/g, '');

      if (cnpj2 == '') {
         setError("CNPJ ainda não preenchido!");
         return false;
      }

      if (cnpj2.length != 14) {
         setError("CNPJ ainda não preenchido!");
         return false;
      }

      // Elimina CNPJs invalidos conhecidos
      if (cnpj2 == "00000000000000" ||
         cnpj2 == "11111111111111" ||
         cnpj2 == "22222222222222" ||
         cnpj2 == "33333333333333" ||
         cnpj2 == "44444444444444" ||
         cnpj2 == "55555555555555" ||
         cnpj2 == "66666666666666" ||
         cnpj2 == "77777777777777" ||
         cnpj2 == "88888888888888" ||
         cnpj2 == "99999999999999") {
         setError("CNPJ invalido!");
         return false;
      }

      // Valida DVs
      let tamanho: number = cnpj2.length - 2;
      let numeros: any = cnpj2.substring(0, tamanho);
      let digitos: any = cnpj2.substring(tamanho);
      let soma: any = 0;
      let pos: number = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
         soma += numeros.charAt(tamanho - i) * pos--;
         if (pos < 2)
            pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) {
         setError("CNPJ invalido!");
         return false;

      }

      tamanho = tamanho + 1;
      numeros = cnpj2.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
         soma += numeros.charAt(tamanho - i) * pos--;
         if (pos < 2)
            pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) {
         setError("CNPJ invalido!");
         return false;

      }

      return true;

   }

   function validarCPF(strCPF: any) {
      
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
   }

   function validatePhone(celular: any) {
      if (celular.length != 15) {
         setError("celular ainda não preenchido!");
         return false;
      }

      if (celular == "(00) 00000-0000" ||
         celular == "(11) 11111-1111" ||
         celular == "(22) 22222-2222" ||
         celular == "(33) 33333-3333" ||
         celular == "(44) 44444-4444" ||
         celular == "(55) 55555-5555" ||
         celular == "(66) 66666-6666" ||
         celular == "(77) 77777-7777" ||
         celular == "(88) 88888-8888" ||
         celular == "(99) 99999-9999") {
         setError("celular invalido!");
         return false;
      }
      return true;

   }

   const handleClick = () => setLoading(true);

   const goBack = () => {
      setOpen(false);

   }

   useEffect(() => {
      const id_avatar = Math.floor(Math.random() * 8) + 1;
      if (process.env.REACT_APP_SIGLA === 'PUB ') {
         if(open){
            pond_pub.addFile(`/img/avatar/avatar_${id_avatar}.svg`);
   
         }

      }else{
         pond.addFile(`/img/avatar/avatar_${id_avatar}.svg`);

      }
   }, [pond, pond_pub, open]);
   

   useEffect(() => {
      if (isLoading) {
         let user: any = validateForm();
         if (user) {
            history.push({
               pathname: '/terms',
               state: user
            });

         } else {
            setLoading(false);
         }
      }
   }, [isLoading]);
   

   if (process.env.REACT_APP_SIGLA === 'PUB ') {

      if (!open) {

         return (
            <Registers>
               <Header title="Cadastrar - Pessoal" />
               <Container>
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5">
                        <Form.Group controlId="formBasicName">
                           <Form.Label className="mt-2">Nome Completo</Form.Label>
                           <Form.Control type="text" aria-required="true" placeholder="Nome Completo"
                              value={name}
                              onChange={event => setName(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a name.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                           <Form.Label className="mt-2">Email</Form.Label>
                           <Form.Control type="email" aria-required="true" placeholder="seuemail@dominio.com"
                              value={email}
                              onChange={event => setEmail(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a username.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicCelular">
                           <Form.Label className="mt-2">Celular</Form.Label>
                           <InputMask className="form-control" aria-required="true" mask={mark_celular} placeholder="(11) 99999-9999"
                              value={celular}
                              onChange={event => setCelular(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a celular.
                           </Form.Control.Feedback>
                        </Form.Group>
                        {error &&
                           <div className="text-center mt-4">
                              <Alert variant="danger" dismissible>{error}</Alert>
                           </div>
                        }

                        <Button className="mt-5" variant="secondary" size="lg" type="button"
                           onClick={() => setOpen(!open)}
                           aria-controls="example-collapse-text"
                           aria-expanded={open}>
                           PROXIMO
                        </Button>
                     </Card>

                  </Row>

               </Container>
            </Registers>
         )

      } else {
         
         return (
            <Registers>
               <Header title="Cadastrar - Empresa" go_back={goBack} />
               <Container>
                  <Row className="justify-content-md-center">

                     <Card className="card-body mt-5">
                        <Form.Group controlId="formBasicRazao_social">
                           <Form.Label className="mt-2">Razão Social</Form.Label>
                           <Form.Control type="text" aria-required="true" placeholder="Sua Razão Social"
                              value={razao_social}
                              onChange={event => setRazao_social(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Razão Social.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicNome_fantasia">
                           <Form.Label className="mt-2">Nome Fantasia</Form.Label>
                           <Form.Control type="text" aria-required="true" placeholder="Seu Nome Fantasia"
                              value={nome_fantasia}
                              onChange={event => setNome_fantasia(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a Nome Fantasia.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                           <Form.Label className="mt-2">Descrição</Form.Label>
                           <Form.Control as="textarea" aria-required="true" maxLength={2000} rows={5} placeholder="Descreva sua empresa"
                              value={description}
                              onChange={event => setDescription(event.target.value)} required />
                           <p className="text-right contAreatext">{2000 - Number(description.length)}/2000</p>
                           <Form.Control.Feedback type="invalid">
                              Please choose a Nome Fantasia.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicCpf_cnpj">
                           <Form.Label className="mt-2">CNPJ</Form.Label>
                           <InputMask className="form-control" aria-required="true" mask={mark_cpf_cnpj} placeholder={placeholder_cpf_cnpj}
                              value={cpf_cnpj}
                              onChange={(event: any) => setCpf_cnpj(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a name.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                           <Form.Label className="mt-2">SEU LOGO</Form.Label>
                           <FilePond
                              ref={ref => pond_pub = ref}
                              onaddfile={(error: any, file: any) => {
                                 // Set currently active file objects to this.state
                                 if (error) {
                                    setError(error.main)
                                 } else {
                                    setImage(`data:${file.fileType};base64,${file.getFileEncodeBase64String()}`)
                                 }
                              }}
                              onremovefile={(error: any, file: any) => {
                                 setError('')
                                 setImage('')
                              }}
                              allowReorder={true}
                              allowImagePreview={true}
                              allowImageExifOrientation={true}
                              allowFileEncode={true}
                              allowMultiple={false}
                              maxFiles={1}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml']}
                              fileValidateTypeLabelExpectedTypesMap={[
                                 { 'image/png': '.png' },
                                 { 'image/jpeg': '.jpeg' },
                                 { 'image/jpg': '.jpg' },
                                 { 'image/svg+xml': '.svg' }
                              ]}
                              className="mt-3"
                              fileValidateTypeLabelExpectedTypes={''}
                              allowFileSizeValidation={true}
                              maxFileSize='500000'
                              required
                              labelMaxFileSizeExceeded="capa muito grande"
                              labelMaxFileSize="maximo aceitavel {filesize}"
                              labelFileTypeNotAllowed='FORMATO NÃO SUPORTADO'
                              labelFileProcessing='CARREGANDO...'
                              labelFileProcessingComplete='COMPLETADO'
                              labelFileProcessingAborted='ERRO NO UPLOAD'
                              labelTapToUndo='TOQUE PARA DESFAZER'
                              labelTapToCancel='TOQUE PARA CANCELAR'
                              labelButtonRemoveItem='REMOVER'
                              labelButtonAbortItemProcessing='CANCELAR'

                              labelIdle='TAMANHO MAXIMO: 500KB;<br> <span class="filepond--label-action">SELECIONAR SEU LOGO</span>'
                           />
                        </Form.Group>

                        {error &&
                           <div className="text-center mt-4">
                              <Alert variant="danger" dismissible>{error}</Alert>
                           </div>
                        }
                        <Button className="mt-5" variant="secondary" size="lg" type="button"
                           onClick={!isLoading ? handleClick : undefined}
                           disabled={isLoading}>
                           {isLoading ? 'Carregando...' : 'CADASTRAR'}
                        </Button>
                     </Card>

                  </Row>

               </Container>
            </Registers>
         )

      }
   } else {

      return (
         <Registers>
            <Header title="Cadastrar - Pessoal" />
            <Container>
               <Row className="justify-content-md-center">
                  <Card className="card-body mt-5">
                     <Form >
                        <Form.Group controlId="formBasicName">
                           <Form.Label className="mt-2">Nome Completo</Form.Label>
                           <Form.Control type="text" aria-required="true" placeholder="Nome Completo"
                              value={name}
                              onChange={event => setName(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a name.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                           <Form.Label className="mt-2">Email</Form.Label>
                           <Form.Control type="email" aria-required="true" placeholder="seuemail@dominio.com"
                              value={email}
                              onChange={event => setEmail(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a username.
                           </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicCelular">
                           <Form.Label className="mt-2">Celular</Form.Label>
                           <InputMask className="form-control" aria-required="true" mask={mark_celular} placeholder="(11) 99999-9999"
                              value={celular}
                              onChange={event => setCelular(event.target.value)} required />
                           <Form.Control.Feedback type="invalid">
                              Please choose a celular.
                           </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                           <Form.Label className="mt-2">{(process.env.REACT_APP_SIGLA == 'PUB ') ? 'CNPJ' : 'CPF'} </Form.Label>
                           <p>
                              <InputMask className="form-control" aria-required="true" mask={(process.env.REACT_APP_SIGLA == 'PUB ') ? '99.999.999/9999-99' : '999.999.999-99'}placeholder={placeholder_cpf_cnpj}
                                 value={cpf_cnpj}
                                 onChange={(event: any) => setCpf_cnpj(event.target.value)} />
                           </p>
                        </Form.Group>

                        <Form.Group>
                           <Form.Label className="mt-2">SEU AVATAR</Form.Label>
                           <FilePond
                              ref={ref => pond = ref}
                              onaddfile={(error: any, file: any) => {
                                 // Set currently active file objects to this.state
                                 if (error) {
                                    setError(error.main)
                                 } else {
                                    setImage(`data:${file.fileType};base64,${file.getFileEncodeBase64String()}`)
                                    setError('')
                                 }
                              }}
                              onremovefile={(error: any, file: any) => {
                                 setError('')
                                 setImage('')
                              }}
                              allowReorder={true}
                              allowImagePreview={true}
                              allowImageExifOrientation={true}
                              allowFileEncode={true}
                              allowMultiple={false}
                              maxFiles={1}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml']}
                              fileValidateTypeLabelExpectedTypesMap={[
                                 { 'image/png': '.png' },
                                 { 'image/jpeg': '.jpeg' },
                                 { 'image/jpg': '.jpg' },
                                 { 'image/svg+xml': '.svg' }
                              ]}
                              className="mt-3"
                              fileValidateTypeLabelExpectedTypes={''}
                              allowFileSizeValidation={true}
                              maxFileSize='500000'
                              required
                              labelMaxFileSizeExceeded="capa muito grande"
                              labelMaxFileSize="maximo aceitavel {filesize}"
                              labelFileTypeNotAllowed='FORMATO NÃO SUPORTADO'
                              labelFileProcessing='CARREGANDO...'
                              labelFileProcessingComplete='COMPLETADO'
                              labelFileProcessingAborted='ERRO NO UPLOAD'
                              labelTapToUndo='TOQUE PARA DESFAZER'
                              labelTapToCancel='TOQUE PARA CANCELAR'
                              labelButtonRemoveItem='REMOVER'
                              labelButtonAbortItemProcessing='CANCELAR'

                              labelIdle='TAMANHO MAXIMO: 500KB;<br> <span class="filepond--label-action">SELECIONAR SEU AVATAR</span>'
                           />
                        </Form.Group>

                        {error &&
                           <div className="text-center mt-4">
                              <Alert variant="danger" dismissible>{error}</Alert>
                           </div>
                        }
                        <div className="text-center">

                           <Button className="mt-2" variant="secondary" size="lg" type="button"
                              onClick={!isLoading ? handleClick : undefined}
                              disabled={isLoading}>
                              {isLoading ? 'Carregando...' : 'CADASTRAR'}
                           </Button>
                        </div>
                     </Form>
                  </Card>

               </Row>

            </Container>
         </Registers>
      )
   }

}