import styled from 'styled-components';

export const Card_comments = styled.div`
svg{
   vertical-align: -0.3em !important;
}

a{
   color: ${props => props.theme.colors.secondary} !important;
   text-decoration: none !important;
}
p{
   
   word-break: break-word;
}

.comment_name{
   font-size: 14px;
}
.comment_date{
   font-size: 12px;
}
`;