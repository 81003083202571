import styled from 'styled-components';
import { Tabs, Card } from 'react-bootstrap';

export const Navtabs = styled(Tabs)`
   background-color: ${props => props.theme.colors.secondary} !important;
   border-bottom: 3px solid ${props => props.theme.colors.primary} !important;

   .active{
      background-color: ${props => props.theme.colors.secondary} !important;
      border-color: ${props => props.theme.colors.secondary} !important;
      border-bottom: 5px solid ${props => props.theme.colors.primary} !important;

   }
   .carousel-container{
      background-color: ${props => props.theme.colors.secondary} !important;
   }
   .tab-pane {
      background-color: ${props => props.theme.colors.secondary} !important;
   }

   .image {
      width: 250px;
      height: 200px;
   }
`;

export const Card_teste = styled.div`
   background-color: ${props => props.theme.colors.secondary} !important;

   ul{
      width: 13490px !important;
   }
   button{
      background-color: ${props => props.theme.colors.secondary} !important;
      color: ${props => props.theme.colors.primary} !important;
      border: 0px;
      border-radius: 0rem;
   }
`;