import axios from 'axios';
import auth from './auth';


export default function users() {
    let base_url = process.env.REACT_APP_BASE_URL;

    let users = {
        get: function get(): any {
            return axios.get(`${base_url}v1/users`,
                {
                    headers: {
                        //origin: Available values : PUB, STR
                        'Origins': process.env.REACT_APP_SIGLA,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${auth().getToken()}`
                    }
                })
                .then(function (response) {
                    return { response: response.data };
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        update: function update(data: any) {
            return axios.put(`${base_url}v1/users`,
                JSON.stringify(data),
                {
                    headers: {
                        //origin: Available values : PUB, STR
                        'Origins': process.env.REACT_APP_SIGLA,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${auth().getToken()}`
                    }
                });
        }

    };

    return users;
}
