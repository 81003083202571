import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Spinner, Card } from 'react-bootstrap';
import Header from '../Header';

import { useInView } from 'react-intersection-observer'

import Loading from '../../components/loading';
import publications from '../../services/publications';
import Card_public  from '../../components/card_public';
import Card_list from '../../components/card_list';
import Tab_banners from '../../components/tab_banners';
import auth from '../../services/auth';
import { Fabs, Spinners, Dashboards } from './styles';

import { useHistory } from 'react-router-dom';
import { FloatingMenu, MainButton, Directions } from 'react-floating-button-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from 'styled-components';

export default function Dashboard() {


	const [publication, setPublication] = useState<any>([]);
	const [getBuy, setgetBuy] = useState([]);
	const [getLoans, setgetLoans] = useState([]);
	const [getRecent, setgetRecent] = useState([]);
	const [page_count, setPage_count] = useState(0);
	const [page_size, setPagesize] = useState(0);
	const [disabled, setDisabled] = useState(true);
	const [show, setShow] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [ref, inView] = useInView({
		threshold: 0,
	})
	const theme = useContext(ThemeContext);

	let history = useHistory();
	let token = auth().isAuthenticated();

	if (!token) {
		history.push("/");
	}
	

	useEffect(() => {
		setShow(true);
		async function loadProducts() {

			if (process.env.REACT_APP_SIGLA === 'PUB ') {
				publications().indexOrg({ page:  0, pageSize: 10  }).then((teste: any) => {
					setPublication(teste.results);
					setPage_count(teste.page_count - 1);
					setPagesize(0)
					setShow(false);
				});

			} else if (process.env.REACT_APP_SIGLA === 'RDR '){
				
				publications().recent([]).then((teste: any) => {
					setgetRecent(teste.results);
					setShow(false);

				});
				publications().indexLoans([]).then((teste: any) => {
					setgetLoans(teste.results);
					setShow(false);

				}); 
			} else {

				publications().all([]).then((teste: any) => {
					setPublication(teste.results);
					setShow(false);

				});
				publications().indexBuy([]).then((teste: any) => {
					setgetBuy(teste.results);
					setShow(false);

				});
				publications().indexLoans([]).then((teste: any) => {
					setgetLoans(teste.results);
					setShow(false);

				});
				publications().recent([]).then((teste: any) => {
					setgetRecent(teste.results);
					setShow(false);

				});
			}
		}


		loadProducts();

	}, []);

	if (inView) {
		if (page_size < page_count) {

			if (process.env.REACT_APP_SIGLA === 'PUB ') {

				publications().indexOrg({ page:  (page_size + 1), pageSize: 10  }).then((teste2: any) => {
					setPagesize(page_size + 1);
					setPublication([...publication, ...teste2.results ]);
				});


			} else {
				publications().all({ }).then((teste2: any) => {
					setPagesize(page_size + 1);
					setPublication([...publication, ...teste2.results ]);
				});

			}

		}

	}

	if (process.env.REACT_APP_SIGLA === 'PUB ') {

		return (
			<>
				<Header />
				<Container className="mt-6">
					<div className="pt-2 pb-5">
						{(publication) ?
							publication.map((item: any, index: any) => (
								(item) ?
									<Card_list publication={item} key={index} />
									: null
							)) :
							<div className="text-center p-auto m-auto ">
								<p>Nenhuma publicação cadastrada</p>
							</div>
						}
						{(page_size < page_count) ?
							<div ref={ref} className={(inView) ? 'visible' : 'invisible'}>
								<Spinners className="text-center p-auto m-auto mt-4">
									<Spinner animation="border" />
								</Spinners>
							</div>
							: ''
						}

					</div>

					<Fabs>
						<FloatingMenu
							slideSpeed={500}
							isOpen={isOpen}
							spacing={8}
							direction={Directions.Up}
						>
							<MainButton
								isOpen={isOpen}
								iconResting={<FontAwesomeIcon icon={faPlus} className="icon_fab" />}
								iconActive={<FontAwesomeIcon icon={faTimes} className="icon_fab" />}
								background={theme.colors.primary}
								onClick={() => {
									setIsOpen((prev) => !prev);
									history.push("/publications/create");

								}}
								size={56}
							/>
						</FloatingMenu>
					</Fabs>
					<Loading show={show} />
				</Container>
			</>
		)
	} else if (process.env.REACT_APP_SIGLA === 'RDR ')  {

		return (
			<div>
				<Header />
				<Tab_banners publication={getRecent} />
				<Container >
					<Dashboards>
						<Row>
							<Col lg="12">
								<div className="mb-2">
									<p className="div_new mt-3">para empréstimo</p>
									<Row>
										{(getLoans) ?
											getLoans.map((item: any) => (
												<Col className="mt-2" xs="4" lg="2" >
													<Card_public publication={item} key={`loans-${item.id}`} width={90} height={120} />
												</Col>
											)) :
											<div className="text-center">
												<p>Nenhuma publicação cadastrada</p>
											</div>
										}
										{(getLoans.length > 9) ?
											<Col className="mt-2" xs="4" lg="2" key={`all-all`}>
												<Card className="justify-content-center align-items-center">
													<Card.Body>
														<a  onClick={() => history.push(`/publications/loan`)} >
															<FontAwesomeIcon icon={faPlus} size="3x" />
														</a>
													</Card.Body>
													<div className="text-center">
														<p>ver mais</p>
													</div>
												</Card>
											</Col>
											: ''
										}
									</Row>
								</div>
							</Col>
						</Row>
						<Loading show={show} />
					</Dashboards>
				</Container>
			</div>
		)

	} else {


		return (
			<div>
				<Header />
				<Tab_banners publication={getRecent} />
				<Container >
					<Dashboards>
						<Row>
							<Col lg="12">
								<div className="mb-2">
									<p className="div_new mt-3">todas</p>
									<Row>
										{(publication) ?
											publication.map((item: any, index: any) => (
												<Col className="mt-2" xs="4" lg="4" key={`all-${index}`}>
													<Card_public publication={item} key={`all-${index}`} width={90} height={120} />
												</Col>
											))

											:
											<div className="text-center">
												<p>Nenhuma publicação cadastrada</p>
											</div>
										}
										{(publication.length > 9) ?
											<Col className="mt-2" xs="4" lg="4" key={`all-all`}>
												<Card className="justify-content-center align-items-center">
													<Card.Body>
														<a onClick={() => history.push(`/publications/all`)} >
															<FontAwesomeIcon icon={faPlus} size="3x" />
														</a>
													</Card.Body>
													<div className="text-center">
														<p>ver mais</p>
													</div>
												</Card>
											</Col>
											: ''
										}
									</Row>
									<p className="div_new mt-3">para comprar</p>
									<Row>

										{(getBuy) ?
											getBuy.map((item, index) => (
												<Col className="mt-2" xs="4" lg="4" key={`buy-${index}`}>
													<Card_public publication={item} key={`buy-${index}`} width={90} height={120} />
												</Col>
											)) :
											<div className="text-center">
												<p>Nenhuma publicação cadastrada</p>
											</div>
										}
										{(getBuy.length > 9) ?
											<Col className="mt-2" xs="4" lg="4" key={`all-all`}>
												<Card className="justify-content-center align-items-center">
													<Card.Body>
														<a onClick={() => history.push(`/publications/buy`)} >
															<FontAwesomeIcon icon={faPlus} size="3x" />
														</a>
													</Card.Body>
													<div className="text-center">
														<p>ver mais</p>
													</div>
												</Card>
											</Col>
											: ''
										}
									</Row>
									<p className="div_new mt-3">para empréstimo</p>
									<Row>
										{(getLoans) ?
											getLoans.map((item, index) => (
												<Col className="mt-2" xs="4" lg="4" key={`loans-${index}`}>
													<Card_public publication={item} key={`loans-${index}`} width={90} height={120} />
												</Col>
											)) :
											<div className="text-center">
												<p>Nenhuma publicação cadastrada</p>
											</div>
										}
										{(getLoans.length > 9) ?
											<Col className="mt-2" xs="4" lg="4" key={`all-all`}>
												<Card className="justify-content-center align-items-center">
													<Card.Body>
														<a onClick={() => history.push(`/publications/loan`)}>
															<FontAwesomeIcon icon={faPlus} size="3x" />
														</a>
													</Card.Body>
													<div className="text-center">
														<p>ver mais</p>
													</div>
												</Card>
											</Col>
											: ''
										}
									</Row>
								</div>
							</Col>
						</Row>
						<Loading show={show} />
					</Dashboards>
				</Container>
			</div>
		)

	}
}
