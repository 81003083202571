import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Form, Button, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { Users_div } from './styles';
import Header from '../Header';
import auth from '../../services/auth';
import users from '../../services/users';
import Loading from '../../components/loading';
import user_interface from '../../interfaces/user_interface';

import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginImageEdit, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize)

export default function User() {

   const [show, setShow] = useState(false);
   const [user, setUser] = useState<user_interface>();
   const [error, setError] = useState('');
   const [success, setSuccess] = useState(false);
   const [isLoading, setLoading] = useState(false);

   let history = useHistory();
   let token = auth().isAuthenticated();

   if (!token) {
      history.push("/");
   }

   useEffect(() => {
      setShow(true);
      async function loadUser() {

         users().get().then((results: any) => {
            setError('');
            setUser(results.response);
            setShow(false);

         });
      }

      loadUser();
   }, []);

   const updateUser = () => {
      setLoading(true);
      console.log(user);

      setShow(true);
      if (error == '') {
         users().update(user)
            .then(function (response) {
               setLoading(false);
               setShow(false);
               if (response.status == 200) {
                  setSuccess(true);
               }
            })
            .catch(function (error: any) {
               setLoading(false);
               setShow(false);
               setError(`${error.response.data.errors[0].message} ${error.response.data.errors[0].field}`);
            });

      } else {
         setLoading(false);
         setShow(false);
      }
   }

   if (!success) {
      return (
         <Users_div>
            <Header title="Suas Informações" />
            <Container >
               <Row className="justify-content-md-center">
                  <Card className="card-body mt-5 mb-5">
                     {(user) ?
                        <div >
                           <FilePond
                              allowReorder={true}
                              allowMultiple={false}
                              files={[user.image]}
                              onaddfile={(error: any, file: any) => {

                                 if (error) {
                                    setError(error.main);
                                 } else {
                                    setUser((prev: any) => ({ ...prev, image: `data:${file.fileType};base64,${file.getFileEncodeBase64String()}` }))
                                 }
                              }}
                              onremovefile={(error: any, file: any) => {
                                 setError('FAÇA O ULPOAD DO LOGO')

                              }}
                              allowImagePreview={true}
                              allowImageExifOrientation={true}
                              allowFileEncode={true}
                              maxFiles={1}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                              fileValidateTypeLabelExpectedTypesMap={[
                                 { 'image/png': '.png' },
                                 { 'image/jpeg': '.png' },
                                 { 'image/jpg': '.png' }
                              ]}
                              required
                              fileValidateTypeLabelExpectedTypes={''}
                              allowFileSizeValidation={true}
                              maxFileSize='500000'
                              labelMaxFileSizeExceeded="Arquivo muito grande"
                              labelMaxFileSize="maximo aceitavel {filesize}"
                              labelFileTypeNotAllowed="FORMATO NÃO SUPORTADO"
                              labelFileProcessing="CARREGANDO..."
                              labelFileProcessingComplete="COMPLETADO"
                              labelFileProcessingAborted="ERRO NO UPLOAD"
                              labelTapToUndo="TOQUE PARA DESFAZER"
                              labelTapToCancel="TOQUE PARA CANCELAR"
                              labelButtonRemoveItem="REMOVER"
                              labelButtonAbortItemProcessing="CANCELAR"
                              labelIdle='TAMANHO MAXIMO: 500KB;<br> <span class="filepond--label-action">SELECIONAR</span>'
                           />
                           <Form.Group controlId="formBasicName">
                              <Form.Label className="mt-2">Nome</Form.Label>
                              <Form.Control type="text" placeholder="João Silva"
                                 value={user.name}
                                 onChange={event => setUser((prev: any) => ({ ...prev, name: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Nome.
                              </Form.Control.Feedback>
                           </Form.Group>
                           {(process.env.REACT_APP_SIGLA == 'PUB ') ? 
                           <>
                              <Form.Group controlId="formBasicNameFantasia">
                                 <Form.Label className="mt-2">Nome Fantasia</Form.Label>
                                 <Form.Control type="text" placeholder="João Silva"
                                    value={user.nome_fantasia}
                                    onChange={event => setUser((prev: any) => ({ ...prev, nome_fantasia: event.target.value }))} required />
                                 <Form.Control.Feedback type="invalid">
                                    Please choose a Nome Fantasia.
                                 </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group controlId="formBasicRazaoSocial">
                                 <Form.Label className="mt-2">Razão Social</Form.Label>
                                 <Form.Control type="text" placeholder="João Silva"
                                    value={user.razao_social}
                                    onChange={event => setUser((prev: any) => ({ ...prev, razao_social: event.target.value }))} required />
                                 <Form.Control.Feedback type="invalid">
                                    Please choose a Razão Social.
                                 </Form.Control.Feedback>
                              </Form.Group>
                           </>
                           : ''
                           }
                           <Form.Group controlId="formBasicDescription">
                              <Form.Label className="mt-2">Descrição</Form.Label>
                              <Form.Control as="textarea" maxLength={2000} rows={5} placeholder="este livro..."
                                 value={user.description}
                                 onChange={event => setUser((prev: any) => ({ ...prev, description: event.target.value }))} required />
                              <p className="text-right contAreatext">{2000-Number(user.description.length)}/2000</p>
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Nome Fantasia.
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group controlId="formBasicEmail">
                              <Form.Label className="mt-2">Email</Form.Label>
                              <Form.Control type="text" placeholder="teste@teste.com"
                                 value={user.email}
                                 onChange={event => setUser((prev: any) => ({ ...prev, email: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Email.
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group controlId="formBasicCelular">
                              <Form.Label className="mt-2">Celular</Form.Label>
                              <Form.Control type="text" placeholder="teste@teste.com"
                                 value={user.celular}
                                 onChange={event => setUser((prev: any) => ({ ...prev, celular: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Email.
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group controlId="formBasicDescription">
                              <Form.Label className="mt-2">{(process.env.REACT_APP_SIGLA == 'PUB ') ? 'CNPJ' : 'CPF'} </Form.Label>
                              <InputMask className="form-control" value={user.cpf_cnpj} mask={(process.env.REACT_APP_SIGLA == 'PUB ') ? '99.999.999/9999-99' : '999.999.999-99'}
                                 onChange={event => setUser((prev: any) => ({ ...prev, cpf_cnpj: event.target.value }))} />
                           </Form.Group>
                           {error &&
                              <div className="text-center mt-4">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                           }
                           <div className="text-center mt-4">
                              <Button variant="secondary" size="lg" type="button"
                                 onClick={!isLoading ? updateUser : undefined}
                                 disabled={isLoading}>
                                 {isLoading ? 'Carregando...' : 'EDITAR'}</Button>

                           </div>

                        </div>
                        :
                        <div className="text-center">
                           <p>Nenhuma informação</p>
                        </div>
                     }
                  </Card>
               </Row>
            </Container>
            <Loading show={show} />
         </Users_div>
      );

   } else {

      return (
         <>
            <Header title="edição - Concluida" />
            <Container >
               <Row className="justify-content-md-center">
                  <Card className="card-body mt-5 mb-5" >
                     <div className="text-center">
                        <img src={`/img/avatar/avatar_${Math.floor(Math.random() * 8) + 1}.svg`} width="150" alt="avatar" className="mb-2" />
                        <h3>PARABÉNS</h3>
                        <h5>sua edição foi realizado!!!</h5>
                        <div className="text-center mt-5 mb-5">
                           <Button variant="secondary" type="button" onClick={() => history.push(`/`)}>CONTINUAR</Button>
                        </div>

                     </div>
                  </Card>

               </Row>

            </Container>
         </>
      );
   }
}