import styled from 'styled-components';

export const Card_lists = styled.div`
border-radius: 0.30rem !important;
   .card{
      word-wrap: inherit !important;

   }
   .card-img-1{
      margin-top: -10px;
      margin-left: -10px;
   }
   p{
      font-size: 12px;
      margin-bottom: 0 !important;
   }
   .badge {
      font-weight: 500 !important;
   }
`;