import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Signin from './pages/Signin';
import Register from './pages/Register';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Date_loan from './pages/Date_loan';
import Publication from './pages/Publication';
import Publication_create from './pages/Publication_create';
import Publication_list from './pages/Publication_list';
import Puchases_loan from './pages/Puchases_loan';
import Terms from './pages/Terms';
import Search from './pages/Search';
import Conclusion from './pages/Conclusion';
import User from './pages/User';
import Reader from './pages/Reader';
import Author from './pages/Author';
import Author_create from './pages/Author_create';

import auth from './services/auth';

export default function Routes() {

    
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" component={Signin} />
                <Route path="/register" component={Register} />
                <Route path="/terms" component={Terms} />
                <Route path="/conclusion" component={Conclusion} />
                <Route path="/users" component={User} />
                <Route path="/date_loan" component={Date_loan} />
                <Route path="/authors" component={Author} />
                <Route path="/author/create" component={Author_create} />
                <Route path="/author/update/:id" component={Author_create} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/publication/:id" component={Publication}  />
                <Route path="/publications/list/:id" component={Puchases_loan}/>
                <Route path="/search/:id" component={Search} />
                <Route path="/publications/author" component={Publication_list} />
                <Route path="/publications/buy" component={Publication_list} />
                <Route path="/publications/loan" component={Publication_list} />
                <Route path="/publications/reader" component={Reader} />
                <Route path="/publications/all" component={Publication_list} />
                <Route path="/publications/create" component={Publication_create} />
                <Route path="/publications/edit/:id" component={Publication_create} />
                {/* <Route path="/new" component={New} /> */}
            </Switch>
        </BrowserRouter>
    )
}