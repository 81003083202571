import React, { useState, useEffect, useRef } from 'react';
import { Button, Offcanvas, Row, Col, Accordion, Overlay, Popover } from 'react-bootstrap';
import { faAngleLeft, faBars, faBookmark, faTrashAlt, faEye, faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { Reader_epubs, Offcanvas2, Offcanvasheader, OffcanvasSummary } from './styles';


import Config_epub from '../../components/config_epub';
import Buttons_summary from '../../components/buttons_summary';
import Card_annotation from '../../components/card_annotation';
import Modal_annotation from '../../components/modal_annotation';

import ePub from 'epubjs';

import annotation from '../../services/annotation';

import usePeristedState from '../../utils/usePersistedState';
import annotation_interface from '../../interfaces/annotation_interface';
import theme_interface from '../../interfaces/theme_epub_interface';
import ebook_interface from '../../interfaces/ebook_interface';

export default function Reader_epub(props: any) {

   const epub = props.epub;
   const [books, setBooks] = useState<any>([]);
   const [themes, setThemes] = usePeristedState<theme_interface>('theme_epub');
   const [ebook, setEbook] = usePeristedState<ebook_interface>(`${epub.uuid}`);
   const [offCavasheight, setOffCavasheight] = useState("6vh");
   const [summary, setSummary] = useState<any>([]);
   const [annotations, setAnnotations] = useState<any>([]);
   const [annotationUpdate, setAnnotationUpdate] = useState<any>();
   const [startProgress, setStartProgress] = useState(false);
   const [cfiRanges, setCfiRange] = useState('');
   const [target, setTarget] = useState(null);
   const [rendition, setRendition] = useState<any>([]);
   const [showModal_annotation, setShowModal_annotation] = useState(false);
   const [showPopoverEdit, setShowPopoverEdit] = useState(false);
   const [showPopoverPublic, setShowPopoverPublic] = useState(false);
   const [showOffcanvasSummary, setShowOffcanvasSummary] = useState(false);
   const [showOffcanvasAnnotations, setShowOffcanvasAnnotations] = useState(false);
   const [showOffcanvas, setShowOffcanvas] = useState(false);
   const ref = useRef(null);
   const fontFamily: any = {
      "Georama":{
         "font-family": 'Georama',
         "src": "url('/fonts/Georama/Georama-Italic-VariableFont_wdth,wght.ttf'), url('/fonts/Georama/Georama-VariableFont_wdth,wght.ttf')"
      },
      "PT_Serif":{
         "font-family": 'PT Serif',
         "src": "url('/fonts/PT_Serif/PTSerif-Bold.ttf'), url('/fonts/PT_Serif/PTSerif-BoldItalic.ttf'), url('/fonts/PT_Serif/PTSerif-Italic.ttf'), url('/fonts/PT_Serif/PTSerif-Regular.ttf')"
      },
      "Roboto": {
         "font-family": 'Roboto',
         "src": "url('/fonts/Roboto/Roboto-Regular.ttf'), url('/fonts/Roboto/Roboto-Black.ttf'), url('/fonts/Roboto/Roboto-BlackItalic.ttf'), url('/fonts/Roboto/Roboto-Bold.ttf'), url('/fonts/Roboto/Roboto-BoldItalic.ttf'), url('/fonts/Roboto/Roboto-Italic.ttf'), url('/fonts/Roboto/Roboto-Medium.ttf'), url('/fonts/Roboto/Roboto-MediumItalic.ttf'), url('/fonts/Roboto/Roboto-Thin.ttf'), url('/fonts/Roboto/Roboto-ThinItalic.ttf')"
      },
      "OpenDyslexic": {
         "font-family": 'OpenDyslexic',
         "src": "url('/fonts/open_dyslexic/OpenDyslexic-Bold.otf'), url('/fonts/open_dyslexic/OpenDyslexic-BoldItalic.otf'), url('/fonts/open_dyslexic/OpenDyslexic-Italic.otf'), url('/fonts/open_dyslexic/OpenDyslexic-Regular.otf'), url('/fonts/open_dyslexic/OpenDyslexicAlta-Bold.otf'), url('/fonts/open_dyslexic/OpenDyslexicAlta-BoldItalic.otf'), url('/fonts/open_dyslexic/OpenDyslexicAlta-Italic.otf'), url('/fonts/open_dyslexic/OpenDyslexicMono-Regular.otf')",
      }
   }


	useEffect(() => {
      let start = false;
      
      if(!ebook){
         setEbook((prev: any) => ({ ...prev, page: null, Progress: `0%`, Current_page: 0, Total_page: 0 }));

      }

      async function getBook(){
         let div_area = document.getElementById("area");
         if(div_area != null){
            div_area.innerHTML = "";
            
            if(rendition.leagth > 0){
               rendition.destroy();
               books.destroy();

            }
         }
         let book = await  ePub(epub.link, {
            openAs: "epub",

            requestHeaders: {
               'Accept': '*/*',
               'Access-Control-Allow-Origin': '*',
               'Access-Control-Allow-methods': 'GET',
               'content-type': ''
            }
         });

         
         book.loaded.navigation.then( (navigation: any) => {
            let summary = navigation.toc.map((chapter: any) => {
               return {label: chapter.label, ref: chapter.href, subchapter: chapter.subitems};
            });
            setSummary(summary);
            
         });
         const renditions = book.renderTo("area", { 
            manager: "continuous", flow: "paginated", width: "100vw", height: "96vh", snap: true});
         
         
         book.ready.then(() => {
            return book.locations.generate(1024); 
         }).then((locations1: any) => {
            setStartProgress(true);
            start = true;
            setEbook((prev: any) => ({ ...prev, Total_page: `${locations1.length}`}));
            annotations.map((annotation: any) => {
               return createAnnotations(annotation, renditions);
            });
         });

         renditions.on('relocated', (location: any) => {
            if(start){
               setEbook((prev: any) => ({ ...prev, page: `${location.start.cfi}`, Progress: `${(location.start.percentage*100).toFixed(2)}%`, Current_page: `${location.start.location}` }));

            }
         });

         renditions.on('selected', (cfiRange: any, contents: any) => {

            let ranges: any = book.getRange(cfiRange);
            
            if(ranges){
               ranges.then((range: any) => {
                  renditions.annotations.highlight(cfiRange, {},(e: any) => {
                     if(range.toString() != ''){
                        setShowPopoverEdit(!showPopoverEdit);
                        setShowPopoverPublic(false);
                        let an: annotation_interface = {
                           range: range.toString(), 
                           color: '#fbff00ae', 
                           annotation: '', 
                           cfiRange: cfiRange,
                           date: `${moment().format('DD/MM/YYYY')}`,
                           hours: `${moment().format('hh:mm:ss')}`,
                           page: Number(ebook.Current_page),
                           public: false,
                           publication_id: Number(epub.publication_id),
                           type: "highlight"
                        }
                        setAnnotationUpdate(an);
   
                     }
                  }, "", { 'fill': '#fbff00ae',  'fill-opacity': '0.3','mix-blend-mode': 'multiply' });
               })
            }
         });

         renditions.on('click', (e: any) => {
            setShowOffcanvas(true); 
         });
         let displayed: any;
         if(ebook != null){
            displayed = renditions.display(ebook.page ?? undefined);

         }else{
            displayed = renditions.display();

         }
         
         displayed.then(() => {
            
            if(themes){
               renditions.themes.default({
                  "@font-face": fontFamily[themes.font_family],
                  "body": 
                  { 
                     "background": `${themes.background} !important`, 
                     "color": `${themes.color} !important`,
                     "font-family": `${themes.font_family} !important`,
                  }, 
                  "p":
                  { 
                     "margin-left": `${themes.margin}vw !important`,
                     "margin-right": `${themes.margin}vw !important`,
                     "font-family": `${themes.font_family} !important`,
                     "text-align": "start !important"
                  }, 
                  "a":
                  { 
                     "text-align": "start !important",
                     "font-family": `${themes.font_family} !important`,
                     "color": `${themes.color} !important`, 
                  }
               });
               renditions.themes.fontSize(`${themes.font_size}%`);
            }
            props.handleClose();

         })
         
         setBooks(book);
         setRendition(renditions);
      }

      async function getAnnotation() {
         await annotation().getAnnotationByBook(Number(epub.publication_id), {page: 0, pageSize: 20})
         .then((result: any) => {
            
            let tes = result.data.map((e: any) => {
               e.hours = moment(e.date*1000).format('hh:mm:ss');
               e.date = moment(e.date*1000).format('DD/MM/YYYY');
               return e;
            }).sort(function(a: any,b: any) {
               return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
            });
            
            setAnnotations(tes);

         })
      }


      if(props.start){
         getBook();
         getAnnotation();
      }
	}, [epub]);

   const handleCloseSummary = () => {
      setShowOffcanvasSummary(false);
   };
   const handleCloseAnnotations = () => {
      setShowOffcanvasAnnotations(false);
   };

   const handleClose = () => {
      setShowPopoverEdit(false);
      setShowOffcanvas(false);
      setShowPopoverPublic(false);
      setShowOffcanvasAnnotations(false);
      setShowOffcanvasSummary(false);
      setOffCavasheight('6vh');
   };


   const handleShowModal = (annotation: any) => {
      if(!annotation.public){
         setAnnotationUpdate(annotation)
         setShowModal_annotation(true);

      }
   };

   const handleCloseModal = () => {
      setShowModal_annotation(false);
   };
   
   const updateTheme = (theme: theme_interface) => {
            
      if(theme != themes){

         rendition.themes.registerRules("default",
         {
            "@font-face": fontFamily[theme.font_family],
            "body": 
            { 
               "background": `${theme.background} !important`, 
               "color": `${theme.color} !important`,
               "font-family": `${theme.font_family} !important`,
            }, 
            "p":
            { 
               "margin-left": `${theme.margin}vw !important`,
               "margin-right": `${theme.margin}vw !important`,
               "font-family": `${theme.font_family} !important`,
               "text-align": "start !important"
            }, 
            "a":
            { 
               "text-align": "start !important",
               "font-family": `${theme.font_family} !important`,
               "color": `${theme.color} !important`, 
            }
         });
         rendition.themes.font(`${theme.font_family}`);
         rendition.themes.override("line-height", theme.line_height, true); 
         rendition.themes.fontSize(`${theme.font_size}%`);
         setThemes(theme);

         annotations.map((a: any) => {
            return updateAnnotations(a, false);
         })
      }
   }

   function go_back() {
      return props.history.goBack();
   }

   const handleClick2 = (event: any) => {
      setShowPopoverEdit(!showPopoverEdit);
      setShowPopoverPublic(!showPopoverPublic);
    };

   const loadAnnotations = (data: any, renditions: any) => {

   }

   const createAnnotations = (data: any, renditions: any) => {

      renditions.annotations.highlight(data.cfiRange, data, (e: any) => {
         if(!data.public){

            setShowPopoverEdit(!showPopoverEdit);
         }else{
            setShowPopoverPublic(!showPopoverPublic);
         }
         let an: annotation_interface = {
            id: data.id, 
            range: data.range, 
            color: data.color, 
            annotation: data.annotation, 
            cfiRange: data.cfiRange,
            date: data.date,
            hours: data.hours,
            page: Number(data.page),
            public: data.public,
            publication_id: Number(data.publication_id),
            type: data.type
         }
         setAnnotationUpdate(an);
      }, "", { 'fill': `${data.color}`,  'fill-opacity': '0.3','mix-blend-mode': 'multiply' });
      

   }

   const removeAnnotations = (type: string) => {
      
      let res = rendition.annotations.remove(annotationUpdate.cfiRange, type);
      setShowPopoverEdit(false);
      setShowOffcanvas(false);
      setShowPopoverPublic(false);
      setShowOffcanvasSummary(false);
      setOffCavasheight('6vh');
   }

   const updateAnnotations = async (data: any, hidden: boolean = true)  => {
      
      await rendition.annotations.remove(data.cfiRanges, data.type);
      createAnnotations(data, rendition);
      
      let anno = annotations.map((a: any) => {
         if(a.id === data.id){
            return data;
         }else{
            return a;

         }
      });

      setAnnotations(anno);

      if(hidden){
         setShowPopoverEdit(false);
         setShowOffcanvas(false);
         setShowPopoverPublic(false);
         setShowOffcanvasSummary(false);
         setOffCavasheight('6vh');

      }
   }

   const selectPage = (page: string) => {
      console.log(page);
      
      rendition.display(page);
      setShowPopoverEdit(false);
      setShowOffcanvas(false);
      setShowOffcanvasAnnotations(false);

      setShowOffcanvasSummary(false);
      setShowOffcanvas(false)
      setOffCavasheight('6vh')
   }
   

   return (
      <Reader_epubs>
         <Offcanvasheader show={showOffcanvas} placement='top'>
            <Offcanvas.Body>
               <Row>
                  <Col xs="2" sm="2">
                     <Button variant="" className="button_goback" onClick={go_back}>
                        <FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" />
                     </Button>
                  </Col>
                  <Col xs="6" sm="6"></Col>
                  <Col xs="4" sm="4">
                     <Row>
                        <Col>
                           <Button variant="light" className="btn-menu" onClick={() => setShowOffcanvasSummary(true)}>
                              <FontAwesomeIcon icon={faBars} className="fa-lg" />
                           </Button>
                        </Col>
                        <Col>
                           <Button variant="light" className="btn-menu" onClick={() => setShowOffcanvasAnnotations(true)}>
                              <FontAwesomeIcon icon={faBookmark} className="fa-lg" />
                           </Button>
                     </Col>
                     </Row>
                  </Col>
               </Row>
            </Offcanvas.Body>
         </Offcanvasheader>
         {(!showPopoverEdit) ?
         <Overlay 
            show={showPopoverPublic}
            placement="auto-end"
            target={target}
            container={ref.current}
            containerPadding={20}>
            <Popover id="popover-basic">
               <Popover.Body className="text-center">
                  <a>
                     <FontAwesomeIcon icon={faEye} className="fa-lg" /> 
                     <p className="m-0">Visualizar</p>
                  </a>
               </Popover.Body>
            </Popover>
         </Overlay>
         :  ""
         }
         <Overlay 
            show={showPopoverEdit}
            placement="auto-end"
            target={target}
            container={ref.current}
            containerPadding={20}>
            <Popover id="popover-basic">
               <Popover.Body className="text-center">
                  <Row>
                     <Col xs="4" sm="4">
                        <a>
                           <FontAwesomeIcon icon={faHighlighter} className="fa-lg" /> 
                           <p className="m-0">Marcar</p>
                        </a>
                     </Col>
                     <Col xs="4" sm="4">
                        <a onClick={(event: any) => {setShowModal_annotation(true); setShowPopoverEdit(!showPopoverEdit); setShowPopoverPublic(false);}}>
                           <FontAwesomeIcon icon={faBookmark} className="fa-lg" />
                           <p className="m-0">Nota</p>
                        </a>
                     </Col>
                     <Col xs="4" sm="4">
                        <a onClick={(event: any) => { removeAnnotations("highlight") }}>
                           <FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
                           <p className="m-0">Excluir</p>
                        </a>
                     </Col>
                  </Row>
               </Popover.Body>
            </Popover>
         </Overlay>
         <div  ref={ref}  style={{ ...props.style, backgroundColor: `${(themes.background ?? '#fff')}`}} id="area"></div>
         
         <footer className="pt-4" style={{ ...props.style, backgroundColor: `${(themes.background ?? '#fff')}`, color: `${(themes.background == '#000') ? '#fff' :  '#000' }`}} >
            {(startProgress) ? 
            
               <Row>
                  <Col xs="6" sm="6">
                     <div className="ml-2">
                        {`Página ${ebook.Current_page} de ${ebook.Total_page}`}
                     </div>
                  </Col>
                  <Col xs="3" sm="3"></Col>
                  <Col xs="3" sm="3">
                     <div className="ml-2">
                        {`${ebook.Progress}`}
                     </div>
                  </Col>
               </Row>
            : 
            <div className="ml-2">
               Carregando dados...
            </div> }
         </footer>
         <OffcanvasSummary show={showOffcanvasSummary} onHide={handleCloseSummary} placement='end' >
            <Offcanvas.Header closeButton>
               <Offcanvas.Title>Sumario</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="mt-3" >
               {summary.map((chapter: any, id: any) => {
                  return <Buttons_summary selectPage={selectPage} chapter={chapter} id={id} />
               })}
            </Offcanvas.Body>
               
         </OffcanvasSummary>
         <OffcanvasSummary show={showOffcanvasAnnotations} onHide={handleCloseAnnotations} placement='end' >
            <Offcanvas.Header closeButton>
               <Offcanvas.Title>Anotações</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body >
               {annotations.map((annotation: any) => (
                   <Card_annotation annotations={annotation} key={annotation.id} selectPage={selectPage} handleShowModal={handleShowModal} font_family={themes.font_family} />
                  )
               )}
            </Offcanvas.Body>
         </OffcanvasSummary>
         <Offcanvas2 show={showOffcanvas} onHide={handleClose} placement='bottom' height={offCavasheight} >
            <Accordion flush>
               <Accordion.Item eventKey="0" >
                  <Accordion.Header onClick={(event: any) => {
                     (offCavasheight == '70vh') ? setOffCavasheight('6vh')
                     :
                     setOffCavasheight('70vh')
                  }} >Configurações</Accordion.Header>
                  {(offCavasheight != '70vh') ? ''
                  :
                  <>
                     <Accordion.Body>
                        <Config_epub theme={themes} setTheme={updateTheme}  />
                     </Accordion.Body>
                  </>
                   }
               </Accordion.Item>
            </Accordion>
         </Offcanvas2>
         
         <Modal_annotation show={showModal_annotation} annotation={annotationUpdate} font_family={themes.font_family}  updateAnnotations={updateAnnotations} book={ebook} create={createAnnotations} handleClose={handleCloseModal} />
      </Reader_epubs>
   )
}