import styled from 'styled-components';

export const Registers = styled.div`

.chooseFileButton{
   display: inline-block;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   border-radius: 0.25rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   color: #fff;
   background-color: #6c757d;
   border-color: #6c757d;
}
.chooseFileButton:hover {
    background: #6c757d;
}
.filepond--credits{
   visibility: hidden;
}
.filepond--image-preview-overlay-success{
   visibility: hidden;
}
.filepond--wrapper{
   margin-top: -5% !important;
}

.contAreatext {
   font-size: 12px;
   color: #706F6F;
}
`;

export const Fabs = styled.div`
position: fixed;
bottom:0px;
left:0px;
right:0px;
height:50px;
margin-bottom:5vh;
margin-right:5vw;
text-align: -webkit-right;
.icon_fab{
   color: ${props => props.theme.colors.text_color_secondary};
}
`;