import styled from 'styled-components';

export const Divs = styled.div`

background-color: #EAEAEA !important;
.div_signin{
   background-color: ${props => props.theme.colors.secondary} !important;
   color: ${props => props.theme.colors.text_color_secondary} !important;
}
p{
   font-size: 12px;
}
.btn-light{
   color: ${props => props.theme.colors.secondary} !important;
}
`;
export const Spinners = styled.div`
.spinner-border {
   width: 2rem;
   height: 2rem;
   color: ${props => props.theme.colors.primary} !important;
}
`;