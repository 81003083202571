import styled from 'styled-components';
import { Offcanvas } from 'react-bootstrap';
export const Reader_epubs = styled.div`

.popover{
   width: 350px !important;
}

footer{
   bottom: 0;
   width: 100%;
   position: fixed;
   font-size: 12px !important;
   .ml-2{
      margin-left: 2vh !important;
   }
}
`;
export const OffcanvasSummary = styled(Offcanvas)`
background-color: #EAEAEA !important;
.offcanvas-body {
   background-color: #EAEAEA !important;
   padding: 0rem 0rem !important;
}
.offcanvas-header{
   
   background-color: ${props => props.theme.colors.primary } !important;
}
.btn_summary{
   
   color: ${props => props.theme.colors.text_color_primary } !important;
   width: 90% !important;
   display: block;
   font-size: 1rem !important;
   white-space: nowrap !important;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
   
   a{
      padding-left: 4vw !important;
      padding-right: 4vw !important;
   }
}
`;

export const Offcanvas2 = styled(Offcanvas)`
height: ${props => props.height} !important;   
 
margin-bottom: ${props => (props.height == '70vh') ? '-14vh' : '0px'} !important;  

.accordion-header{
   button{
   background-color: ${props => props.theme.colors.primary } !important;
   color: ${props => props.theme.colors.text_color_primary } !important;

   }
}

.accordion-body{
   background-color: #EAEAEA !important;
   margin-bottom: -1.5rem !important;
}
`;

export const Offcanvasheader = styled(Offcanvas)`
height: 70px !important;
background:  rgba(0, 0, 0, 0.001);
border: none;
   .row{
      margin: 0mm !important;
   }
   .row > * {
      padding: 0mm !important;
   }
.btn-menu{
   margin-right: 2vw !important;
   margin-top: 2vh !important;
   background-color: ${props => props.theme.colors.primary} !important;
   color: ${props => props.theme.colors.text_color_primary} !important;
   border-color: ${props => props.theme.colors.primary} !important;
}
.button_goback {

   background-color: ${props => props.theme.colors.primary} !important;
   color: ${props => props.theme.colors.text_color_primary} !important;
   margin-left: 2vw !important;
   margin-top: 1vh !important;
   border-radius: 50% !important;
   height: 50px !important;
   width: 50px !important;
   opacity: 1 !important;
}
.offcanvas-body{
   padding: 5px !important;
}

`;