import React from 'react';
import { Card } from 'react-bootstrap';
import { Link  } from 'react-router-dom';

import Image_loading from '../image_loading';

export default function Card_public(props: any) {

   let publication = props.publication;
   
   return (
      <Card className="justify-content-center align-items-center" key={props.key} >
         <Link to={`/publication/${publication.id}`} title={publication.title}>
            <Image_loading image={publication.cover} className="card-img" width={props.width} height={props.height} alt={`Capa do ${publication.title} de ${publication.author.name} `} />
         </Link>
      </Card>
   )
}