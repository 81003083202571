import React from 'react';
import { Card_pushases } from './styles';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { useHistory  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faBookReader, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import fileDownload from 'js-file-download';

import licenses from '../../services/licenses';

import Image_loading from '../image_loading';
 
export default function Card_puchases(props: any) {

   let license = props.license;
   let type= '';
   let history = useHistory();
   
   const getlicense = () => {
      props.funShow();
      licenses().register(license.uuid)
      .then((results: any) => {
         fileDownload(JSON.stringify(results.data), `${license.publication.title}.lcpl`);
         props.funInShow();
      })
      .catch((error: any) => {
        
      })
   }

   const openReader = (publication: any) => {
      
      history.push({
         pathname: '/publications/reader',
         state: publication
      })
   }

   if( license.type === 'BUY'){ 
      type = 'compra';
      
      return (
         <Card_pushases>
            <Card className="mt-2 mb-2" key={`${props.key}`} body >
               <Row>
                  <Col sm={6} xs={6} md={6}>
                     <Image_loading image={license.publication.cover} className="card-img-1" width={140} height={170} alt={`Capa do ${license.publication.title} de ${license.publication.author.name} `} />
                  </Col>
                  
                  <Col sm={6} xs={6} md={6}>
                     <h3>{license.publication.title}</h3>
                     <h6>{license.publication.author.name}</h6>
                     <div>
                     <Badge className="bg-secondary" key={`${type}-${license.id}`}>{`${type}`}</Badge>
                     <Badge className="bg-secondary ml-2" >{license.status}</Badge>

                     </div>
                     <div className="text-right pt-4 mt-2">
                        <Button variant="secondary" size="sm" onClick={() => history.push(`/license/${license.publication.id}`)} type="button"> Download da licença  <FontAwesomeIcon icon={faDownload} className="icon_fab" /></Button>

                     </div>
                  </Col>
               </Row>
            </Card>
         </Card_pushases>
      )
   }else { 
      type = 'Empréstimo';
      
      return (
         <Card_pushases>
            <Card className="mt-2 mb-2" key={`${props.key}-${license.id}`} body >
               <Row>
                  <Col sm={6} xs={6} md={6}>
                     <Image_loading image={license.publication.cover} className="card-img-1" width={140} height={170} alt={`Capa do ${license.publication.title} de ${license.publication.author.name} `} />
                  </Col>
                  
                  <Col sm={6} xs={6} md={6}>
                     <h3>{license.publication.title}</h3>
                     <h6>{license.publication.author.name}</h6>
                     <h6>Devolução: {moment(license.endDate).utc().format('DD/MM/YYYY')}</h6>
                     <h6>Data limite: {moment(license.maxEndDate).utc().format('DD/MM/YYYY')}</h6>
                     <div>
                     <Badge className="bg-secondary" key={`${type}-${license.id}`}>{`${type}`}</Badge>
                     <Badge className="bg-secondary ml-2" >{license.status}</Badge>

                     </div>
                  </Col>
                  <Col>
                     <div className="mt-2 text-center">
                        <Button variant="secondary" size="sm" className=" m-2"
                           onClick={event => openReader(license)} type="button"> <FontAwesomeIcon icon={faBookReader} className="icon_fab" /><br /> ler agora
                        </Button>
                        <Button variant="secondary" size="sm" className=" m-2"
                           onClick={getlicense} type="button"> <FontAwesomeIcon icon={faDownload} className="icon_fab" /><br /> baixar
                        </Button>
                        <Button variant="danger" size="sm" className=" m-2"
                           onClick={getlicense} type="button"><FontAwesomeIcon icon={faRedoAlt} className="icon_fab" /><br /> renovar
                        </Button>
                     </div>
                  </Col>
               </Row>
            </Card>
         </Card_pushases>
      )
   }
}