import React, { useState, useEffect, useContext } from 'react';
import { ButtonGroup, ToggleButton, Accordion, Form, Row, Col } from 'react-bootstrap';
import { Range } from 'react-range';

import { Config_epubs } from './styles';
import { ThemeContext } from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';

import theme_interface from '../../interfaces/theme_epub_interface';


export default function Config_epub(props: any) {

   let t: theme_interface = {
      background: "#eee5de",
      color: "#000",
      font_size: 150,
      font_transform: '',
      font_family: 'sans-serif',
      line_height: 1,
      margin: 0
   }
   const [ themes, setThemes] = useState<theme_interface>((props.theme ?? t));
   const theme = useContext(ThemeContext);


   const radios = [
      { name: 'Claro', value: '[ "#fff", "#000" ]', variant:"light" },
      { name: 'Padrão', value: '["#eee5de", "#000"]', variant:"info" },
      { name: 'Escuro', value: '[ "#000", "#ffffff"]', variant:"dark" },
   ];
   

	useEffect(() => {      
      props.setTheme(themes);
	}, [themes]);


   return (
      <Config_epubs className="text-center">
         <ButtonGroup className="text-center">
           {radios.map((radio, idx) => (
             <ToggleButton
               size="lg"
               key={idx}
               id={`radio-${idx}`}
               type="radio"
               variant={radio.variant}
               name="radio"
               value={radio.value}
               checked={`["${themes.background}", "${themes.color}" ]` === radio.value}
               onChange={(e: any) => {
                  let color = JSON.parse(e.currentTarget.value);
                  setThemes((prev: any) => ({ ...prev, background: color[0], color: color[1] }));
               }}
             >
             </ToggleButton>
           ))}
         </ButtonGroup>
         <Row className="">
            <Col xs="6" lg="6">
               <p className="mt-3">Tamanho</p>
               <Range
                  step={10}
                  min={150}
                  max={250}
                  values={[(themes.font_size ?? 150)]}
                  onChange={(values) =>{ setThemes((prev: any) => ({ ...prev, font_size: values[0] }))}}
                  renderTrack={({ props, children }) => (
                     <div
                        {...props}
                        style={{
                           ...props.style,
                           height: '25px',
                           width: '85%',
                           marginLeft: '2vh',
                           marginRight: '2vh',
                           borderTopLeftRadius: '14px',
                           borderBottomLeftRadius: '14px',
                           borderTopRightRadius: '14px',
                           borderBottomRightRadius: '14px',
                           backgroundColor: `${theme.colors.primary}`
                        }}
                     >
                        {children}
                        {/* <FontAwesomeIcon icon={faAngleLeft} className="icon-navbar fa-lg" /> */}
                     </div>
                  )}
                  renderThumb={({ props }) => (
                     <div
                        {...props}
                        style={{
                           ...props.style,
                           height: '25px',
                           width: '25px',
                           borderRadius: '50%',
                           backgroundColor: `${theme.colors.secondary}`
                        }}
                     />
                  )}
               />
               <p className="mt-3">Margem</p>
               <Range
                  step={0.0125}
                  min={0}
                  max={2.0}
                  values={[(themes.margin ?? 0)]}
                  onChange={(values) =>{ setThemes((prev: any) => ({ ...prev, margin: values[0] }))}}
                  renderTrack={({ props, children }) => (
                     <div
                        {...props}
                        style={{
                           ...props.style,
                           height: '25px',
                           width: '85%',
                           marginLeft: '2vh',
                           marginRight: '2vh',
                           borderTopLeftRadius: '14px',
                           borderBottomLeftRadius: '14px',
                           borderTopRightRadius: '14px',
                           borderBottomRightRadius: '14px',
                           backgroundColor: `${theme.colors.primary}`
                        }}
                     >
                        {children}
                     </div>
                  )}
                  renderThumb={({ props }) => (
                     <div
                        {...props}
                        style={{
                           ...props.style,
                           height: '25px',
                           width: '25px',
                           borderRadius: '50%',
                           backgroundColor: `${theme.colors.secondary}`
                        }}
                     />
                  )}
               />
            </Col>
            <Col xs="6" lg="6">
               <p className="mt-3">Linhas</p>
               <Range
                  step={0.5}
                  min={1}
                  max={5}
                  values={[(themes.line_height ?? 1)]}
                  onChange={(values) =>{ setThemes((prev: any) => ({ ...prev, line_height: values[0] }));}}
                  renderTrack={({ props, children }) => (
                     <div
                        {...props}
                        style={{
                           ...props.style,
                           height: '25px',
                           width: '85%',
                           marginLeft: '2vh',
                           marginRight: '2vh',
                           borderTopLeftRadius: '14px',
                           borderBottomLeftRadius: '14px',
                           borderTopRightRadius: '14px',
                           borderBottomRightRadius: '14px',
                           backgroundColor: `${theme.colors.primary}`
                        }}
                     >
                        {children}
                     </div>
                  )}
                  renderThumb={({ props }) => (
                     <div
                        {...props}
                        style={{
                           ...props.style,
                           height: '25px',
                           width: '25px',
                           borderRadius: '50%',
                           backgroundColor: `${theme.colors.secondary}`
                        }}
                     />
                  )}
               />
               
               <Form.Label className="mt-3">Fontes</Form.Label>
               <Form.Control as="select" className="form-select"
                  defaultValue={themes.font_family}
                  onChange={event => setThemes((prev: any) => ({ ...prev, font_family: event.target.value }))} required>
                  <option value="null" disabled >Selecione</option>
                  <option value="Roboto"  >Roboto</option>
                  <option value="OpenDyslexic" >Open Dyslexic</option>
                  <option value="Georama">Georama</option>
                  <option value="PT_Serif" >PT Serif</option>
               </Form.Control>
            </Col>
            
            <Form.Group controlId="formBasicType">
               <Form.Control.Feedback type="invalid">
                  Please choose a type.
               </Form.Control.Feedback>
            </Form.Group>
         </Row>
      </Config_epubs>
   )
}