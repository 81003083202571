import React, { useState, useEffect } from 'react';
import Header from '../Header';
import auth from '../../services/auth';

import Loading from '../../components/loading';
import { Divterms, Divreview } from './styles';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { Card, Container, Row, Alert, Form, Button } from 'react-bootstrap';

import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginImageEdit, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize)

export default function Terms(props: any) {

   const [user, setUser] = useState(props.history.location.state);
   const [show, setShow] = useState(false);
   const [open, setOpen] = useState(false);
   const [isLoading, setLoading] = useState(false);
   const [error, setError] = useState('');
   let history = useHistory();
   const mark_celular = '(99) 99999-9999';
   const mark_cpf_cnpj = (process.env.REACT_APP_SIGLA == 'PUB ') ? '99.999.999/9999-99' : '999.999.999-99';
   const placeholder_cpf_cnpj = (process.env.REACT_APP_SIGLA == 'PUB ') ? 'XX.XXX.XXX/0001-XX' : 'XXX.XXX.XXX-XX';
   const [disabled, setDisabled] = useState('secondary disabled');




   const handleScroll = () => {
      const position = window.pageYOffset;
      const limit = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      if (position === limit) {
         setDisabled('secondary');
      } else {
         setDisabled('secondary disabled');
      }
   };

   const checkTerms = () => setOpen(true);

   const goBack = () => {
      setOpen(false);

   }

   const createUser = () => {
      setLoading(true);
      setShow(true);
      setError("");
      user.celular = validatePhone(user.celular);
      user.cpf_cnpj = validarCPF_CNPJ(user.cpf_cnpj);
      if (user.cpf_cnpj === '') {
         setError("Valide o CNPJ!");

      } else {
         if (user.celular === '') {
            setError("Valide o Celular!");
         } else {
            auth().register(user)
               .then(function (response: any) {
                  setShow(false);
                  setLoading(false);
                  history.push({
                     pathname: '/conclusion',
                     state: {
                        type: 'create', 
                        name: user.name, 
                        email: user.email
                     }
                  });
               })
               .catch(function (errors: any) {
                  history.push("/register");
                  setShow(false);
                  setLoading(false);
               });
         }

      }
   };

   function validarCNPJ(cnpj: any) {

      let cnpj2: any = cnpj.replace(/[^\d]+/g, '');

      if (cnpj2 == '') {
         setError("CNPJ ainda não preenchido!");
         return false;
      }

      if (cnpj2.length != 14) {
         setError("CNPJ ainda não preenchido!");
         return false;
      }

      // Elimina CNPJs invalidos conhecidos
      if (cnpj2 == "00000000000000" ||
         cnpj2 == "11111111111111" ||
         cnpj2 == "22222222222222" ||
         cnpj2 == "33333333333333" ||
         cnpj2 == "44444444444444" ||
         cnpj2 == "55555555555555" ||
         cnpj2 == "66666666666666" ||
         cnpj2 == "77777777777777" ||
         cnpj2 == "88888888888888" ||
         cnpj2 == "99999999999999") {
         setError("CNPJ invalido!");
         return false;
      }

      // Valida DVs
      let tamanho: number = cnpj2.length - 2;
      let numeros: any = cnpj2.substring(0, tamanho);
      let digitos: any = cnpj2.substring(tamanho);
      let soma: any = 0;
      let pos: number = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
         soma += numeros.charAt(tamanho - i) * pos--;
         if (pos < 2)
            pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) {
         setError("CNPJ invalido!");
         return false;

      }

      tamanho = tamanho + 1;
      numeros = cnpj2.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
         soma += numeros.charAt(tamanho - i) * pos--;
         if (pos < 2)
            pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) {
         setError("CNPJ invalido!");
         return false;

      }

      return true;

   }

   function validarCPF(strCPF: any) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
   }

   function validatePhone(celular: any) {
      if (celular.length != 15) {
         setError("celular ainda não preenchido!");
         return false;
      }

      if (celular == "(00) 00000-0000" ||
         celular == "(11) 11111-1111" ||
         celular == "(22) 22222-2222" ||
         celular == "(33) 33333-3333" ||
         celular == "(44) 44444-4444" ||
         celular == "(55) 55555-5555" ||
         celular == "(66) 66666-6666" ||
         celular == "(77) 77777-7777" ||
         celular == "(88) 88888-8888" ||
         celular == "(99) 99999-9999") {
         setError("celular invalido!");
         return props.history.location.state.celular;
      }
      return celular;

   }

   function validarCPF_CNPJ(strCPF: any) {
      if (process.env.REACT_APP_SIGLA == 'PUB ') {
         return (validarCNPJ(strCPF)) ? strCPF.replace(/[^\d]+/g, '') : props.history.location.state.cpf_cnpj;
      } else {
         return (validarCPF(strCPF.replace(/[^\d]+/g, ''))) ? strCPF.replace(/[^\d]+/g, '') : props.history.location.state.cpf_cnpj;
      }
   }


   useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);


   if (!open) {
      return (
         <>
            <Header title="Termos" />
            <Container >
               <Row className="justify-content-md-center">
                  <Card className="card-body mt-5 mb-5" >
                     <a href='/pdf/POLITICAS_E_TERMOS_DE_USO_Leitura.tech.pdf' target="_blank">POLITICAS E TERMOS DE USO</a>
                  </Card>

               </Row>

            </Container>
            {(user) ? 
            <Divterms className="d-grid gap-2 justify-content-md-center" >
               <Button variant='secondary' size="lg" type="button" onClick={checkTerms} >CONCORDO</Button>
            </Divterms> : ''}
         </>
      );
   } else {
      if (process.env.REACT_APP_SIGLA === 'PUB ') {

         return (
            <Divreview>
               <Header title="Revisão dos Dados" go_back={goBack} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form >
                           <Form.Group controlId="formBasicName">
                              <Form.Label className="mt-2">Nome Completo</Form.Label>
                              <Form.Control type="text" aria-required="true" placeholder="João Silva"
                                 value={user.name}
                                 onChange={event => setUser((prev: any) => ({ ...prev, name: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a name.
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group controlId="formBasicEmail">
                              <Form.Label className="mt-2">Email</Form.Label>
                              <Form.Control type="email" aria-required="true" placeholder="seuemail@dominio.com"
                                 value={user.email}
                                 onChange={event => setUser((prev: any) => ({ ...prev, email: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a username.
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group controlId="formBasicCelular">
                              <Form.Label className="mt-2">Celular</Form.Label>
                              <InputMask className="form-control" aria-required="true" mask={mark_celular} placeholder="(11) 99999-9999"
                                 value={user.celular}
                                 onChange={event => setUser((prev: any) => ({ ...prev, celular: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a celular.
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group controlId="formBasicRazao_social">
                              <Form.Label className="mt-2">Razão Social</Form.Label>
                              <Form.Control type="text" placeholder="João Silva" aria-required="true"
                                 value={user.razao_social}
                                 onChange={event => setUser((prev: any) => ({ ...prev, razao_social: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Razão Social.
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group controlId="formBasicNome_fantasia">
                              <Form.Label className="mt-2">Nome Fantasia</Form.Label>
                              <Form.Control type="text" placeholder="João Silva" aria-required="true"
                                 value={user.nome_fantasia}
                                 onChange={event => setUser((prev: any) => ({ ...prev, nome_fantasia: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Nome Fantasia.
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group controlId="formBasicDescription">
                              <Form.Label className="mt-2">Descrição</Form.Label>
                              <Form.Control as="textarea" maxLength={2000} rows={5} aria-required="true" placeholder="João Silva"
                                 value={user.description}
                                 onChange={event => setUser((prev: any) => ({ ...prev, description: event.target.value }))} required />
                              <p className="text-right contAreatext">{2000 - Number(user.description.length)}/2000</p>
                              <Form.Control.Feedback type="invalid">
                                 Please choose a Nome Fantasia.
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group controlId="formBasicCpf_cnpj">
                              <Form.Label className="mt-2">CNPJ</Form.Label>
                              <InputMask className="form-control" aria-required="true" mask={mark_cpf_cnpj} placeholder={placeholder_cpf_cnpj}
                                 value={user.cpf_cnpj}
                                 onChange={(event: any) => setUser((prev: any) => ({ ...prev, cpf_cnpj: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a name.
                              </Form.Control.Feedback>
                           </Form.Group>
                        </Form>
                        <div className="mt-4">
                           {error &&
                              <div className="text-center">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                           }
                           <div className="text-center">
                              <Button variant={disabled} size="lg" type="button"
                                 onClick={!isLoading ? createUser : undefined}
                                 disabled={isLoading}>
                                 {isLoading ? 'Carregando...' : 'REGISTRAR'}</Button>

                           </div>
                        </div>
                     </Card>

                  </Row>

               </Container>
               <Loading show={show} />
            </Divreview >
         );
      } else {

         return (
            <Divreview>
               <Header title="Revisão dos Dados" go_back={goBack} />
               <Container >
                  <Row className="justify-content-md-center">
                     <Card className="card-body mt-5 mb-5" >
                        <Form >
                           <Form.Group controlId="formBasicName">
                              <Form.Label className="mt-2">Nome Completo</Form.Label>
                              <Form.Control type="text" aria-required="true" placeholder="João Silva"
                                 value={user.name}
                                 onChange={event => setUser((prev: any) => ({ ...prev, name: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a name.
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group controlId="formBasicEmail">
                              <Form.Label className="mt-2">Email</Form.Label>
                              <Form.Control type="email" aria-required="true" placeholder="seuemail@dominio.com"
                                 value={user.email}
                                 onChange={event => setUser((prev: any) => ({ ...prev, email: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a username.
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group controlId="formBasicCelular">
                              <Form.Label className="mt-2">Celular</Form.Label>
                              <InputMask className="form-control" aria-required="true" mask={mark_celular} placeholder="(11) 99999-9999"
                                 value={user.celular}
                                 onChange={event => setUser((prev: any) => ({ ...prev, celular: event.target.value }))} required />
                              <Form.Control.Feedback type="invalid">
                                 Please choose a celular.
                              </Form.Control.Feedback>
                           </Form.Group>
                           
                           <Form.Group controlId="formBasicDescription">
                              <Form.Label className="mt-2">{(process.env.REACT_APP_SIGLA == 'PUB ') ? 'CNPJ' : 'CPF'} </Form.Label>
                              <p>
                                 <InputMask className="form-control" aria-required="true" mask={mark_cpf_cnpj} placeholder={placeholder_cpf_cnpj}
                                 value={user.cpf_cnpj}
                                 onChange={(event: any) => setUser((prev: any) => ({ ...prev, cpf_cnpj: event.target.value }))} />
                              </p>
                           </Form.Group>
                           <Form.Group>
                              <Form.Label className="mt-2">SEU AVATAR</Form.Label>
                              <FilePond
                                 files={[user.image]}
                                 onaddfile={(error: any, file: any) => {
                                    // Set currently active file objects to this.state
                                    if (error) {
                                       setError(error.main)
                                    } else {
                                       setUser((prev: any) => ({ ...prev, image: `data:${file.fileType};base64,${file.getFileEncodeBase64String()}` }));
                                       setError('');
                                    }
                                 }}
                                 onremovefile={(error: any, file: any) => {
                                    setError('')
                                    setUser((prev: any) => ({ ...prev, image: `` }));
                                 }}
                                 allowReorder={true}
                                 allowImagePreview={true}
                                 allowImageExifOrientation={true}
                                 allowFileEncode={true}
                                 allowMultiple={false}
                                 maxFiles={1}
                                 allowFileTypeValidation={true}
                                 acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml']}
                                 fileValidateTypeLabelExpectedTypesMap={[
                                    { 'image/png': '.png' },
                                    { 'image/jpeg': '.jpeg' },
                                    { 'image/jpg': '.jpg' },
                                    { 'image/svg+xml': '.svg' }
                                 ]}
                                 className="mt-3"
                                 fileValidateTypeLabelExpectedTypes={''}
                                 allowFileSizeValidation={true}
                                 maxFileSize='500000'
                                 required
                                 labelMaxFileSizeExceeded="capa muito grande"
                                 labelMaxFileSize="maximo aceitavel {filesize}"
                                 labelFileTypeNotAllowed='FORMATO NÃO SUPORTADO'
                                 labelFileProcessing='CARREGANDO...'
                                 labelFileProcessingComplete='COMPLETADO'
                                 labelFileProcessingAborted='ERRO NO UPLOAD'
                                 labelTapToUndo='TOQUE PARA DESFAZER'
                                 labelTapToCancel='TOQUE PARA CANCELAR'
                                 labelButtonRemoveItem='REMOVER'
                                 labelButtonAbortItemProcessing='CANCELAR'

                                 labelIdle='TAMANHO MAXIMO: 500KB;<br> <span class="filepond--label-action">SELECIONAR SEU AVATAR</span>'
                              />
                           </Form.Group>
                        </Form>
                        <div className="mt-2">
                           {error &&
                              <div className="text-center">
                                 <Alert variant="danger" dismissible>{error}</Alert>
                              </div>
                           }
                           <div className="text-center">
                              <Button variant={disabled} size="lg" type="button"
                                 onClick={!isLoading ? createUser : undefined}
                                 disabled={isLoading}>
                                 {isLoading ? 'Carregando...' : 'REGISTRAR'}
                              </Button>
                           </div>
                        </div>
                     </Card>

                  </Row>

               </Container>
               <Loading show={show} />
            </Divreview >
         );
      }
   }
}