import axios from 'axios';
import auth from './auth';

export default function publications() {
   let base_url = process.env.REACT_APP_BASE_URL;

   let publications = {
      all: function all(header: any): any {
         
         return axios.get(`${base_url}publications`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               },
               params: {
                  page: (header.page) ? header.page : null,
                  pageSize: (header.pageSize) ? header.pageSize : null,
                  author: (header.author) ? header.author : null,
                  org: (header.org) ? header.org : null,
                  title: (header.title) ? header.title : null,
                  // buy, loan, public ou self
                  bucket: (header.bucket) ? header.bucket : null
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      recent: function recent(header: any): any {
         return axios.get(`${base_url}publications/recent`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8'
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      get: function get(header: any, id: number): any {

         return axios.get(`${base_url}publications/${id}`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Expose-Headers': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               }
            })
            .then(function (response) {
               return response.data;

            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      getcover: function getcover(url: string): any {

         return axios.get(`${url}`,
            {
               headers: {
                  'Accept': '*/*',
                  'Access-Control-Allow-Origin': '*'
               }
            })
            .then(function (response) {
               return response;

            })
            .catch(function (error) {
               console.log(error);
            });
      },

      getOrg: function getOrg(header: any, id: number): any {

         return axios.get(`${base_url}v1/publications/${id}`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            })
            .then(function (response) {
               return response.data;

            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      getLoan: function getLoan(header: any, id: string): any {

         return axios.get(`${base_url}publications/loan/${id}`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      indexLoans: function indexLoans(header: any): any {

         return axios.get(`${base_url}publications/loan`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               },
               params: {
                  page: (header.page) ? header.page : null,
                  pageSize: (header.pageSize) ? header.pageSize : null,
                  author: (header.author) ? header.author : null,
                  org: (header.org) ? header.org : null,
                  title: (header.title) ? header.title : null,
                  // buy, loan, public ou self
                  bucket: (header.bucket) ? header.bucket : null
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      indexBuy: function indexBuy(header: any): any {

         return axios.get(`${base_url}publications/buy`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               },
               params: {
                  page: (header.page) ? header.page : null,
                  pageSize: (header.pageSize) ? header.pageSize : null,
                  author: (header.author) ? header.author : null,
                  org: (header.org) ? header.org : null,
                  title: (header.title) ? header.title : null,
                  // buy, loan, public ou self
                  bucket: (header.bucket) ? header.bucket : null
               }
            })
            .then(function (response) {
               return response.data;
            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      indexSelf: function indexSelf(header: any): any {

         return axios.get(`${base_url}publications/buy`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
            params: {
               page: (header.page) ? header.page : null,
               pageSize: (header.pageSize) ? header.pageSize : null,
               author: (header.author) ? header.author : null,
               org: (header.org) ? header.org : null,
               title: (header.title) ? header.title : null,
               // buy, loan, public ou self
               bucket: (header.bucket) ? header.bucket : null
            }
         })
         .then(function (response) {
            return response.data;
         })
         .catch(function (error) {
            console.log(error);
            return [];
         });
      },

      indexOrg: function indexOrg(header: any): any {

         return axios.get(`${base_url}v1/org/publications`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
            params: {
               page: (header.page) ? header.page : null,
               pageSize: (header.pageSize) ? header.pageSize : null,
               author: (header.author) ? header.author : null,
               org: (header.org) ? header.org : null,
               title: (header.title) ? header.title : null,
               // buy, loan, public ou self
               bucket: (header.bucket) ? header.bucket : null
            }
         })
         .then(function (response) {
            return response.data;
         })
         .catch(function (error) {
            console.log(error);
            return [];
         });
      },

      
      indexPuchases_loan: function indexPuchases_loan(header: any): any {

         return axios.get(`${base_url}v1/users/purchases`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json;charset=UTF-8',
               'Authorization': `Bearer ${auth().getToken()}`
            },
            params: {
               page: (header.page) ? header.page : null,
               pageSize: (header.pageSize) ? header.pageSize : null,
               type: (header.type) ? header.type : null
            }
         })
         .then(function (response) {
            return response.data;
         })
         .catch(function (error) {
            console.log(error);
            return [];
         });
      },

      search: function search(header: any, term: string): any {

         return axios.get(`${base_url}publications/search/${term}`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
               }
            })
            .then(function (response) {
               return response.data;

            })
            .catch(function (error) {
               console.log(error);
               return [];
            });
      },

      searchOrg: function searchOrg(header: any, term: string): any {

         return axios.get(`${base_url}publications/search/${term}`,
            {
               headers: {
                  //origin: Available values : PUB, STR
                  'Origins': process.env.REACT_APP_SIGLA,
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Authorization': `Bearer ${auth().getToken()}`
               }
            })
            .then(function (response) {
               return response.data;

            })
            .catch(function (error) {
               
               return [];
            });
      },

      register: function register(data: any): any {

         return axios.post(`${base_url}v1/publications`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },

      update: function update(data: any, id: number): any {

         return axios.put(`${base_url}v1/publications/${id}`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },

      storage: function storage(url: string, data: any): any {

         return axios.put(url, data,
         {
            headers: {
               'Accept': '*/*',
               'Access-Control-Allow-Origin': '*',
               'Access-Control-Allow-methods': 'PUT',
               'content-type': ''
            }
         });
      },

      upload: function upload(data: any): any {

         return axios.post(`${base_url}v1/publications/file`, data,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },

      enabled: function enabled(id: number): any {

         return axios.post(`${base_url}v1/publications/enable/${id}`, {},
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },

      urlSignedBook: function urlSignedBook(data: any): any {

         return axios.post(`${base_url}v1/publications/reader/`, JSON.stringify(data),
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },

      destroy: function destroy(id: number): any {

         return axios.delete(`${base_url}v1/publications/${id}`,
         {
            headers: {
               //origin: Available values : PUB, STR
               'Origins': process.env.REACT_APP_SIGLA,
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${auth().getToken()}`
            }
         });
      },
   };

   return publications;
}
